import React from 'react';
import PropTypes from 'prop-types';
import { root } from 'baobab-react/higher-order';
import tree from './state';

function Root({ children }) {
  return <div>{children}</div>;
}

Root.propTypes = {
  children: PropTypes.node,
};

Root.defaultProps = {
  children: undefined,
};

export default root(tree, Root);
