import React, { useState } from 'react';
import { get } from 'lodash';
import { trackClick } from 'utility/analytics';
import history from 'historyContainer';
import Flex from 'styled-flex-component';
import { MD } from '@zendeskgarden/react-typography';
import { Dropdown, Trigger, Menu, Item } from '@zendeskgarden/react-dropdowns';
import { Button } from 'theme/Button';
import { variables } from 'theme/variables';
import Icon from 'components/Icon/Icon';
import { isAdmin, isFacilitator } from 'utility/hasAccount';
import PropTypes from 'prop-types';
import { SubMenuItems, SubItem } from './AccountMenuDropdown.styles';

const {
  line_height_xxxl: lineHeightXxxl,
  spacing_md: spacingMd,
  spacing_sm: spacingSm,
} = variables;

function AccountMenuDropdown({ session, logout }) {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  let name;
  if (get(session, 'first_name') && get(session, 'last_name')) {
    name = `Hi, ${get(session, 'first_name')} ${get(session, 'last_name')}`;
  } else if (get(session, 'first_name')) {
    name = get(session, 'first_name');
  } else {
    name = 'My Account';
  }
  return (
    <Dropdown
      style={{ width: '200px' }}
      arrow={false}
      placement="bottom-end"
      onStateChange={(changes) => {
        if (
          changes.isOpen !== menuIsOpen &&
          Object.prototype.hasOwnProperty.call(changes, 'isOpen')
        ) {
          setMenuIsOpen(changes.isOpen);
        }
      }}
    >
      <Trigger>
        <div>
          <Button
            whiteButtonLink
            black
            bold
            transparentBorder
            style={{ padding: '0 10px' }}
          >
            <Flex
              alignCenter
              style={{
                height: lineHeightXxxl,
                position: 'relative',
                marginRight: 0,
              }}
            >
              <MD ellipsis>
                {isAdmin() ? (
                  <span
                    style={{
                      verticalAlign: 'middle',
                      display: 'inline-block',
                      width: 5,
                      height: 5,
                      borderRadius: '100%',
                      background: 'red',
                      marginRight: '4px',
                    }}
                  />
                ) : null}
                {name}
              </MD>
              <span style={{ paddingLeft: 7, paddingTop: 2 }}>
                <Icon icon="icon-chevron-down" fontSize="10px" />
              </span>
            </Flex>
          </Button>
        </div>
      </Trigger>
      {!menuIsOpen ? null : (
        <Menu placement="bottom" arrow>
          <Item
            hoverColorLight
            bold
            value="dashboard"
            onClick={() => {
              trackClick('goToDashboard', 'Dashboard', 'AccountMenuDropdown');
              history.push('/dashboard');
            }}
          >
            <AccountMenuDropdownItem icon="icon-menu" title="Dashboard" />
          </Item>

          <Item
            hoverColorLight
            bold
            value="activity"
            onClick={() => {
              trackClick('goToActivity', 'Activity', 'AccountMenuDropdown');
              history.push('/dashboard/activity');
            }}
          >
            <AccountMenuDropdownItem icon="list" title="Activity" fa />
          </Item>

          <Item
            hoverColorLight
            bold
            value="settings"
            onClick={() => {
              trackClick('goToSettings', 'Settings', 'AccountMenuDropdown');
              history.push('/settings/business');
            }}
          >
            <AccountMenuDropdownItem icon="icon-settings-v" title="Settings" />
          </Item>
          <SubMenuItems>
            <SubItem
              hoverColorLight
              bold
              value="business"
              onClick={() => {
                history.push('/settings/business');
              }}
            >
              <AccountMenuDropdownItem fa icon="building" title="Business" />
            </SubItem>
            <SubItem
              hoverColorLight
              bold
              value="areasOfInterests"
              onClick={() => {
                history.push('/settings/locations-of-interest');
              }}
            >
              <AccountMenuDropdownItem
                fa
                icon="map-marker-alt"
                title="Areas of Interests"
              />
            </SubItem>
            <SubItem
              hoverColorLight
              bold
              value="account"
              onClick={() => {
                history.push('/settings/account');
              }}
            >
              <AccountMenuDropdownItem fa icon="user" title="Account" />
            </SubItem>
            {isFacilitator() && (
              <>
                <SubItem
                  hoverColorLight
                  bold
                  value="permissions"
                  onClick={() => {
                    history.push('/settings/permissions');
                  }}
                >
                  <AccountMenuDropdownItem
                    fa
                    icon="user-lock"
                    title="Permissions"
                  />
                </SubItem>
                <SubItem
                  hoverColorLight
                  bold
                  value="attributes"
                  onClick={() => {
                    history.push('/settings/attributes');
                  }}
                >
                  <AccountMenuDropdownItem fa icon="tag" title="Attributes" />
                </SubItem>
              </>
            )}
          </SubMenuItems>
          <Item
            hoverColorLight
            bold
            value="logout"
            onClick={() => {
              trackClick('logout', 'logout', 'AccountMenuDropdown');
              logout();
            }}
          >
            <AccountMenuDropdownItem icon="icon-exit" title="Logout" />
          </Item>
        </Menu>
      )}
    </Dropdown>
  );
}

AccountMenuDropdown.defaultProps = {
  session: undefined,
  logout: undefined,
};

AccountMenuDropdown.propTypes = {
  session: PropTypes.oneOfType([
    PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
    }),
    PropTypes.string,
  ]),
  logout: PropTypes.func,
};

function AccountMenuDropdownItem({ icon, title, fa }) {
  return (
    <Flex alignCenter>
      <span
        style={{
          paddingLeft: spacingSm,
          paddingRight: spacingMd,
          paddingTop: 2,
        }}
      >
        <Icon icon={icon} fontSize="15x" fontAwesome={fa || false} />
      </span>
      <MD>{title}</MD>
    </Flex>
  );
}

AccountMenuDropdownItem.defaultProps = {
  icon: undefined,
  title: undefined,
  fa: undefined,
};

AccountMenuDropdownItem.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  fa: PropTypes.bool,
};

export default AccountMenuDropdown;
