import tree from 'state';
import * as customerEffects from 'state/customers/effects';
import { get, replace } from 'lodash';
import asyncTreeRequester from 'utility/asyncTreeRequester';
import { getMyOpportunities } from 'state/opportunities/actions';
import * as effects from './effects';
import requestForQuoteState from './state';

const requestForQuoteCursor = tree.select(['requestForQuote']);

export async function getRfqMeta() {
  await asyncTreeRequester({
    func: effects.getRfqMeta,
    cursor: requestForQuoteCursor,
    path: ['meta'],
  });
}

export async function resetActiveRfq() {
  const initialState = requestForQuoteState();
  const meta = requestForQuoteCursor.get('meta');
  requestForQuoteCursor.set({ ...initialState, meta });
}

export async function getActiveRfq(id, isMfg) {
  const rfq = await asyncTreeRequester({
    func: effects.getRfqGroup.bind(this, id, isMfg),
    cursor: requestForQuoteCursor,
    path: ['activeRfq', 'group'],
  });
  requestForQuoteCursor.set(
    ['rfqItemInformations', 'leadSource'],
    get(rfq, 'lead_source', {})
  );

  if (rfq && !rfq.error) {
    requestForQuoteCursor.set('id', id);
    tree.commit();
  } else if (rfq && rfq.error) {
    // TODO set error
    console.error(rfq?.error);
  }
  return rfq;
}

export async function createRfq(data, options, isMfg) {
  const { returnResponse } = options || {};
  const rfq = await asyncTreeRequester({
    func: effects.createRfqGroup.bind(this, data, null, isMfg),
    cursor: requestForQuoteCursor,
    path: ['activeRfq', 'group'],
  });

  if (rfq && rfq.id && !returnResponse) {
    requestForQuoteCursor.set('id', rfq.id);
    const oldUrl = window.location.href;
    const newUrl = replace(oldUrl, '/m/rfq/', `/m/rfq/${rfq.id}/`);
    window.history.replaceState(null, null, newUrl);
  }
  return rfq;
}

export async function updateRfq(rfqId, data, isMfg) {
  const rfq = await asyncTreeRequester({
    func: effects.updateRfqGroup.bind(this, rfqId, data, isMfg),
    cursor: requestForQuoteCursor,
    path: ['activeRfq', 'group'],
  });
  return rfq;
}

export async function clearError() {
  requestForQuoteCursor.set(['activeRfq', 'group', 'error'], false);
}

export async function submitRfq(rfqId, transitionValue, data, isMfg) {
  const result = await asyncTreeRequester({
    func: effects.transitionRfq.bind(
      this,
      rfqId,
      transitionValue || 'not-quoted',
      data,
      null,
      isMfg
    ),
    handleResult: true,
  });

  if (!get(result, 'error')) {
    getMyOpportunities(); // refresh opportunities
  }
  return result;
}

export async function getRfqCustomer(customerId) {
  try {
    const customer = await customerEffects.getCustomerDetails(customerId);
    requestForQuoteCursor.set(['customer'], customer);
    return customer;
  } catch (err) {
    return err.message;
  }
}

export async function setRfqCustomerLocally(customer) {
  requestForQuoteCursor.set(['customer'], customer);
}

export async function setRfqSourceLead(leadSource, activeRfqId, isMfg) {
  const _activeRfqId = activeRfqId || null;
  let result;
  if (activeRfqId) {
    try {
      result = await asyncTreeRequester({
        func: effects.updateRfqGroup.bind(
          this,
          _activeRfqId,
          {
            lead_source: leadSource.id,
          },
          isMfg
        ),
        handleResult: true,
      });
    } catch (err) {
      return err?.message;
    }
  }

  requestForQuoteCursor.set(['rfqItemInformations', 'leadSource'], leadSource);
  return result;
}

export async function getActiveRfqForOverview(rfqId, isMfg) {
  await asyncTreeRequester({
    func: effects.getRfqGroupSummary.bind(this, rfqId, isMfg),
    cursor: requestForQuoteCursor,
    path: ['activeRfq', 'summary'],
  });
}

export async function patchRfqDetails(data) {
  try {
    requestForQuoteCursor.merge(['activeRfq', 'summary', 'result'], data);
  } catch (err) {
    return err?.message;
  }
  return undefined;
}

export async function duplicateRfq(rfqId, isMfg) {
  let result;
  if (rfqId) {
    try {
      result = await effects.duplicateRfq(rfqId, isMfg);
    } catch (err) {
      return err?.message;
    }
  }
  return result;
}
