import React from 'react';
import PropTypes from 'prop-types';
import { map, forEach } from 'lodash';
import { Helmet } from 'react-helmet-async';
import { ENVIRONMENT } from '../constants';

function DefaultMeta() {
  return (
    <Helmet>
      <meta
        name="twitter:card"
        value="Pipesearch is Providing The Fastest, Most Reliable, Quality CRA Tubulars In The World"
      />
      <meta
        name="twitter:image"
        content="https://www.pipesearch.com/pipesearch.jpg"
      />

      <meta property="og:title" content="PipeSearch" />
      <meta property="og:type" content="article" />
      <meta property="og:url" content="https://pipesearch.com" />
      <meta
        property="og:image"
        content="https://www.pipesearch.com/pipesearch.jpg"
      />
      <meta
        property="og:description"
        content="Pipesearch is Providing The Fastest, Most Reliable, Quality CRA Tubulars In The World"
      />
    </Helmet>
  );
}

export default DefaultMeta;

function HelmetWrapper({ metadata } = {}) {
  if (!metadata) {
    return DefaultMeta();
  }
  return (
    <Helmet title={metadata.title}>
      {map(metadata.meta, (properties, i) => (
        <meta
          key={`meta-${i}`}
          // LINT OVERRIDE #3
          // Component wraps another component
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...properties}
        />
      ))}
      {map(metadata.script, ({ type, innerHTML }, i) => (
        <script key={`script-${i}`} type={type}>
          {`${innerHTML}`}
        </script>
      ))}
    </Helmet>
  );
}

export const basicHelmetGenerator = (data = {}) => {
  const meta = [];
  const properties = {
    title: data.title || 'PipeSearch',
    description:
      data.description ||
      'Pipesearch is Providing The Fastest, Most Reliable, Quality CRA Tubulars In The World',
    image: data.image || 'https://www.pipesearch.com/pipesearch.jpg',
  };

  forEach(properties, (v, k) => {
    if (v) {
      meta.push({ property: `og:${k}`, content: v });
      meta.push({ name: `twitter:${k}`, content: v });
    }
  });
  meta.push({ name: 'description', content: properties.description });

  // Helmet should be built during initial server-side render, but have a fallback to window env variable in case the browser builds the page
  let isProd;
  if (typeof process !== 'undefined') {
    isProd = process.env.ENVIRONMENT === 'live';
  } else {
    isProd = ENVIRONMENT === 'live';
  }
  const robots = isProd
    ? { name: 'robots', content: 'all' }
    : { name: 'robots', content: 'noindex' };
  meta.push(robots);

  return { title: properties.title, meta };
};

HelmetWrapper.propTypes = {
  metadata: PropTypes.shape({
    title: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.shape({})),
    script: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        innerHTML: PropTypes.string,
      })
    ),
  }),
};

HelmetWrapper.defaultProps = {
  metadata: undefined,
};

export { HelmetWrapper };
