import React from 'react';
import { MD } from '@zendeskgarden/react-typography';
import { map } from 'lodash';
import { Dot } from 'theme/Global.styles.js';
import Flex from 'styled-flex-component';
import { SM } from '@zendeskgarden/react-typography';
import moment from 'moment';
import { Link } from 'react-router-dom';

import {
  StyledNotificationDropdownList,
  StyledNotificationItem,
  StyledNotificationText,
  StyledNotificationSubject,
} from './NotificationDropdownList.styles.js';
import { objectToUrl } from 'utility/notificationLinks.js';

const NotificationDropdownList = ({ onClickItem, notifications, ...rest }) => {
  return (
    <StyledNotificationDropdownList {...rest}>
      {notifications && notifications.length ? (
        map(notifications, (notification, i, arr) => {
          return (
            <NotificationItem
              last={i === arr.length - 1}
              onClickItem={onClickItem}
              key={i}
              notification={notification}
              {...rest}
            />
          );
        })
      ) : (
        <MD center style={{ paddingTop: '30px' }}>
          No Notifications
        </MD>
      )}
    </StyledNotificationDropdownList>
  );
};

const NotificationItem = ({ last, onClickItem, notification, ...rest }) => {
  const { actor_count, activity_count, activities, updated_at, is_seen } =
    notification;
  const activity = activities[0];
  const isNew = !is_seen;

  return (
    <StyledNotificationItem
      style={last ? { borderBottom: 0 } : {}}
      new={true}
      padding={rest.itemPadding}
    >
      <Link onClick={onClickItem} className="bold" to={objectToUrl(activity)}>
        <Flex full>
          {isNew ? (
            <Dot style={{ height: '24px' }} />
          ) : (
            <Dot style={{ height: '24px' }} background={'#ccc'} />
          )}
          <div style={{ flex: 1, minWidth: 0 }}>
            <Flex style={{ height: 'auto' }} full>
              <Flex full alignCenter>
                {actor_count === 1 ? (
                  <StyledNotificationSubject bold>
                    {activity.actor_name} made {activity_count}{' '}
                    {activity_count === 1 ? 'change' : 'changes'} to{' '}
                    {(activity.object_type || '')
                      .replace('AuthUser', 'User')
                      .replace('SupplyOpportunity', 'Supply')}{' '}
                    {(activity.object_name || '')
                      .replace('AuthUser', 'User')
                      .replace('SupplyOpportunity', 'Supply')}
                  </StyledNotificationSubject>
                ) : (
                  <StyledNotificationSubject bold>
                    {actor_count} {actor_count === 1 ? 'person' : 'people'} made{' '}
                    {activity_count}{' '}
                    {activity_count === 1 ? 'change' : 'changes'} to{' '}
                    {(activity.object_type || '')
                      .replace('AuthUser', 'User')
                      .replace('SupplyOpportunity', 'Supply')}{' '}
                    {(activity.object_name || '')
                      .replace('AuthUser', 'User')
                      .replace('SupplyOpportunity', 'Supply')}
                  </StyledNotificationSubject>
                )}
              </Flex>
            </Flex>
            <StyledNotificationText ellipsis grey>
              <SM
                slate
                style={{ minWidth: '80px', textAlign: 'right' }}
                alignCenter
                bold={isNew}
                blue={isNew}
              >
                {moment.utc(updated_at).local().format('h:mm a')}
              </SM>
            </StyledNotificationText>

            <SM paddingTopSm blue link>
              View{' '}
              {(activity.object_type || '')
                .replace('AuthUser', 'User')
                .replace('SupplyOpportunity', 'Supply')}
            </SM>
          </div>
        </Flex>
      </Link>
    </StyledNotificationItem>
  );
};

export default NotificationDropdownList;
