import tree from 'state';
import { canUseDOM } from 'exenv';
import { filter, includes, keys, sortBy, head } from 'lodash';
import { userRoles } from './staticMap';

export function hasSession() {
  const session = tree.get(['authentication', 'session', 'email']);
  return session || (canUseDOM && window.OFFLINE_MODE);
}

export function isFacilitator() {
  const roles = tree.get(['authentication', 'session', 'roles']) || {};
  const dashboardType = tree.get(['application', 'accountType']);
  const {
    facilitator,
    facilitator_manager: facilitatorManager,
    facilitator_executive: facilitatorExecutive,
    admin,
  } = roles;
  return (
    ((dashboardType && dashboardType === 'facilitator') || !dashboardType) &&
    (facilitator ||
      facilitatorManager ||
      facilitatorExecutive ||
      admin ||
      dashboardType === 'facilitator')
  );
}

export function isAdmin() {
  return false;
}

export function isAdministrator() {
  const roles = tree.get(['authentication', 'session', 'roles']) || {};
  const { admin } = roles;
  return admin;
}

export function isExecutive() {
  const roles = tree.get(['authentication', 'session', 'roles']) || {};
  const { facilitator_executive: facilitatorExecutive } = roles;
  return facilitatorExecutive;
}

export function isManager() {
  const roles = tree.get(['authentication', 'session', 'roles']) || {};
  const { facilitator_manager: facilitatorManager } = roles;
  return facilitatorManager;
}

export function isPartner() {
  const roles = tree.get(['authentication', 'session', 'roles']) || {};
  const { facilitator_partner: facilitatorPartner } = roles;
  return facilitatorPartner;
}

export function isOperator() {
  const roles = tree.get(['authentication', 'session', 'roles']) || {};
  const { facilitator_operator: facilitatorOperator } = roles;
  return facilitatorOperator;
}

export function getRole() {
  /*
    Returns the current authenticated user's role metadata
    If the user has multiple roles, returns the metadata for the role with the highest rank
    (highest being 1)
  */
  const roles = tree.get(['authentication', 'session', 'roles']) || {};
  const rolesList = filter(userRoles, (role) =>
    includes(keys(roles), role.value)
  );
  const rankedRolesList = sortBy(rolesList, ['rank']);
  // Mocks role info for users who don't have an assignable permissions role (such as End User)
  const firstRoleKey = head(keys(roles));
  // Sets role to undefined if no roles exist
  const alternateRole = firstRoleKey && { value: firstRoleKey };
  return head(rankedRolesList) || alternateRole;
}

export function isEndUser() {
  const roles = tree.get(['authentication', 'session', 'roles']) || {};
  const dashboardType = tree.get(['application', 'accountType']);
  const endUser =
    roles.buyer || roles.seller || roles.buyer_seller || roles.buyerseller;
  return (
    ((dashboardType && dashboardType === 'end_user') || !dashboardType) &&
    (endUser || dashboardType === 'end_user')
  );
}

export function isBetaTester() {
  return true;
}

export function getClientId() {
  return tree.get([
    'authentication',
    'session',
    'roles',
    'facilitator',
    'data',
    'company',
    'id',
  ]);
}

export function getSessionDetails() {
  return tree.get(['authentication', 'session']);
}
