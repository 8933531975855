import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ProgressBar from 'components/ProgressBar/ProgressBar';
import uuid from 'uuid';
import numeral from 'numeral';
import { Divider } from 'theme/Global.styles';
import { Button } from 'theme/Button';
import { MD, SM } from '@zendeskgarden/react-typography';
import { map as promiseMap } from 'bluebird';
import { some, keyBy, isEmpty, map, filter, reject, noop } from 'lodash';
import { trackClick } from 'utility/analytics';
import Icon from 'components/Icon/Icon';
import Flex from 'styled-flex-component';
import { variables } from 'theme/variables';
import { useDropzone } from 'react-dropzone';
import { fileUploadShape } from 'propTypesObjects';
// TODO P2-178 import { Field, Input } from '@zendeskgarden/react-forms';
import { FileUpload } from 'types/types.ts';

const {
  spacing,
  custom_border_grey: customBorderGrey,
  custom_silver: customSilver,
  custom_green: customGreen,
  custom_blue: customBlue,
} = variables;

type UploadKey = string;
type UploadedFiles = Record<UploadKey, FileUpload>;
type UploadFile = File & {
  lastModifiedDate: Date;
  path: string;
  mtrNumber: string;
};

type DropZoneComponentProps = {
  renderContinueContainer: Function;
  descriptionContainer: Function;
  uploadOnDrop: boolean;
  onSubmitFiles: Function;
  uploaded: UploadedFiles;
  setUploaded: React.Dispatch<React.SetStateAction<UploadedFiles>>;
  onAddFile: (files: Record<string, Promise<FileUpload>>) => void;
  children: React.ReactNode;
  onClose: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  height: string;
  onRemoveFile: Function;
  isImage: boolean;
  multiple: boolean;
  externalSubmit: Function;
  modalHeader: React.ReactNode;
  submitting: boolean;
  fileType: string;
};

function DropZoneComponent({
  renderContinueContainer,
  descriptionContainer,
  uploadOnDrop,
  onSubmitFiles,
  uploaded,
  // TODO P2-178 setUploaded,
  onAddFile,
  children,
  onClose,
  height,
  onRemoveFile,
  isImage,
  multiple,
  externalSubmit,
  modalHeader,
  submitting,
}: // TODO P2-178 fileType,
DropZoneComponentProps) {
  const onDrop = async (acceptedFiles: File[]) => {
    function readFile(file: UploadFile) {
      return new Promise((resolve, rejectFunc) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onabort = () => {
          trackClick('onAbortDropzone', 'drop', 'DropZone');
          resolve({});
        };
        reader.onerror = () => {
          trackClick('onErrorDropzone', 'drop', 'DropZone');
          rejectFunc();
        };
        reader.onload = () => {
          trackClick('onLoadDropzone', 'drop', 'DropZone');
          const {
            name,
            lastModified,
            lastModifiedDate,
            webkitRelativePath,
            size,
            type,
            path,
            mtrNumber,
          } = file;
          const obj = {
            name,
            lastModified,
            lastModifiedDate,
            webkitRelativePath,
            size,
            type,
            path,
            uuid: uuid.v4(),
            result: reader.result,
            mtrNumber,
            file,
          };
          resolve(obj);
        };
      });
    }

    const filePromises = map(acceptedFiles, (file: UploadFile) =>
      readFile(file)
    );
    const resolvedPromised = await promiseMap(filePromises, (d) => d);
    onAddFile(
      keyBy(resolvedPromised, 'uuid') as unknown as Record<
        string,
        Promise<FileUpload>
      >
    );
  };

  const uploadCount = filter(
    uploaded,
    (u) => !u.saved && !u.queued && !u.error
  ).length;
  const hasUnsaved = reject(uploaded, 'saved').length;

  useEffect(() => {
    if (uploadCount && uploadOnDrop && !submitting) {
      onSubmitFiles();
    }
  }, [onSubmitFiles, submitting, uploadCount, uploadOnDrop]);

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    noClick: true,
  });
  const svgStyle = {
    pointerEvents: 'none',
    height: '100%',
    width: '100%',
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  };
  const rectStyle = {
    stroke: 'rgb(196, 192, 192)',
    strokeWidth: 2,
    strokeDasharray: '15 5',
    fill: 'none',
  };

  /* TODO P2-178 
    function handleChangeMtrNumber(
    e: React.FormEvent<HTMLInputElement>,
    file: FileUpload
  ) {
    e.persist();
    setUploaded((previousUploaded) => {
      const previousUploadedCopy = cloneDeep(previousUploaded);
      previousUploadedCopy[file.uuid].mtrNumber = (
        e.target as HTMLInputElement
      ).value;
      return previousUploadedCopy;
    });
  } */

  /* TODO P2-178
    const mtrFieldsIncomplete =
    fileType === 'mtr'
      ? some(uploaded, (file) => {
          if (!file.mtrNumber || file.mtrNumber === '') {
            return true;
          }
          return false;
        })
      : false; */

  return (
    <div
      className="dropzone-wrapper-component"
      style={{ outline: 'none' }}
      onClick={onClose}
      onKeyDown={noop}
      role="button"
      tabIndex={-1}
    >
      {children || (
        <div
          className="drop-zone-inner"
          style={{ maxHeight: '90%', overflow: 'auto' }}
          // LINT OVERRIDE #3
          // Component wraps another component
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...getRootProps({ onClick: (e) => e.stopPropagation() })}
        >
          {onClose ? (
            <div
              style={{ position: 'relative', width: '100%', height: '40px' }}
            >
              <div
                style={{
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  paddingLeft: '10px',
                }}
              >
                {modalHeader}
              </div>
              <Icon
                buttonStyle
                fadeOnHover
                icon="icon-close"
                fontSize="12px"
                onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                  e.stopPropagation();
                  trackClick('closeDropzone', 'icon-close', 'DropZone');
                  onClose(e);
                }}
                style={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  zIndex: 22,
                }}
              />
            </div>
          ) : null}

          {descriptionContainer || null}

          <Flex
            className={`dropzone-drop ${isEmpty(uploaded) ? 'empty' : ''}`}
            justifyCenter
            alignCenter
            style={{
              minHeight: height || '300px',
              borderRadius: '4px',
              position: 'relative',
            }}
          >
            {!isEmpty(uploaded) ? (
              <Flex className="file-wrapper" column style={{ width: '80%' }}>
                {map(uploaded, (file, key) => (
                  <div
                    style={{ position: 'relative' }}
                    className="file-row-item"
                    key={file.uuid}
                  >
                    {file.saving ? (
                      <ProgressBar
                        wrapperStyle={{
                          pointerEvents: 'none',
                          height: '100%',
                        }}
                        style={{ height: '100%' }}
                        color="rgba(2, 104, 245, 0.08)"
                        estimatedDuration={400}
                        complete={!file.saving}
                      />
                    ) : null}
                    <Flex
                      className="file-container"
                      alignCenter
                      justifyBetween
                      style={{ padding: '20px 20px 20px' }}
                    >
                      <Flex alignCenter>
                        <Icon
                          icon={
                            !file.saved
                              ? 'icon-cloud-upload'
                              : 'icon-check-outline'
                          }
                          color={!file.saved ? customSilver : customGreen}
                          fontSize="24px"
                          style={{ marginRight: spacing }}
                        />

                        <div>
                          <MD as="h3" noMargin className="file-name" bold>
                            {file.name}
                          </MD>
                          <SM
                            as="h4"
                            className="file-size"
                            style={{
                              textAlign: 'left',
                              fontSize: '11px',
                              paddingTop: '3px',
                            }}
                          >
                            {numeral(file.size).format('0,0')} Bytes
                          </SM>
                          {/* TODO P2-178 
                          {fileType === 'mtr' ? (
                            <Field>
                              <Input
                                small
                                itemSelection
                                placeholder="Enter MTR Number"
                                value={file.mtrNumber || ''}
                                onChange={(
                                  e: React.FormEvent<HTMLInputElement>
                                ) => handleChangeMtrNumber(e, file)}
                              />
                            </Field>
                          ) : null} */}
                          {file.error ? (
                            <SM
                              style={{ fontSize: '11px', paddingTop: '3px' }}
                              error
                            >
                              {file.error}
                            </SM>
                          ) : (
                            ''
                          )}
                        </div>
                      </Flex>

                      <Icon
                        buttonStyle
                        fadeOnHover
                        icon="icon-close-outline"
                        fontSize="16px"
                        onClick={(
                          e: React.MouseEvent<HTMLDivElement, MouseEvent>
                        ) => {
                          e.stopPropagation();
                          onRemoveFile({ data: file, key });
                        }}
                      />
                    </Flex>
                    <Divider
                      // TODO: P2-1222
                      // @ts-ignore
                      horizontal
                      margin="0px"
                      className="el-divider"
                      background={customBorderGrey}
                    />
                  </div>
                ))}
                {!uploadOnDrop && !externalSubmit ? (
                  <Flex column justifyCenter alignCenter>
                    {/* TODO P2-178 
                      {mtrFieldsIncomplete ? (
                      <SM slate style={{ margin: '20px 0 10px' }}>
                        Please enter all MTR Numbers
                      </SM>
                    ) : null} */}
                    {/* TODO: P2-1227 */}
                    {/* @ts-ignore */}
                    <Button
                      // TODO P2-178 disabled={uploadCount === 0 || mtrFieldsIncomplete}
                      disabled={uploadCount === 0}
                      loading={some(uploaded, 'saving')}
                      onClick={onSubmitFiles}
                      // TODO P2-178 spacingTop={!mtrFieldsIncomplete}
                      spacingTop
                      spacingBottom
                      primary
                      style={{ width: '100%' }}
                    >
                      Upload {uploadCount > 0 ? uploadCount : ''}{' '}
                      {uploadCount === 1 ? 'File' : 'Files'}
                    </Button>
                  </Flex>
                ) : null}

                {multiple !== false ? (
                  <SM
                    paddingTopLg={uploadOnDrop}
                    paddingTopSm={!uploadOnDrop || externalSubmit}
                    style={{
                      textAlign: 'center',
                      width: '120px',
                      margin: '0 auto',
                    }}
                    bold
                    link
                    paddingBottomLg={!externalSubmit}
                    onClick={(
                      e: React.MouseEvent<HTMLDivElement, MouseEvent>
                    ) => {
                      e.stopPropagation();
                      open();
                    }}
                  >
                    Add Another File
                  </SM>
                ) : null}
              </Flex>
            ) : (
              <div
                style={{
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                  height: '300px',
                }}
                onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                  e.stopPropagation();
                  open();
                }}
                onKeyDown={noop}
                role="button"
                tabIndex={0}
              >
                <svg
                  className="drop-zone-border"
                  // TODO: P2-1223
                  // @ts-ignore
                  style={svgStyle}
                >
                  <rect
                    x="0"
                    y="0"
                    width="100%"
                    height="100%"
                    style={rectStyle}
                  />
                </svg>
                <Flex alignCenter justifyCenter column>
                  <Icon
                    fontAwesome
                    pointer
                    icon={isImage ? 'file-image' : 'cloud-upload'}
                    color={customSilver}
                    fontSize="60px"
                    style={{ marginBottom: spacing }}
                  />
                  <MD className="cta" bold navy>
                    Drop your {isImage ? 'image' : 'file'} here or{' '}
                    <span style={{ color: customBlue }}>browse</span>
                  </MD>
                </Flex>
              </div>
            )}
          </Flex>
          {renderContinueContainer
            ? renderContinueContainer(uploaded, hasUnsaved, onClose)
            : null}
        </div>
      )}
      {
        // LINT OVERRIDE #3
        // Component wraps another component
        // eslint-disable-next-line react/jsx-props-no-spreading
        <input {...getInputProps()} />
      }
    </div>
  );
}

DropZoneComponent.propTypes = {
  renderContinueContainer: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
  ]),
  descriptionContainer: PropTypes.node,
  uploadOnDrop: PropTypes.bool,
  onSubmitFiles: PropTypes.func,
  uploaded: fileUploadShape,
  setUploaded: PropTypes.func,
  onAddFile: PropTypes.func,
  children: PropTypes.node,
  onClose: PropTypes.func,
  height: PropTypes.string,
  onRemoveFile: PropTypes.func,
  isImage: PropTypes.bool,
  multiple: PropTypes.bool,
  externalSubmit: PropTypes.bool,
  modalHeader: PropTypes.node,
  submitting: PropTypes.bool,
  fileType: PropTypes.string,
};

DropZoneComponent.defaultProps = {
  renderContinueContainer: undefined,
  descriptionContainer: undefined,
  uploadOnDrop: undefined,
  onSubmitFiles: undefined,
  uploaded: undefined,
  setUploaded: undefined,
  onAddFile: undefined,
  children: undefined,
  onClose: undefined,
  height: undefined,
  onRemoveFile: undefined,
  isImage: undefined,
  multiple: undefined,
  externalSubmit: undefined,
  modalHeader: undefined,
  submitting: undefined,
  fileType: undefined,
};

export default DropZoneComponent;
