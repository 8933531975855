import React from 'react';
import Icon from 'components/Icon/Icon';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import {
  Field,
  Label,
  Input,
  Message,
  Textarea,
} from '@zendeskgarden/react-forms';
import PhoneInput from 'react-phone-input-2';
import { variables } from './variables';

const {
  spacing,
  spacing_sm: spacingSm,
  color_red_400: colorRed400,
  spacing_lg: spacingLg,
  custom_red: customRed,
} = variables;

const FormInputWrapper = styled.div`
  margin-top: ${spacingSm};
`;

export const InputMediaIconWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  ${(props) =>
    props.right
      ? `
    right: 3px;
    background: #fff;
    top: 4px;
    bottom: 4px
  `
      : ''}
  ${(props) =>
    props.pointer
      ? `
    span::before {
      cursor: pointer !important;
    }
    `
      : ''}
`;

export const GroupedItemSelectionInputItem = styled.div`
  margin-top: ${spacingLg};
  ${(props) =>
    props.noSpacing
      ? `
    margin-top: 0;
  `
      : ''}
`;

export const Form = styled.form`
  footer {
    margin-top: ${spacing};
  }
`;

export const FormFooter = styled.div`
  margin-top: ${spacing};
  ${(props) =>
    props.centeredButton
      ? `
    display: flex;
    justify-content: center;
  `
      : ''}
`;

export const InputMediaWrapper = styled.div`
  position: relative;
`;

export function FormInput({
  wrapperStyle,
  phone,
  textarea,
  icon,
  error,
  showMessage = false,
  scaled,
  label,
  required,
  inputMediaWrapperStyle,
  ...rest
}) {
  return (
    <FormInputWrapper className="form-input-wrapper" style={wrapperStyle || {}}>
      <Field>
        {label ? (
          <Label style={error ? { color: colorRed400 } : {}}>{label}</Label>
        ) : null}
        <InputMediaWrapper style={inputMediaWrapperStyle}>
          {required ? <span style={{ color: customRed }}> * </span> : null}
          {textarea && (
            <Textarea
              style={{ minHeight: '90px' }}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...rest}
              scaled={scaled}
              validation={error ? 'error' : undefined}
            />
          )}
          {phone && (
            <PhoneInput
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...rest}
              country="us"
              inputProps={{ autoComplete: 'none' }}
              onChange={(phoneNum) =>
                rest.onChange({ currentTarget: { value: `+${phoneNum}` } })
              }
            />
          )}
          {!textarea && !phone && (
            <Input
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...rest}
              icon={icon ? 'icon' : ''}
              scaled={scaled}
              validation={error ? 'error' : undefined}
            />
          )}
          {icon ? (
            <InputMediaIconWrapper style={{ width: '40px' }}>
              <Icon alignCenter justifyCenter icon={icon} fontSize="15px" />
            </InputMediaIconWrapper>
          ) : null}
        </InputMediaWrapper>
        {error && showMessage ? (
          <Message validation="error">{error}</Message>
        ) : null}
      </Field>
    </FormInputWrapper>
  );
}

FormInput.propTypes = {
  wrapperStyle: PropTypes.shape({}),
  phone: PropTypes.number,
  textarea: PropTypes.bool,
  icon: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  showMessage: PropTypes.bool,
  scaled: PropTypes.bool,
  label: PropTypes.string,
  required: PropTypes.bool,
  inputMediaWrapperStyle: PropTypes.shape({}),
};

FormInput.defaultProps = {
  wrapperStyle: undefined,
  phone: undefined,
  textarea: undefined,
  icon: undefined,
  error: undefined,
  showMessage: false,
  scaled: undefined,
  label: undefined,
  required: undefined,
  inputMediaWrapperStyle: {},
};
