import React from 'react';
import PropTypes from 'prop-types';
import {
  MenuLinkBox,
  MenuButton,
  MenuText,
  MenuContainer,
  MenuCollapse,
} from './SidebarMenuLink.styles';
import MenuIcon from './MenuIcon';

function LinkOrDivWrapper({ children, onClick, to, exact }) {
  return to ? (
    // MenuLinkBox is a react-router NavLink
    <MenuLinkBox onClick={onClick || null} to={to} exact={exact}>
      {/* Children represents all of the sub-elements to prevent duplicate code */}
      {children}
    </MenuLinkBox>
  ) : (
    // MenuCollapse is a simple div
    <MenuCollapse onClick={onClick || null}>{children}</MenuCollapse>
  );
}

LinkOrDivWrapper.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  to: PropTypes.string,
  exact: PropTypes.bool,
};

LinkOrDivWrapper.defaultProps = {
  children: undefined,
  onClick: undefined,
  to: undefined,
  exact: undefined,
};

function SidebarMenuLink({
  onClick,
  sidebarMinimized,
  icon,
  children,
  to,
  settingsLinks,
  item,
  exact,
}) {
  return (
    <MenuContainer
      settingsLinks={settingsLinks}
      settings={item?.title === 'Settings'}
    >
      {/* Wraps the component with Nav or div if there's a to (pathname) */}
      <LinkOrDivWrapper onClick={onClick} to={to} exact={exact}>
        <div>
          <MenuButton>
            {!sidebarMinimized ? (
              <MenuText
                style={{
                  lineHeight: '32px',
                  textAlign: 'left',
                  textDecoration: 'none',
                }}
              >
                <span style={{ paddingRight: '5px' }}>
                  <MenuIcon icon={icon} isExpanded={!sidebarMinimized} />
                </span>
                {children}
              </MenuText>
            ) : (
              <MenuIcon icon={icon} isExpanded={!sidebarMinimized} />
            )}
          </MenuButton>
        </div>
      </LinkOrDivWrapper>
    </MenuContainer>
  );
}

SidebarMenuLink.propTypes = {
  onClick: PropTypes.func,
  sidebarMinimized: PropTypes.bool,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node,
  to: PropTypes.string,
  settingsLinks: PropTypes.bool,
  item: PropTypes.shape({
    title: PropTypes.string,
  }),
  exact: PropTypes.bool,
};

SidebarMenuLink.defaultProps = {
  onClick: undefined,
  sidebarMinimized: undefined,
  icon: undefined,
  children: undefined,
  to: undefined,
  settingsLinks: undefined,
  item: undefined,
  exact: undefined,
};

export default SidebarMenuLink;
