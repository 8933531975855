import { isEmpty } from 'lodash';

import fetchJson from 'utility/fetchJson';
import { getValuesForRequest } from 'utility/reverseUrlFilters';
import { BASE } from '../../constants';

export async function getOpportunities(
  { criteria } = {},
  { filters, pagination, sorting = {} } = {}
) {
  let _criteria = criteria;
  const _filters = !filters ? getValuesForRequest('opportunities') : filters;
  if (!_criteria || isEmpty(_criteria)) {
    _criteria = {};
  }

  const body = { ..._filters, ...pagination, ...sorting, ..._criteria };

  const response = await fetchJson(`${BASE}/api/rfq/group/list/`, {
    method: 'POST',
    body: JSON.stringify(isEmpty(body) ? { page: 1 } : body),
  });
  return response;
}

export async function getMfgOpportunities(
  { criteria } = {},
  { filters, pagination, sorting = {} } = {}
) {
  let _criteria = criteria;
  const _filters = !filters ? getValuesForRequest('opportunities') : filters;
  if (!_criteria || isEmpty(_criteria)) {
    _criteria = {};
  }

  const body = { ..._filters, ...pagination, ...sorting, ..._criteria };

  const response = await fetchJson(`${BASE}/api/mrfq/group/list/`, {
    method: 'POST',
    body: JSON.stringify(isEmpty(body) ? { page: 1 } : body),
  });
  return response;
}

export async function getItemOpportunities({
  filters,
  pagination,
  sorting = {},
}) {
  const _filters = !filters
    ? getValuesForRequest('itemOpportunities')
    : filters;
  const body = { ..._filters, ...pagination, ...sorting };
  const response = await fetchJson(`${BASE}/api/rfq/item/list/`, {
    method: 'POST',
    body: JSON.stringify(isEmpty(body) ? { page: 1 } : body),
  });
  return response;
}

export async function getMfgItemOpportunities({
  filters,
  pagination,
  sorting = {},
}) {
  const _filters = !filters
    ? getValuesForRequest('itemOpportunities')
    : filters;
  const body = { ..._filters, ...pagination, ...sorting };
  const response = await fetchJson(`${BASE}/api/mrfq/item/list/`, {
    method: 'POST',
    body: JSON.stringify(isEmpty(body) ? { page: 1 } : body),
  });
  return response;
}
