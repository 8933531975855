import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, findIndex, filter, map, find, forEach, size } from 'lodash';
import Flex from 'styled-flex-component';
import { LG, SM } from '@zendeskgarden/react-typography';
import { Col, Row } from '@zendeskgarden/react-grid';
import Checkbox from 'components/Checkbox/Checkbox';
import { variables } from 'theme/variables';
import {
  EndFinishFilterItemSelectionWrapper,
  SelectionCol,
  Item,
  EmptySelectionCol,
} from './EndFinishFilterItemSelection.styles';
import EndFinishFilterDropdown from './components/EndFinishFilterDropdown/EndFinishFilterDropdown';
import EndFinishFilterFilterResults from './components/EndFinishFilterFilterResults/EndFinishFilterFilterResults';

const { custom_light_grey: customLightGrey, spacing_sm: spacingSm } = variables;

function EndFinishFilterItemSelection({
  updateTotalSelected,
  setSelectedValue,
  filteredFinishValue,
  clearFinishFilterValue,
  optionValues,
  selectionFilters: selectionFiltersProp,
  selectedValue,
  selectedFilters,
  inputValue,
  onClose,
  allowMultiple,
}) {
  const [initiated, setInitiated] = useState();
  const [activeState, setActiveState] = useState({
    activeColumn: 0,
    activeColumnItem: 0,
    activeItem: null,
    finishes: [],
    activeFilters: [],
  });
  const { activeFilters, selectionFilters, finishes } = activeState;

  const selectedFinishes = filter(finishes, 'selected');

  const totalSelected = size(filter(finishes, ({ selected }) => selected));

  const chooseFinishes = useCallback(
    (_finishes, _activeFilters) => {
      const selected = filter(_finishes, 'selected');
      updateTotalSelected(size(selected) + size(_activeFilters));
      setSelectedValue(selected, { selectedFilters: _activeFilters });
    },
    [setSelectedValue, updateTotalSelected]
  );

  useEffect(() => {
    if (initiated) {
      chooseFinishes(finishes, activeFilters);
    }
  }, [activeFilters, chooseFinishes, initiated, finishes]);

  useEffect(() => {
    updateTotalSelected(totalSelected);
  }, [totalSelected, updateTotalSelected]);

  const onSelectFilter = useCallback(
    (selectedFilter, selected) => {
      let cf;
      if (!selected) {
        cf = [...activeFilters, selectedFilter];
      } else {
        const index = findIndex(activeFilters, selectedFilter);
        cf = [...activeFilters];
        cf.splice(index, 1);
      }

      setActiveState({
        ...activeState,
        activeFilters: cf,
      });
    },
    [activeFilters, activeState]
  );

  const onSelectItem = useCallback(
    (item, custom) => {
      const items = cloneDeep(finishes);
      if (custom === true) {
        items.push(item);
      }

      // Allow for cases where filters are generated from a different transformer which may not have set 'multiple' at all.
      // We are treating multiple === true as default
      if (allowMultiple === false) {
        forEach(items, (_, key) => {
          items[key].selected = false;
        });
      }

      const itemInList = find(items, item);
      if (itemInList) {
        itemInList.selected = !itemInList.selected;
      }
      setActiveState({
        ...activeState,
        finishes: items,
      });
    },
    [activeState, allowMultiple, finishes]
  );

  useEffect(() => {
    // when searching values, the event bubbles to root dropdown. we then pass that down to this componnet
    // where we set it. not the best performance but works
    if (filteredFinishValue?.finish?.value) {
      const v = filteredFinishValue;
      if (v.finish.type) {
        onSelectFilter(v.finish, v.selected);
      } else {
        onSelectItem(v.finish, v.customValue);
      }
      clearFinishFilterValue();
    }
  }, [
    clearFinishFilterValue,
    filteredFinishValue,
    onSelectFilter,
    onSelectItem,
  ]);

  function initInitialSelectedFinishes(_finishes, _selectedFinishes) {
    const items = map(_finishes, (g) => {
      const currentVg = find(_selectedFinishes, { value: g.value });
      return {
        ...g,
        selected: !!currentVg,
      };
    });
    const customValues = filter(_selectedFinishes, 'customValue');

    return [...items, ...customValues];
  }

  useEffect(() => {
    if (!initiated) {
      const finishItems = optionValues || [];
      const _selectionFilters = selectionFiltersProp || [];
      const _selectedFinishes = selectedValue || [];
      const _selectedFilters = selectedFilters || [];
      const selectedValueItems = initInitialSelectedFinishes(
        finishItems,
        _selectedFinishes
      );
      const defaultState = {
        ...activeState,
        finishes: selectedValueItems,
        selectionFilters: _selectionFilters,
        activeFilters: _selectedFilters,
      };
      setActiveState(defaultState);
      setInitiated(true);
    }
  }, [
    activeState,
    initiated,
    optionValues,
    selectedFilters,
    selectedValue,
    selectionFiltersProp,
  ]);

  function renderOptions() {
    if (!size(finishes)) {
      return <div />;
    }
    if (inputValue) {
      return (
        <EndFinishFilterFilterResults
          inputValue={inputValue}
          activeState={activeState}
        />
      );
    }
    return (
      <EndFinishFilterItemSelectionWrapper>
        <Flex justifyBetween alignCenter>
          <LG bold>Choose End Finish</LG>
          <SM bold justifyEnd onClick={onClose} link>
            Done
          </SM>
        </Flex>

        <Row style={{ marginTop: spacingSm, minHeight: '32px' }}>
          {map(selectionFilters, (sf, key) => (
            <Col style={{ marginTop: '5px' }} size="12" sm={4} key={key}>
              <EndFinishFilterDropdown
                title={key}
                activeFilters={activeFilters}
                options={sf}
                onSelectFilter={onSelectFilter}
              />
            </Col>
          ))}
        </Row>

        <Row style={{ marginTop: 0 }}>
          <Col sm={5} size={10}>
            <SelectionCol>
              {size(finishes) ? (
                map(finishes, (item) => {
                  const { label, value, selected } = item;
                  return (
                    <Item
                      onClick={() => {
                        onSelectItem(item);
                      }}
                      key={`${value}-${label}`}
                    >
                      <Checkbox checked={selected} />
                      {label}
                    </Item>
                  );
                })
              ) : (
                <EmptySelectionCol>No Items Match Criteria</EmptySelectionCol>
              )}
            </SelectionCol>
          </Col>

          <Col sm={7} size={10}>
            <SelectionCol>
              {!size(activeFilters) && !size(selectedFinishes) ? (
                <EmptySelectionCol>
                  No Items Have Been Selected
                </EmptySelectionCol>
              ) : null}
              {map(activeFilters, (_filter, i) => (
                <React.Fragment key={i}>
                  {i === 0 ? (
                    <Item key={-1} headerItem small>
                      Selected End Finish Groups
                    </Item>
                  ) : null}
                  <Item
                    onClick={() => onSelectFilter.bind(_filter, true)}
                    key={`${i}`}
                  >
                    <div>
                      <Flex alignCenter>
                        <Checkbox checked />
                        {_filter.label}
                      </Flex>
                    </div>
                  </Item>
                </React.Fragment>
              ))}
              {map(selectedFinishes, (item, i) => {
                const { label, value } = item;
                return (
                  <React.Fragment key={i}>
                    {i === 0 ? (
                      <Item key={-1} headerItem small>
                        Selected Finishes
                      </Item>
                    ) : null}
                    <Item
                      onClick={() => {
                        onSelectItem(item);
                      }}
                      key={`${value}-${label}`}
                    >
                      <div>
                        <Flex alignCenter>
                          <Checkbox checked />
                          {label}
                        </Flex>
                        <Flex alignCenter>
                          {item.end_finish_category ? (
                            <SM
                              ellipsis
                              grey
                              style={{
                                fontSize: '10px',
                                maxWidth: '105px',
                                borderRight: `1px solid ${customLightGrey}`,
                                paddingRight: '5px',
                                marginRight: '5px',
                              }}
                              size="small"
                              center
                            >
                              {
                                find(selectionFilters.end_finish_category, {
                                  value: item.end_finish_category,
                                }).label
                              }
                            </SM>
                          ) : null}
                          {item.end_finish_licensee ? (
                            <SM
                              ellipsis
                              grey
                              style={{
                                fontSize: '10px',
                                maxWidth: '105px',
                                paddingRight: '2px',
                                marginRight: '2px',
                              }}
                              size="small"
                              center
                            >
                              {
                                find(selectionFilters.end_finish_licensee, {
                                  value: item.end_finish_licensee,
                                }).label
                              }
                            </SM>
                          ) : null}
                          {item.end_finish_thread_type ? (
                            <SM
                              ellipsis
                              grey
                              style={{
                                fontSize: '10px',
                                maxWidth: '105px',
                                borderRight: `1px solid ${customLightGrey}`,
                                paddingRight: '5px',
                                marginRight: '5px',
                              }}
                              size="small"
                              center
                            >
                              {
                                find(selectionFilters.end_finish_thread_type, {
                                  value: item.end_finish_thread_type,
                                }).label
                              }
                            </SM>
                          ) : null}
                        </Flex>
                      </div>
                    </Item>
                  </React.Fragment>
                );
              })}
            </SelectionCol>
          </Col>
        </Row>
      </EndFinishFilterItemSelectionWrapper>
    );
  }

  return <>{renderOptions()}</>;
}

EndFinishFilterItemSelection.propTypes = {
  currentFilters: PropTypes.shape({
    end_finish: PropTypes.shape({
      multiple: PropTypes.bool,
    }),
  }),
  updateTotalSelected: PropTypes.func,
  setSelectedValue: PropTypes.func,
  filteredFinishValue: PropTypes.string,
  clearFinishFilterValue: PropTypes.func,
  optionValues: PropTypes.arrayOf(PropTypes.shape({})),
  selectionFilters: PropTypes.shape({}),
  selectedValue: PropTypes.arrayOf(PropTypes.shape({})),
  selectedFilters: PropTypes.arrayOf(PropTypes.shape({})),
  inputValue: PropTypes.string,
  onClose: PropTypes.func,
  allowMultiple: PropTypes.bool,
};

EndFinishFilterItemSelection.defaultProps = {
  currentFilters: undefined,
  updateTotalSelected: undefined,
  setSelectedValue: undefined,
  filteredFinishValue: undefined,
  clearFinishFilterValue: undefined,
  optionValues: undefined,
  selectionFilters: undefined,
  selectedValue: undefined,
  selectedFilters: undefined,
  inputValue: undefined,
  onClose: undefined,
  allowMultiple: undefined,
};

export default EndFinishFilterItemSelection;
