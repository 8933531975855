import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import DropZoneFileManager from 'components/DropZone/DropZoneFileManager';
import { TabList, Tab } from '@zendeskgarden/react-tabs';
import { trackClick } from 'utility/analytics';
import { Button } from 'theme/Button';
import Icon from 'components/Icon/Icon';
import { useBranch } from 'baobab-react/hooks';
import Flex from 'styled-flex-component';
import { IconButton } from '@zendeskgarden/react-buttons';
import { MD } from '@zendeskgarden/react-typography';
import { get } from 'lodash';
import { variables } from 'theme/variables';
import MultiTypeForm from 'components/MultiTypeForm/MultiTypeForm';
import {
  AddressList,
  PhoneNumbersList,
} from 'components/MultiTypeForm/components/LineItemRows/LineItemRows';
import {
  setCompanyInfo,
  submitCompany,
  getMyCompanies,
  saveCompanyPhoneNumber,
  saveCompanyAddress,
  deleteCompanyAddress,
  deleteCompanyPhoneNumber,
} from 'state/companies/actions';
import { addCompanySidebarShape } from 'propTypesObjects';
import {
  SidebarFilterTabs,
  SidebarFilterHeader,
  SidebarFilterBody,
  SidebarContentWrapper,
  SidebarFooterWrapper,
} from '../../GlobalSideBar.styles.js';

const { spacing } = variables;

function AddCompanySidebar({ onClickClose, onSetCloseWarn, options }) {
  const [isNewCompany, setIsNewCompany] = useState(options?.isNewCompany);
  const [tab, setActiveTab] = useState('details');
  const [fileCount, setFileCount] = useState();
  const { activeCompany, activeCompanyDetails, companyDetails, metadata } =
    useBranch({
      activeCompany: ['companies', 'activeCompany', 'companyFormData'],
      companyDetails: [
        'companies',
        'activeCompany',
        'companyDetails',
        'result',
      ],
      activeCompanyDetails: ['companies', 'activeCompany', 'companyDetails'],
      metadata: ['requestForQuote', 'meta', 'result'],
    });
  const onSetWarnSetting = useCallback(
    (warn) => {
      if (onSetCloseWarn) {
        onSetCloseWarn(warn);
      }
    },
    [onSetCloseWarn]
  );

  const onSubmitCompany = useCallback(
    async (e) => {
      trackClick('onSubmitCompany', 'Submit', 'AddCompanySidebar');
      e.preventDefault();

      const companyAdded = await submitCompany(get(companyDetails, 'id'));
      if (!companyAdded.error) {
        onSetWarnSetting(false);
        setIsNewCompany(false);
        if (options.onSubmit) {
          options.onSubmit();
        } else {
          getMyCompanies();
        }
      }
      return null;
    },
    [companyDetails, onSetWarnSetting, options]
  );

  const basePath = ['activeCompany', 'companyFormData'];
  let modalType;

  const onSaveAddress = useCallback(
    async (item, address) => {
      const _item = item || {};
      const result = await saveCompanyAddress(_item.id, {
        data: {
          ...address,
          company: companyDetails?.id,
        },
      });
      if (!result.error) {
        onSetWarnSetting(false);
      }
      return result;
    },
    [companyDetails?.id, onSetWarnSetting]
  );

  const onSavePhoneNumber = useCallback(
    async (item, phoneNumber) => {
      const itemCopy = item || {};
      const result = await saveCompanyPhoneNumber(itemCopy.id, {
        data: {
          ...phoneNumber,
          company: companyDetails.id,
        },
      });
      if (!result.error) {
        onSetWarnSetting(false);
      }
      return result;
    },
    [companyDetails?.id, onSetWarnSetting]
  );

  const onDeleteAddress = useCallback(async (item) => {
    const deleteAddress = await deleteCompanyAddress(item.id);
    return deleteAddress;
  }, []);

  const onDeletePhoneNumber = useCallback(async (item) => {
    const deleteNumber = await deleteCompanyPhoneNumber(item.id);
    return deleteNumber;
  }, []);

  function onSetChange(_basePath, category, type, data) {
    onSetWarnSetting(true);
    setCompanyInfo(_basePath, category, type, data);
  }

  if (fileCount > 0) {
    modalType = '';
  } else {
    modalType = 'upload';
  }

  function renderOptions() {
    if (tab === 'details') {
      return (
        <form onSubmit={onSubmitCompany}>
          <MultiTypeForm
            contentType={get(metadata, 'tag_content_types.Company')}
            formName="addCompany"
            error={get(activeCompanyDetails, 'error')}
            setData={(category, type, data) =>
              onSetChange(basePath, category, type, data)
            }
            data={activeCompany || {}}
          />
          {isNewCompany === false ? (
            <div
              style={{
                marginBottom: '10px',
              }}
            >
              <div
                style={{
                  fontWeight: 'bold',
                  fontSize: '12px',
                  textAlign: 'left',
                  marginBottom: '10px',
                }}
              >
                Upload Company Image
              </div>
              <DropZoneFileManager
                type={modalType}
                externalSubmit={false}
                hasExternalSubmit
                onRemoveFile={() => {
                  setFileCount(fileCount - 1);
                }}
                uploadOnDrop={() => {
                  if (fileCount > 0) {
                    return false;
                  }
                  return true;
                }}
                multiple={false}
                isImage
                showUploadAndRender
                objectId={companyDetails?.id}
                contentType={metadata?.conversation_content_types?.Company}
                disableDelete={false}
                setFileCount={setFileCount}
              />
            </div>
          ) : null}
        </form>
      );
    }
    if (tab === 'addresses') {
      return (
        <div>
          <AddressList
            onSetCloseWarn={onSetWarnSetting}
            addresses={get(companyDetails, 'addresses')}
            onSave={onSaveAddress}
            onDelete={onDeleteAddress}
          />
        </div>
      );
    }
    if (tab === 'phoneNumbers') {
      return (
        <div>
          <PhoneNumbersList
            onSetCloseWarn={onSetWarnSetting}
            phoneNumbers={get(companyDetails, 'phone')}
            onSave={onSavePhoneNumber}
            onDelete={onDeletePhoneNumber}
          />
        </div>
      );
    }
    return null;
  }

  return (
    <SidebarContentWrapper>
      <SidebarFilterHeader padding={`${spacing}`}>
        <Flex full justifyBetween alignCenter>
          <Flex alignCenter>
            <Icon icon="icon-profile" fontSize="18px" color="#fff" />
            <MD scaled paddingHorizontal bold color="#fff">
              Manage Company
            </MD>
          </Flex>
          <IconButton
            onClick={onClickClose}
            disableBackground
            alignCenter
            justifyEnd
            size="large"
            aria-label="closeSidebar"
          >
            <Icon button icon="icon-close" fontSize="13px" color="#fff" />
          </IconButton>
        </Flex>
      </SidebarFilterHeader>

      <SidebarFilterTabs>
        <TabList className="tabs no-border">
          <Tab
            slate
            className="tab"
            onClick={() => {
              trackClick('setRegister', 'current', 'CustomerApprovalSidebar');
              setActiveTab('details');
            }}
            selected={tab === 'details'}
          >
            Company Details
          </Tab>
          <Tab
            slate
            className="tab"
            disabled={!companyDetails}
            onClick={() => {
              trackClick('setTab', 'previous', 'CustomerApprovalSidebar');
              if (!companyDetails) {
                alert('The Company Must Be Saved Before Adding An Address');
              } else {
                setActiveTab('addresses');
              }
            }}
            selected={tab === 'addresses'}
          >
            Addresses
          </Tab>
          <Tab
            slate
            className="tab"
            disabled={!companyDetails}
            onClick={() => {
              trackClick('setTab', 'previous', 'CustomerApprovalSidebar');
              if (!companyDetails) {
                alert('The Company Must Be Saved Before Adding A Phone Number');
              } else {
                setActiveTab('phoneNumbers');
              }
            }}
            selected={tab === 'phoneNumbers'}
          >
            Phone Numbers
          </Tab>
        </TabList>
      </SidebarFilterTabs>

      <SidebarFilterBody>{renderOptions()}</SidebarFilterBody>
      {tab === 'details' ? (
        <SidebarFooterWrapper>
          <Button
            buttonStatus
            error={get(activeCompanyDetails, 'error')}
            loading={get(activeCompanyDetails, 'loading')}
            style={{ width: '100%' }}
            primary
            onClick={onSubmitCompany}
          >
            Submit Company
          </Button>
        </SidebarFooterWrapper>
      ) : null}
    </SidebarContentWrapper>
  );
}

AddCompanySidebar.defaultProps = {
  onClickClose: undefined,
  onSetCloseWarn: undefined,
  options: addCompanySidebarShape,
};

AddCompanySidebar.propTypes = {
  onClickClose: PropTypes.func,
  onSetCloseWarn: PropTypes.func,
  options: addCompanySidebarShape,
};

export default AddCompanySidebar;
