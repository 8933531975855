import React from 'react';
import { LG, MD, SM } from '@zendeskgarden/react-typography';
import { Button } from 'theme/Button';
import { variables } from 'theme/variables';
import Flex, { FlexItem } from 'styled-flex-component';
import { PropTypes } from 'prop-types';
import { NoDataPlaceholderWrapper } from './NoDataPlaceholder.styles.js';

const { spacing_sm: spacingSm, spacing } = variables;

function NoDataPlaceholder({ children, copy, action }) {
  return children ? (
    <Flex style={{ minHeight: '100px' }} full alignCenter justifyCenter>
      {children}
    </Flex>
  ) : (
    <NoDataPlaceholderWrapper>
      <Flex style={{ height: '90%' }} alignCenter justifyCenter>
        <FlexItem>
          <SM
            slate
            scaled
            center
            style={{ padding: `0 0 ${spacingSm} 0` }}
            tag="h2"
          >
            {copy}
          </SM>
          <MD
            slate
            bold
            center
            style={{ padding: `0 0 ${spacingSm} 0` }}
            tag="h1"
          >
            No Content Available.
          </MD>
          {!!action && (
            <Button center large onClick={action?.onClick} primary>
              {action?.buttonText}
            </Button>
          )}
        </FlexItem>
      </Flex>
    </NoDataPlaceholderWrapper>
  );
}

NoDataPlaceholder.defaultProps = {
  children: undefined,
  copy: undefined,
  action: undefined,
};

NoDataPlaceholder.propTypes = {
  children: PropTypes.node,
  copy: PropTypes.string,
  action: PropTypes.shape({
    onClick: PropTypes.func,
    buttonText: PropTypes.string,
  }),
};

export function FormattedEmpty({ styleOverrides }) {
  return (
    <NoDataPlaceholderWrapper
      style={{
        width: '100%',
        marginTop: '50px',
        position: 'relative',
        ...styleOverrides,
      }}
    >
      <Flex style={{ height: '90%' }} alignCenter justifyCenter>
        <FlexItem>
          {/* <IconImage/> */}
          <LG
            slate
            bold
            center
            style={{ marginBottom: 0, padding: `0 0 ${spacing} 0` }}
            tag="h1"
          >
            No Content Available.
          </LG>
          <MD slate paddingBottomLg center tag="h3">
            Try adjusting your search or filter criteria.{' '}
          </MD>
        </FlexItem>
      </Flex>
    </NoDataPlaceholderWrapper>
  );
}

FormattedEmpty.defaultProps = {
  styleOverrides: undefined,
};

FormattedEmpty.propTypes = {
  styleOverrides: PropTypes.shape({}),
};

export default NoDataPlaceholder;
