import React, { useCallback, useEffect, useState } from 'react';
import { trackClick } from 'utility/analytics';
import { Button } from 'theme/Button';
import Icon from 'components/Icon/Icon';
import { useBranch } from 'baobab-react/hooks';
import Flex from 'styled-flex-component';
import { IconButton } from '@zendeskgarden/react-buttons';
import { MD, LG } from '@zendeskgarden/react-typography';
import { variables } from 'theme/variables';
import MultiTypeForm from 'components/MultiTypeForm/MultiTypeForm';
import PropTypes from 'prop-types';
import {
  setActiveEmployee,
  setEmployeeInfo,
  addEmployeeRole,
  getEmployees,
} from 'state/employees/actions.js';
import { isFormValid } from 'utility/dynamicFormHelpers';
import { keys, includes, findIndex, slice, forEach, isEmpty } from 'lodash';
import { Link } from 'react-router-dom';
import { getRole } from '../../../../utility/hasAccount';
import {
  SidebarFilterHeader,
  SidebarFilterBody,
  SidebarContentWrapper,
  SidebarFooterWrapper,
} from '../../GlobalSideBar.styles.js';

const { spacing } = variables;

function AddEmployeeSidebar({ onClickClose, onSetCloseWarn }) {
  const basePath = ['activeEmployee'];
  const { employee, userRole } = useBranch({
    employee: ['employees', 'activeEmployee'],
    userRole: ['authentication', 'session', 'roles'],
  });

  const [currentState, updateCurrentState] = useState({});

  const onSetWarnSetting = useCallback(
    (warn) => {
      if (onSetCloseWarn) {
        onSetCloseWarn(warn);
      }
    },
    [onSetCloseWarn]
  );

  const getUrlReadyObject = useCallback((currentEmployee) => {
    const urlReadyObj = {};
    const fieldKeys = keys(currentEmployee);
    forEach(fieldKeys, (keyName) => {
      const fieldData = currentEmployee[keyName];
      urlReadyObj[keyName] = fieldData?.value?.value;
    });
    return urlReadyObj;
  }, []);

  const onSubmitEmployee = useCallback(
    async (e) => {
      trackClick('onSubmitEmployee', 'Submit', 'AddEmployeeSidebar');
      e.preventDefault();
      if (isFormValid(employee)) {
        updateCurrentState({ loading: true });
        const urlReadyObject = getUrlReadyObject(employee?.employee?.fields);
        const employeeAdded = await addEmployeeRole(urlReadyObject);
        if (employeeAdded.error) {
          updateCurrentState({ error: employeeAdded?.error });
        } else {
          updateCurrentState({
            loading: false,
            success: 'Employee successfully created',
          });
          onSetWarnSetting(false);
          getEmployees({});
        }
      }
    },
    [employee, getUrlReadyObject, onSetWarnSetting]
  );

  const updateEmployeeData = useCallback(() => {
    const currentUserRole = !isEmpty(getRole()) || keys(userRole)[0];
    const listOfRoles = employee?.employee?.fields?.role?.items || [];

    let newRoles;
    let roleIdx;
    switch (currentUserRole) {
      case 'facilitatorpartner':
        roleIdx = findIndex(listOfRoles, (role) =>
          includes(role.value, 'partner')
        );
        newRoles = slice(listOfRoles, roleIdx + 1);
        break;
      case 'facilitatoroperator':
        roleIdx = findIndex(listOfRoles, (role) =>
          includes(role.value, 'operator')
        );
        newRoles = slice(listOfRoles, roleIdx + 1);
        break;
      case 'facilitatormanager':
        roleIdx = findIndex(listOfRoles, (role) =>
          includes(role.value, 'manager')
        );
        newRoles = slice(listOfRoles, roleIdx + 1);
        break;
      case 'facilitatorexecutive':
        roleIdx = findIndex(listOfRoles, (role) =>
          includes(role.value, 'executive')
        );
        newRoles = slice(listOfRoles, roleIdx + 1);
        break;
      case 'facilitatoradministrator':
        roleIdx = findIndex(listOfRoles, (role) =>
          includes(role.value, 'administrator')
        );
        newRoles = slice(listOfRoles, roleIdx + 1);
        break;
      default:
        newRoles = listOfRoles;
    }

    const updatedEmployeeData =
      listOfRoles !== newRoles
        ? {
            ...employee,
            employee: {
              ...employee?.employee,
              fields: {
                ...employee?.employee?.fields,
                role: {
                  ...employee?.employee?.fields?.role,
                  items: newRoles,
                },
              },
            },
          }
        : employee;

    return updatedEmployeeData;
  }, [userRole, employee]);

  const onSetChange = useCallback(
    async (bP, category, type, data) => {
      onSetWarnSetting(true);
      setEmployeeInfo(bP, category, type, data);
    },
    [onSetWarnSetting]
  );

  useEffect(() => {
    setActiveEmployee({});
  }, []);

  return (
    <SidebarContentWrapper>
      <SidebarFilterHeader padding={`${spacing}`}>
        <Flex full justifyBetween alignCenter>
          <Flex alignCenter>
            <Icon icon="icon-profile" fontSize="18px" color="#fff" />
            <MD scaled paddingHorizontal bold color="#fff">
              Add Employee
            </MD>
          </Flex>
          <IconButton
            onClick={onClickClose}
            disableBackground
            alignCenter
            justifyEnd
            size="large"
            aria-label="closeSidebar"
          >
            <Icon button icon="icon-close" fontSize="13px" color="#fff" />
          </IconButton>
        </Flex>
      </SidebarFilterHeader>
      <SidebarFilterBody>
        {currentState?.success ? (
          <div>
            <LG headline style={{ textAlign: 'center', marginTop: '20%' }} bold>
              Employee successfully created
            </LG>
            <MD
              headline
              style={{ padding: '50px 0 20px', textAlign: 'center' }}
            >
              You can now change your new employee&apos;s role on the{' '}
              <strong>Permissions</strong> tab in your <strong>Settings</strong>
            </MD>
            <MD
              headline
              style={{ padding: '10px 0 10px', textAlign: 'center' }}
            >
              To further edit your new employee&apos;s details, you can edit
              them from the <strong>User</strong>&apos;s table
            </MD>
            <MD center tag="p">
              <Link to="/dashboard/users"> Go to Users Table </Link>
            </MD>
          </div>
        ) : (
          <form onSubmit={onSubmitEmployee}>
            <MultiTypeForm
              formName="addEmployee"
              error={currentState?.error}
              setData={(...otherChanges) =>
                onSetChange(basePath, ...otherChanges)
              }
              data={updateEmployeeData()}
            />
          </form>
        )}
      </SidebarFilterBody>
      <SidebarFooterWrapper>
        {!currentState?.success ? (
          <Button
            buttonStatus
            error={!!currentState?.error}
            loading={!!currentState?.loading}
            success={!!currentState?.success}
            style={{ width: '100%' }}
            primary
            onClick={onSubmitEmployee}
            disabled={!isFormValid(employee)}
          >
            Submit Employee
          </Button>
        ) : null}
      </SidebarFooterWrapper>
    </SidebarContentWrapper>
  );
}

AddEmployeeSidebar.defaultProps = {
  onClickClose: undefined,
  options: {},
  onSetCloseWarn: undefined,
};

AddEmployeeSidebar.propTypes = {
  onClickClose: PropTypes.func,
  options: PropTypes.shape({}),
  onSetCloseWarn: PropTypes.func,
};

export default AddEmployeeSidebar;
