import { map, get, reduce, toLower } from 'lodash';
import tree from 'state';
import { onboardingReverser, baseValidation } from 'utility/dynamicFormHelpers';
import { variables } from 'theme/variables';

const { media_md: mediaMd } = variables;

export function generateCustomer({ metadata, seedData } = {}) {
  const rfqMetadata = tree.select(['requestForQuote']).get(['meta', 'result']);
  const newMetadata =
    metadata || tree.select(['onboarding']).get(['meta', 'result']) || {};
  let config = {
    customer: {
      title: 'Contact',
      fields: {
        first_name: {
          required: true,
          value: { value: '', label: '' },
          validation: baseValidation,
          ui: {
            sm: 12,
            md: '6',
            lg: '6',
            extraPadding: mediaMd,
          },
        },
        last_name: {
          required: true,
          value: { value: '', label: '' },
          validation: baseValidation,
          ui: {
            sm: 12,
            md: '6',
            lg: '6',
            extraPadding: mediaMd,
          },
        },
        email: {
          required: true,
          value: { value: '', label: '' },
          validation: baseValidation,
          ui: {
            sm: 12,
            md: '6',
            extraPadding: mediaMd,
          },
        },
        mobile_phone: {
          phone: true,
          required: true,
          value: { value: '', label: '' },
          validation: baseValidation,
          ui: {
            sm: 12,
            md: '6',
            extraPadding: mediaMd,
          },
        },

        contact_status: {
          disabled: false,
          validation: baseValidation,
          items: map(newMetadata.contact_status, ({ name, id }) => ({
            value: id,
            label: name,
          })),
          ui: {
            title: 'Status',
            condensed: true,
            placeholder: 'Enter Status',
            sm: 12,
            md: '6',
            lg: '6',
            extraPadding: mediaMd,
          },
        },
        priority: {
          disabled: false,
          validation: baseValidation,
          items: map(rfqMetadata.priority, ({ name, id }) => ({
            value: id,
            label: name,
          })),
          ui: {
            title: 'Priority',
            condensed: true,
            placeholder: 'Enter Priority',
            sm: 12,
            md: '6',
            lg: '6',
            extraPadding: mediaMd,
          },
        },
        linkedin_url: {
          required: false,
          value: { value: '', label: '' },
          validation: baseValidation,
          ui: {
            sm: 12,
            md: '6',
            extraPadding: mediaMd,
          },
        },
        tag: {
          required: false,
          customValues: false,
          key: 'tag',
          validation: baseValidation,
          ignoreCustomFieldValidation: true,
          multiSelect: true,
          items: [],
          value: [],
          ui: {
            title: 'Tag',
            condensed: true,
            placeholder: 'Enter Tag',
            sm: 12,
            md: 6,
            lg: 6,
            extraPadding: mediaMd,
          },
        },
      },
    },
  };

  if (seedData) {
    config = onboardingReverser(config, seedData);
    config.valid = true;
  } else {
    config.temp = true;
  }
  return config;
}

export function createBaseFlexCustomer(value, key, type = '') {
  const isNumber = toLower(type) === 'number';
  const newValue = isNumber && value ? Number(value) : value || '';
  return {
    [key]: {
      value: { value: newValue, label: newValue },
      forceValidation: true,
      valid: true,
      isNumber,
      validation: (args, required, field) =>
        baseValidation(args, required, field, {
          forceValidation: true,
          isNumber,
        }),
      ui: {
        sm: 12,
        md: '6',
        lg: '6',
        extraPadding: mediaMd,
      },
    },
  };
}

export function generateFlexCustomer({ seedData, schema } = {}) {
  const flexData = get(seedData, 'flex') || {};
  const fields = reduce(
    schema,
    (accum, d, k) => {
      if (!d.entity_id) {
        return accum;
      }
      const newAccum = {
        ...(accum || {}),
        ...createBaseFlexCustomer(flexData[k], k, d.type),
      };
      return newAccum;
    },
    {}
  );

  const config = {
    customFields: {
      fields,
    },
  };
  return config;
}
