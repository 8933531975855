import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TabList, Tab } from '@zendeskgarden/react-tabs';
import Icon from 'components/Icon/Icon';
import { useBranch } from 'baobab-react/hooks';
import Flex from 'styled-flex-component';
import { IconButton } from '@zendeskgarden/react-buttons';
import { MD } from '@zendeskgarden/react-typography';
import { get } from 'lodash';
import { closeSidebar } from 'state/sidebars/actions';
import { variables } from 'theme/variables';
import EngagementForm from 'components/BasicForms/EngagementForm.js';
import { PersonnelContainer } from 'components/BasicForms/PersonnelForm.js';
import CompanyForm from 'components/BasicForms/CompanyForm.js';
import { engagementItemsShape } from 'propTypesObjects.js';
import {
  SidebarFilterTabs,
  SidebarFilterHeader,
  SidebarFilterBody,
  SidebarContentWrapper,
} from '../../GlobalSideBar.styles.js';

const { spacing } = variables;

function AddEngagementSidebar({ options }) {
  const [tab, setActiveTab] = useState(options.tab || 'details');
  const [engagement, setEngagement] = useState(options.engagement);
  const engagementId = engagement && engagement.id;
  const { metadata } = useBranch({
    metadata: ['requestForQuote', 'meta', 'result'],
  });

  return (
    <SidebarContentWrapper>
      <SidebarFilterHeader padding={`${spacing}`}>
        <Flex full justifyBetween alignCenter>
          <Flex alignCenter>
            <MD scaled bold color="#fff">
              Add Engagement
            </MD>
          </Flex>
          <IconButton
            onClick={closeSidebar}
            disableBackground
            alignCenter
            justifyEnd
            size="large"
            aria-label="closeSidebar"
          >
            <Icon button icon="icon-close" fontSize="13px" color="#fff" />
          </IconButton>
        </Flex>
      </SidebarFilterHeader>
      <SidebarFilterTabs>
        <TabList className="tabs no-border">
          <Tab
            className="tab"
            slate
            onClick={() => {
              setActiveTab('details');
            }}
            selected={tab === 'details'}
          >
            Engagement Details
          </Tab>
          <Tab
            disabled={!engagementId}
            className="tab"
            slate
            onClick={
              !engagementId
                ? null
                : () => {
                    setActiveTab('personnel');
                  }
            }
            selected={tab === 'personnel'}
          >
            Personnel
          </Tab>
          <Tab
            disabled={!engagementId}
            className="tab"
            slate
            onClick={
              !engagementId
                ? null
                : () => {
                    setActiveTab('companies');
                  }
            }
            selected={tab === 'companies'}
          >
            Companies
          </Tab>
        </TabList>
      </SidebarFilterTabs>
      <SidebarFilterBody>
        {
          {
            details: (
              <EngagementForm
                onSuccess={(engagementResult) => {
                  setEngagement(engagementResult);
                  if (options.table) {
                    setActiveTab('companies');
                  } else {
                    setActiveTab('personnel');
                  }
                }}
                onDelete={() => closeSidebar()}
                engagementId={engagementId}
                data={engagement}
                companyId={options.companyId}
              />
            ),
            personnel: (
              <PersonnelContainer
                type="engagement"
                contentType={get(metadata, 'crm_content_types.engagement')}
                objectId={engagementId}
                data={engagement}
              />
            ),
            companies: (
              <CompanyForm
                data={engagement}
                onSuccess={(engagementResult) => {
                  setEngagement(engagementResult);
                }}
              />
            ),
          }[tab]
        }
      </SidebarFilterBody>
    </SidebarContentWrapper>
  );
}

AddEngagementSidebar.defaultProps = {
  options: undefined,
};

AddEngagementSidebar.propTypes = {
  options: PropTypes.shape({
    tab: PropTypes.string,
    engagement: engagementItemsShape,
    table: PropTypes.bool,
    companyId: PropTypes.number,
  }),
};

export default AddEngagementSidebar;
