import React from 'react';
import AutoCompleteFormItem from 'components/MultiTypeForm/components/MultiSelectFormItem/AutoCompleteFormItem.js';
import { getSuggestion } from 'state/application/actions';
import PropTypes from 'prop-types';

function CompanyLookupV2({
  style,
  placeholder,
  selectedItem,
  setSelectedValue,
  clientId,
  searchType,
  valueKey,
}) {
  const search = {
    company: {
      clientId,
    },
    user: {
      clientId,
      includeContacts: false,
      labelKey: 'full_name',
      valueKey,
    },
    contact: {
      clientId,
      isUser: false,
      labelKey: 'full_name',
    },
  };

  return (
    <AutoCompleteFormItem
      style={style}
      defaultValue={placeholder || `Search ${searchType}`}
      selectedItem={selectedItem}
      setSelectedValue={(value) => {
        setSelectedValue(value);
      }}
      search={(value) => getSuggestion(searchType, value, search[searchType])}
      awaitOptionValues={(value) =>
        getSuggestion(searchType, value, search[searchType])
      }
      active={selectedItem !== ''}
    />
  );
}

CompanyLookupV2.defaultProps = {
  style: undefined,
  placeholder: undefined,
  selectedItem: undefined,
  setSelectedValue: undefined,
  clientId: undefined,
  searchType: undefined,
  valueKey: undefined,
};

CompanyLookupV2.propTypes = {
  style: PropTypes.shape({}),
  placeholder: PropTypes.string,
  selectedItem: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  setSelectedValue: PropTypes.func,
  clientId: PropTypes.number,
  searchType: PropTypes.string,
  valueKey: PropTypes.string,
};

export default CompanyLookupV2;
