import React from 'react';
import { Grid, Row, Col } from '@zendeskgarden/react-grid';
import styled from 'styled-components/macro';
import { PropTypes } from 'prop-types';
import { variables } from './variables';

const {
  app_page_padding: appPagePadding,
  media_sm: mediaSm,
  media_md: mediaMd,
  app_page_padding_medium: appPagePaddingMedium,
  app_page_padding_small: appPagePaddingSmall,
  sidebar_menu_width: sidebarMenuWidth,
  sidebar_menu_minimized: sidebarMenuMinimized,
  app_header_height: appHeaderHeight,
  app_mobile_header_height: appMobileHeaderHeight,
  app_footer_height: appFooterHeight,
  spacing,
  spacing_lg: spacingLg,
  spacing_sm: spacingSm,
  custom_border_grey: customBorderGrey,
  public_app_max_width: publicAppMaxWidth,
} = variables;

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  background: ${(props) => props?.background || undefined};
  padding: ${(props) =>
    props?.padding || props?.mobilePadding || appPagePaddingSmall};
  @media ${mediaSm} {
    padding: ${(props) =>
      props?.padding || props?.tabletPadding || appPagePaddingMedium};
  }
  @media ${mediaMd} {
    padding: ${(props) => props?.padding || appPagePadding};
  }
`;

export const PageCard = styled.div`
  border-radius: 6px;
  border: 1px solid ${customBorderGrey};
  background: ${(props) => props?.background || '#fff'};
  padding: ${(props) => {
    if (props?.padding) {
      return props.padding;
    }
    if (props?.small) {
      return `${spacing} ${spacingSm}`;
    }
    return spacingSm;
  }};
  margin-bottom: ${(props) => props?.marginBottom || spacingLg};
  transition: all 0.3s ease;

  ${(props) =>
    props?.withHeader
      ? `
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
  `
      : ''}
  ${(props) =>
    props?.withFooter
      ? `
    padding-bottom: 0px;
  `
      : ''}
  ${(props) =>
    props?.hover
      ? `
    &:hover {
      box-shadow: 0px 4px 5px rgba(66,100,251,0.1);
      cursor: pointer;
    }
  `
      : ''}

  @media ${mediaSm} {
    padding: ${(props) => {
      if (props?.padding) {
        return props.padding;
      }
      if (props?.small) {
        return `${spacing} ${spacingLg}`;
      }
      return spacingLg;
    }};
    ${(props) =>
      props?.withHeader
        ? `
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
  `
        : ''}
    ${(props) =>
      props?.withFooter
        ? `
    padding-bottom: 0px;
  `
        : ''}
  ${(props) =>
      props?.hover
        ? `
    &:hover {
      box-shadow: 0px 4px 5px rgba(66,100,251,0.1);
      cursor: pointer;
    }
  `
        : ''}
  }
`;

export const PageCardHeader = styled.div`
  border-bottom: 1px solid ${customBorderGrey};
  padding: ${(props) => {
    if (props?.padding) {
      return props.padding;
    }
    if (props?.small) {
      return `${spacing} ${spacing}`;
    }
    return spacing;
  }};
  ${(props) =>
    props?.withButton
      ? `
    padding: ${() => {
      if (props?.padding) {
        return props.padding;
      }
      if (props?.small) {
        return `10px ${spacing}`;
      }
      return spacing;
    }};
  `
      : ''}

  @media ${mediaMd} {
    padding: ${(props) => {
      if (props?.padding) {
        return props.padding;
      }
      if (props?.small) {
        return `${spacing} ${spacingLg}`;
      }
      return spacingLg;
    }};
    ${(props) =>
      props?.withButton
        ? `
      padding: ${() => {
        if (props?.padding) {
          return props.padding;
        }
        if (props?.small) {
          return `10px ${spacingLg}`;
        }
        return spacingLg;
      }};
    `
        : ''}
  }
`;

export const PageCardFooter = styled.div`
  padding: ${(props) => {
    if (props?.padding) {
      return props.padding;
    }
    if (props?.small) {
      return `10px ${spacingLg}`;
    }
    return spacingLg;
  }};
  border-top: 1px solid ${customBorderGrey};
  ${(props) =>
    props.withButton
      ? `
    padding: ${() => {
      if (props?.padding) {
        return props.padding;
      }
      if (props?.small) {
        return `10px ${spacingLg}`;
      }
      return spacingLg;
    }};
  `
      : ''}
`;

export const PageCardBody = styled.div`
  padding: ${(props) => {
    if (props?.padding) {
      return props.padding;
    }
    if (props?.small) {
      return `${spacing} ${spacingSm}`;
    }
    return spacingSm;
  }};
  padding-bottom: ${(props) => {
    if (props?.paddingBottom) {
      if (props?.padding) {
        return props.padding;
      }
      if (props?.small) {
        return `${spacing} ${spacingSm}`;
      }
      return spacingSm;
    }
    return '0px';
  }};
  @media ${mediaMd} {
    padding: ${(props) => {
      if (props?.padding) {
        return props.padding;
      }
      if (props?.small) {
        return `${spacing} ${spacingLg}`;
      }
      return spacingLg;
    }};
    padding-bottom: ${(props) => {
      if (props?.paddingBottom) {
        if (props?.padding) {
          return props.padding;
        }
        if (props?.small) {
          return `${spacing} ${spacingLg}`;
        }
        return spacingLg;
      }
      return '0px';
    }};
  }
`;

export const SidebarPage = styled.div`
  padding: 10px;
  padding-top: 0;
  padding-bottom: 0;
  @media ${mediaMd} {
    padding-left: ${sidebarMenuWidth};
    padding-bottom: 0px;
    padding-right: 0px;
    padding-top: 0;
    transition: all 0.2s ease;
    ${(props) =>
      props.sidebarMinimized
        ? `
      padding-left: ${sidebarMenuMinimized};
    `
        : ''}
  }
`;

export const SidebarPageBody = styled.div`
  padding: ${appPagePaddingSmall};
  position: relative;
  background-color: #fff;
  @media ${mediaMd} {
    padding: ${appPagePaddingSmall};
  }
  &.container-dashboard,
  &.container-assessment,
  &.container-order-initiation,
  &.container-order-management,
  &.container-information,
  &.container-items,
  &.container-activity,
  &.container-documents {
    padding: 0 !important;
    background: transparent !important;
  }
`;

export const SidebarPageMenu = styled.section`
  padding-top: 0px;
  display: flex;
  white-space: unset;
  display: block;
  height: 100%;
  position: fixed;
  left: 0;
  margin-bottom: 0;
  width: ${sidebarMenuWidth};
  overflow: scroll;
  min-height: calc(100% - ${appHeaderHeight});
  top: ${appHeaderHeight};
  padding-bottom: ${appFooterHeight};
  transition: all 0.2s ease;
  button {
    min-width: 100% !important;
  }
  ${(props) => {
    if (props.sidebarMinimized && props.endUserMenu) {
      return `width: 70px;`;
    }
    if (props.sidebarMinimized && !props.settingsMenu) {
      return `width: ${sidebarMenuMinimized};`;
    }
    return '';
  }}
  & {
    ::-webkit-scrollbar {
      display: none;
    }
  }
  @media (max-width: 767px) {
    display: none;
    padding-top: 0;
    top: ${appMobileHeaderHeight};
    white-space: nowrap;
    overflow: auto;
    height: 50px;
    padding: 0;
    width: 100%;
    margin-bottom: 5px;
    max-height: 100%;
    padding-top: 5px;
    border-right: 1px solid #eff4f9;
    -webkit-overflow-scrolling: touch;
    overflow-scrolling: touch;
    & {
      ::-webkit-scrollbar {
        display: none;
      }
    }
  }
`;

export const SidebarSecondaryHeader = styled.div`
  width: 100vw;
  margin-left: -${appPagePaddingSmall};
  border-bottom: 1px solid #eff4f9;
  display: flex;
  align-items: center;
  background: #f7f9fb;
  height: 60px;
  padding-right: 10px;
  padding-left: 10px;
  @media ${mediaMd} {
    margin-left: 0;
    max-width: 100%;
    overflow: auto;
    // overflow-x: auto;
    // overflow-y: hidden;
    padding-right: 20px;
    padding-left: 20px;
  }
`;

export const StyledLandingSection = styled('div')`
  background-color: ${(props) => props.background || '#fff'};
  position: relative;
  ${(props) =>
    props.skewed
      ? `
    margin-top: 100px;
    &:before {
      content: "";
      background: #f3f5f7;
      position: absolute;
      bottom: 0;
      top: -69px;
      transform: rotate(-3.4deg);
      height: 100%;
      left: -51px;
      right: -51px;
    }
  `
      : ''}
`;

export const LandingSectionInner = styled('div')`
  max-width: ${(props) =>
    props.page === 'landing' ? '100%' : publicAppMaxWidth};
  margin: 0 auto;
  padding: ${() => '50px' || '0'} 15px 50px;
  @media ${mediaSm} {
    padding: ${() => '50px' || '0'} 30px 50px;
  }
  ${(props) =>
    props.large
      ? `
    padding: 50px 15px 50px;
    @media ${mediaSm} {
      padding: 80px 15px 80px;
    }
  `
      : ''}
`;

export const LandingCard = styled('div')`
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  padding: 30px;
  background: #fff;
`;

export const CardDraftWrapper = styled('div')`
  ins {
    text-decoration: underline;
  }
  blockquote {
    border-left: 2px solid #a2a2a2;
    color: #5d5d5d;
    font-style: italic;
    margin: 12px 0 40px 0;
    padding: 6px 0 5px 18px;
  }
  ol,
  ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 30px;
  }
  ol {
    list-style: decimal;
    li {
      display: list-item;
    }
  }
  ul {
    li {
      display: list-item;
    }
  }
  a {
    line-break: anywhere;
  }
  img {
    max-width: 80%;
    max-height: 500px;
  }
`;

export function LandingSection({
  skewed,
  background,
  large,
  style,
  colStyle,
  children,
  page,
}) {
  return (
    <StyledLandingSection skewed={skewed} background={background}>
      <LandingSectionInner
        large={large}
        background={background}
        style={style || {}}
        page={page}
      >
        <Grid>
          <Row>
            <Col style={colStyle || {}}>{children}</Col>
          </Row>
        </Grid>
      </LandingSectionInner>
    </StyledLandingSection>
  );
}

LandingSection.defaultProps = {
  skewed: undefined,
  background: undefined,
  large: undefined,
  style: undefined,
  colStyle: undefined,
  children: undefined,
  page: undefined,
};

LandingSection.propTypes = {
  skewed: PropTypes.bool,
  background: PropTypes.bool,
  large: PropTypes.bool,
  style: PropTypes.shape({}),
  colStyle: PropTypes.shape({}),
  children: PropTypes.node,
  page: PropTypes.string,
};
