import React, { useCallback, useState } from 'react';
import { SM } from '@zendeskgarden/react-typography';
import { Formik } from 'formik';
import { FormInput, Form } from 'theme/Form';
import { Row, Col } from '@zendeskgarden/react-grid';
import { FullFormFieldWrapper } from 'theme/AuthForms.styles';
import { useBranch } from 'baobab-react/hooks';
import { get, map } from 'lodash';
import { Button } from 'theme/Button';

import { convertFromRaw, EditorState } from 'draft-js';
import Editor from 'draft-js-plugins-editor';
import createLinkifyPlugin from 'draft-js-linkify-plugin';
import createToolbarPlugin from 'draft-js-static-toolbar-plugin';
import createLinkPlugin from 'draft-js-anchor-plugin';
import {
  createInteraction,
  updateInteraction,
  deleteInteraction,
} from 'state/crm/actions';
import { getRawDraft } from 'utility/draftjsUtilities';
import { closeSidebar } from 'state/sidebars/actions';
import AutoCompleteFormItem from 'components/MultiTypeForm/components/MultiSelectFormItem/AutoCompleteFormItem.js';
import DatePicker from 'components/DatePicker/DatePicker';
import {
  ItalicButton,
  BoldButton,
  UnderlineButton,
  UnorderedListButton,
  OrderedListButton,
} from 'draft-js-buttons';
import { variables } from 'theme/variables';
import { formProps } from 'propTypesObjects.js';
import { Wrapper, WrapperInner } from './CampaignEmailSettingsForm.styles.js';

const { color_red_400: colorRed400 } = variables;

function InteractionForm({
  disabled,
  interactionId,
  canContinue,
  data = {},
  onSuccess,
}) {
  const [deletingStatus, setDeletingStatus] = useState({});
  const [localInteractionId, setLocalInteractionId] = useState(interactionId);
  const [editorState, setEditorState] = useState(
    data.json?.draft
      ? EditorState.createWithContent(convertFromRaw(data.json.draft))
      : EditorState.createEmpty()
  );
  const { rfqMeta } = useBranch({
    rfqMeta: ['requestForQuote', 'meta', 'result'],
  });
  // the list of types
  // eg is this an email or call interaction
  const interactionTypeOptions = get(rfqMeta, 'interaction_type');

  const submitInteraction = useCallback(
    async (values, { setSubmitting, setStatus }) => {
      setSubmitting(true);
      const outData = { ...values, json: getRawDraft(editorState) };
      outData.type = outData.type.value;
      outData.content_type = data.contentType;
      outData.object_id = data.objectId;

      const saveInteractionResult = localInteractionId
        ? await updateInteraction(localInteractionId, outData)
        : await createInteraction(outData);

      const { error } = saveInteractionResult;
      if (error) {
        setStatus({ error });
      } else {
        setStatus({ error });
        setLocalInteractionId(saveInteractionResult.id);
        if (onSuccess) {
          onSuccess(saveInteractionResult);
        }
      }
      setSubmitting(false);
    },
    [
      data.contentType,
      data.objectId,
      editorState,
      localInteractionId,
      onSuccess,
    ]
  );

  const onDeleteInteraction = useCallback(async () => {
    const { id } = data;
    setDeletingStatus({ loading: true });
    const result = await deleteInteraction(id);
    if (result.error) {
      setDeletingStatus({ error: result.error });
    } else {
      setDeletingStatus({ loading: false });
      if (onSuccess) {
        onSuccess({ id });
      }
      closeSidebar();
    }
  }, [data, onSuccess]);

  return (
    <Formik
      initialValues={{
        title: data.title || '',
        date: data.date || '',
        type: data.type ? { value: data.type, label: data.type_name } : '',
      }}
      onSubmit={submitInteraction}
    >
      {(props) => (
        <FormRendered
          interactionId={localInteractionId}
          deletingStatus={deletingStatus}
          onDelete={onDeleteInteraction}
          // LINT OVERRIDE #3
          // Component wraps another component
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          disabled={disabled}
          canContinue={canContinue}
          interactionTypeOptions={interactionTypeOptions}
          {...{ editorState, setEditorState }}
        />
      )}
    </Formik>
  );
}

InteractionForm.defaultProps = {
  disabled: undefined,
  interactionId: undefined,
  canContinue: undefined,
  data: undefined,
  onSuccess: undefined,
};

InteractionForm.propTypes = {
  ...formProps.generalForm.propTypes,
};

function FormRendered({
  deletingStatus,
  interactionId,
  onDelete,
  disabled,
  editorState,
  setEditorState,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  isSubmitting,
  status,
  setFieldValue,
  interactionTypeOptions,
}) {
  const error =
    (status && status.error) || (deletingStatus && deletingStatus.error);
  return (
    <Form size="large" onSubmit={handleSubmit}>
      <Row>
        <Col size="6">
          <FullFormFieldWrapper spacer spacing="0px">
            <SM bold navy scaled tag="span">
              Interaction Name
            </SM>
            <FormInput
              type="text"
              id="title"
              name="title"
              required
              disabled={disabled}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.title}
              showMessage
              error={errors.name && touched.name && errors.name}
              placeholder="Enter Interaction Name"
            />
          </FullFormFieldWrapper>
        </Col>

        <Col size="6">
          <FullFormFieldWrapper spacer spacing="0px">
            <SM bold navy scaled tag="span">
              Type
            </SM>
            <AutoCompleteFormItem
              style={{ marginTop: 10 }}
              defaultValue="Select Type"
              optionValues={map(interactionTypeOptions, (item) => ({
                value: item.id,
                label: item.name,
              }))}
              selectedItem={values.type}
              setSelectedValue={(value) => {
                setFieldValue('type', value);
              }}
              active={values.type !== ''}
            />
          </FullFormFieldWrapper>
        </Col>
        <Col size="6" sm="6">
          <FullFormFieldWrapper spacer spacing="0px">
            <SM bold navy scaled tag="span">
              Date
            </SM>
            <div style={{ marginTop: '10px' }}>
              <DatePicker
                validation={touched.deadline && errors.deadline ? 'error' : ''}
                date={values.date || ''}
                medium
                onChange={(value) => {
                  setFieldValue('date', value);
                }}
              />
            </div>
          </FullFormFieldWrapper>
        </Col>
      </Row>

      <Row>
        <Col size="12">
          <FullFormFieldWrapper spacer spacing="0px">
            <SM bold navy scaled>
              Interaction Description
            </SM>
            <InputContainer
              disabled={disabled}
              {...{ editorState, setEditorState }}
            />
          </FullFormFieldWrapper>
        </Col>
      </Row>

      <Row>
        <Col alignCenter justifyBetween md={6}>
          {error ? (
            <div>
              <SM error validation="error">
                {error}
              </SM>
            </div>
          ) : null}
          <Button
            loading={isSubmitting || undefined}
            type="submit"
            disabled={disabled}
            buttonStatus
            error={status && status.error}
            primary
            style={{ marginTop: '20px', marginBottom: 0 }}
          >
            Save Interaction
          </Button>
        </Col>
        {interactionId ? (
          <Col alignCenter justifyEnd>
            <Button
              color={colorRed400}
              link
              loading={interactionId.loading}
              buttonStatus
              error={interactionId.error}
              onClick={onDelete}
            >
              Delete Interaction
            </Button>
          </Col>
        ) : null}
      </Row>
    </Form>
  );
}

FormRendered.defaultProps = {
  deletingStatus: undefined,
  onDelete: undefined,
  disabled: undefined,
  editorState: undefined,
  setEditorState: undefined,
  values: undefined,
  errors: undefined,
  touched: undefined,
  handleChange: undefined,
  handleBlur: undefined,
  handleSubmit: undefined,
  isSubmitting: undefined,
  status: undefined,
  setFieldValue: undefined,
  canContinue: undefined,
  interactionTypeOptions: undefined,
  interactionId: undefined,
};

FormRendered.propTypes = {
  ...formProps.formRendered.propTypes,
  ...formProps.interactionForm.propTypes,
};

export default InteractionForm;

export class InputContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rendered: true,
    };
    this.editor = React.createRef();
    this.onEditorStateChange = this.onEditorStateChange.bind(this);
  }

  componentDidMount() {
    this.linkifyPlugin = createLinkifyPlugin();
    this.staticToolbarPlugin = createToolbarPlugin();
    this.linkPlugin = createLinkPlugin();

    this.plugins = [
      this.linkifyPlugin,
      this.staticToolbarPlugin,
      this.linkPlugin,
    ];
    // force re render
    this.setState({ rendered: true });
  }

  onEditorStateChange = (editorState) => {
    const { disabled, setEditorState } = this.props;
    if (disabled) {
      return;
    }
    setEditorState(editorState);
  };

  render() {
    const { rendered } = this.state;
    const { editorState, placeholder, disableToolbar } = this.props;
    const { linkPlugin } = this;
    const Toolbar =
      this.staticToolbarPlugin && this.staticToolbarPlugin.Toolbar;
    return (
      <Wrapper
        className="editor-input-container-wrapper"
        onClick={(e) => e.stopPropagation()}
      >
        <WrapperInner>
          {rendered && this.plugins ? (
            <div>
              <Editor
                placeholder={placeholder || 'Enter Engagement Description'}
                editorState={editorState}
                onChange={this.onEditorStateChange}
                plugins={this.plugins}
                ref={this.editor}
              />
              {!disableToolbar ? (
                <Toolbar>
                  {(externalProps) => (
                    <div>
                      <BoldButton
                        // LINT OVERRIDE #3
                        // Component wraps another component
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...externalProps}
                      />
                      <ItalicButton
                        // LINT OVERRIDE #3
                        // Component wraps another component
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...externalProps}
                      />
                      <UnderlineButton
                        // LINT OVERRIDE #3
                        // Component wraps another component
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...externalProps}
                      />
                      <UnorderedListButton
                        // LINT OVERRIDE #3
                        // Component wraps another component
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...externalProps}
                      />
                      <OrderedListButton
                        // LINT OVERRIDE #3
                        // Component wraps another component
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...externalProps}
                      />
                      <linkPlugin.LinkButton
                        // LINT OVERRIDE #3
                        // Component wraps another component
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...externalProps}
                      />
                    </div>
                  )}
                </Toolbar>
              ) : null}
            </div>
          ) : null}
        </WrapperInner>
      </Wrapper>
    );
  }
}

InputContainer.defaultProps = {
  disabled: undefined,
  editorState: undefined,
  setEditorState: undefined,
  placeholder: undefined,
  disableToolbar: undefined,
};

InputContainer.propTypes = formProps.inputContainer;
