import { isEmpty } from 'lodash';

import fetchJson from 'utility/fetchJson';
import { BASE } from '../../constants';
import { transformFromFormtoBE } from './transformer';

export async function getMarketComparables({
  filters,
  pagination,
  sorting,
} = {}) {
  const body = {
    ...filters,
    ...pagination,
    ...sorting,
  };
  const response = await fetchJson(`${BASE}/api/market-comp/list/`, {
    method: 'POST',
    body: JSON.stringify(isEmpty(body) ? { page: 1 } : body),
  });
  return response;
}

export async function getMarketComparableItem(id) {
  const response = await fetchJson(`${BASE}/api/market-comp/${id}/`, {
    method: 'GET',
  });
  return response;
}

export async function createMarketComparable(data) {
  const body = await transformFromFormtoBE(data);
  const response = await fetchJson(`${BASE}/api/market-comp/`, {
    method: 'POST',
    body: JSON.stringify(body),
  });
  return response;
}

export async function updateMarketComparable(id, data) {
  const body = await transformFromFormtoBE(data);
  const response = await fetchJson(`${BASE}/api/market-comp/${id}/`, {
    method: 'PATCH',
    body: JSON.stringify(body),
  });
  return response;
}

export async function getPurchaseHistory({
  filters,
  pagination,
  sorting,
} = {}) {
  const body = {
    ...filters,
    ...pagination,
    ...sorting,
  };
  const response = await fetchJson(
    `${BASE}/api/market-comp/list/purchase/order/`,
    {
      method: 'POST',
      body: JSON.stringify(isEmpty(body) ? { page: 1 } : body),
    }
  );
  return response;
}

export async function getPurchaseItemHistory({
  filters,
  pagination,
  sorting,
} = {}) {
  const body = {
    ...filters,
    ...pagination,
    ...sorting,
  };
  const response = await fetchJson(
    `${BASE}/api/market-comp/list/purchase/item/`,
    {
      method: 'POST',
      body: JSON.stringify(isEmpty(body) ? { page: 1 } : body),
    }
  );
  return response;
}

export async function getPurchaseGroupHistory({
  filters,
  pagination,
  sorting,
} = {}) {
  const body = {
    ...filters,
    ...pagination,
    ...sorting,
  };
  const response = await fetchJson(
    `${BASE}/api/market-comp/list/purchase/group/`,
    {
      method: 'POST',
      body: JSON.stringify(isEmpty(body) ? { page: 1 } : body),
    }
  );
  return response;
}

export async function getPurchaseGroup(id) {
  const response = await fetchJson(
    `${BASE}/api/market-comp/list/purchase/group/${id}/`,
    {
      method: 'POST',
      body: JSON.stringify({}),
    }
  );
  return response;
}

export async function getRequestQuoteHistory({
  filters,
  pagination,
  sorting,
} = {}) {
  const body = {
    ...filters,
    ...pagination,
    ...sorting,
  };
  const response = await fetchJson(
    `${BASE}/api/market-comp/list/request/quote/`,
    {
      method: 'POST',
      body: JSON.stringify(isEmpty(body) ? { page: 1 } : body),
    }
  );
  return response;
}

export async function getRequestOrderHistory({
  filters,
  pagination,
  sorting,
} = {}) {
  const body = {
    ...filters,
    ...pagination,
    ...sorting,
  };
  const response = await fetchJson(
    `${BASE}/api/market-comp/list/request/order/`,
    {
      method: 'POST',
      body: JSON.stringify(isEmpty(body) ? { page: 1 } : body),
    }
  );
  return response;
}

export async function getRequestItemHistory({
  filters,
  pagination,
  sorting,
} = {}) {
  const body = {
    ...filters,
    ...pagination,
    ...sorting,
  };
  const response = await fetchJson(
    `${BASE}/api/market-comp/list/request/item/`,
    {
      method: 'POST',
      body: JSON.stringify(isEmpty(body) ? { page: 1 } : body),
    }
  );
  return response;
}

export async function getRequestGroupHistory({
  filters,
  pagination,
  sorting,
} = {}) {
  const body = {
    ...filters,
    ...pagination,
    ...sorting,
  };
  const response = await fetchJson(
    `${BASE}/api/market-comp/list/request/group/`,
    {
      method: 'POST',
      body: JSON.stringify(isEmpty(body) ? { page: 1 } : body),
    }
  );
  return response;
}

export async function getRequestGroup(id) {
  const response = await fetchJson(
    `${BASE}/api/market-comp/list/request/group/${id}/`,
    {
      method: 'POST',
      body: JSON.stringify({}),
    }
  );
  return response;
}

export async function getOrderHistory({ filters, pagination, sorting } = {}) {
  const body = {
    ...filters,
    ...pagination,
    ...sorting,
  };
  const response = await fetchJson(
    `${BASE}/api/market-comp/list/sales/order/`,
    {
      method: 'POST',
      body: JSON.stringify(isEmpty(body) ? { page: 1 } : body),
    }
  );
  return response;
}

export async function getOrderItemHistory({
  filters,
  pagination,
  sorting,
} = {}) {
  const body = {
    ...filters,
    ...pagination,
    ...sorting,
  };
  const response = await fetchJson(`${BASE}/api/market-comp/list/sales/item/`, {
    method: 'POST',
    body: JSON.stringify(isEmpty(body) ? { page: 1 } : body),
  });
  return response;
}

export async function getOrderGroupHistory({
  filters,
  pagination,
  sorting,
} = {}) {
  const body = {
    ...filters,
    ...pagination,
    ...sorting,
  };
  const response = await fetchJson(
    `${BASE}/api/market-comp/list/sales/group/`,
    {
      method: 'POST',
      body: JSON.stringify(isEmpty(body) ? { page: 1 } : body),
    }
  );
  return response;
}

export async function getOrderGroup(id) {
  const response = await fetchJson(
    `${BASE}/api/market-comp/list/sales/group/${id}/`,
    {
      method: 'POST',
      body: JSON.stringify({}),
    }
  );
  return response;
}
