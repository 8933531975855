import tree from 'state';
import { keys, reduce, get, omitBy, isNil, unset, isArray } from 'lodash';

import { setValues, getValues } from 'utility/urlUtils';
import asyncTreeRequester from 'utility/asyncTreeRequester';
import {
  generateItemFilters,
  generateFilterValues,
} from 'state/requestForQuote/transformer';
import { generateCriteriaQueryFilters } from 'state/requestForQuote/transformer_criteria';
import * as effects from './effects';

const opportunitiesCursor = tree.select(['opportunities']);

export async function getMyOpportunities({
  pagination,
  filters,
  sorting,
  criteria,
} = {}) {
  return asyncTreeRequester({
    func: effects.getOpportunities.bind(this, { criteria }),
    pagination,
    filters,
    sorting,
    cursor: opportunitiesCursor,
    path: ['currentOpportunities'],
  });
}

export async function getMyMfgOpportunities({
  pagination,
  filters,
  sorting,
  criteria,
} = {}) {
  return asyncTreeRequester({
    func: effects.getMfgOpportunities.bind(this, { criteria }),
    pagination,
    filters,
    sorting,
    cursor: opportunitiesCursor,
    path: ['currentOpportunities'],
  });
}

export async function getMyItemOpportunities({
  pagination,
  filters,
  sorting,
} = {}) {
  return asyncTreeRequester({
    func: effects.getItemOpportunities.bind(this),
    pagination,
    filters,
    sorting,
    cursor: opportunitiesCursor,
    path: ['currentItemOpportunities'],
  });
}

export async function getMyMfgItemOpportunities({
  pagination,
  filters,
  sorting,
} = {}) {
  return asyncTreeRequester({
    func: effects.getMfgItemOpportunities.bind(this),
    pagination,
    filters,
    sorting,
    cursor: opportunitiesCursor,
    path: ['currentItemOpportunities'],
  });
}

export async function setActiveRfqItem(seed = {}) {
  opportunitiesCursor.set(
    ['opportunityItemFilter', 'rfqItemFilters'],
    generateItemFilters(null, seed, {
      range: { multiple: true },
    })
  );
}

export function prepareCriteria({ ignoreOmit } = {}) {
  let filterData = generateFilterValues(
    opportunitiesCursor.get(['opportunityItemFilter', 'rfqItemFilters'])
  );
  filterData = generateCriteriaQueryFilters(filterData);
  if (!ignoreOmit) {
    filterData = omitBy(filterData, (d) => isNil(d) || get(d, 'length') === 0);
  }
  return filterData;
}

export async function generateOpportunitySearch(branch, type, data, isMfg) {
  opportunitiesCursor.set(
    ['opportunityItemFilter', 'rfqItemFilters', type],
    data
  );
  const criteria = prepareCriteria();
  const querystring = getValues();
  unset(querystring, type);
  if (isMfg) {
    getMyMfgOpportunities({ criteria, filters: querystring });
  } else {
    getMyOpportunities({ criteria, filters: querystring });
  }

  if (!data.value || isArray(data.value)) {
    // remove item from url
    setValues({ [type]: undefined, ...querystring }, keys(criteria));
  } else {
    setTimeout(() => {
      setValues({ ...criteria, ...querystring }, keys(criteria));
    }, 1);
  }
}

export function updateItemFilters(type, data) {
  const { value, operator, selectedFilters } = data;
  const itemFilters = opportunitiesCursor.get([
    'opportunityItemFilter',
    'rfqItemFilters',
  ]);
  if (selectedFilters) {
    itemFilters[type] = {
      ...itemFilters[type],
      selectedFilters,
    };
  }
  if (value || value === null) {
    itemFilters[type] = {
      ...itemFilters[type],
      value,
    };
  }
  if (operator) {
    itemFilters[type] = {
      ...itemFilters[type],
      operator,
    };
  }
  opportunitiesCursor.set(
    ['opportunityItemFilter', 'rfqItemFilters'],
    itemFilters
  );
}

export function clearCriteriaSearch(seed) {
  const keyList = keys(prepareCriteria({ ignoreOmit: true }));
  const filters = reduce(
    keyList,
    (accum, item) => {
      const _accum = accum;
      _accum[item] = undefined;
      return _accum;
    },
    {}
  );
  const querystring = getValues();
  setValues({ ...filters, ...querystring }, keyList);
  setActiveRfqItem(seed);
}
