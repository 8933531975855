import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import Promise from 'bluebird';
import numeral from 'numeral';
import { useBranch } from 'baobab-react/hooks';
import {
  Button as ZendeskButton,
  ButtonGroup,
  IconButton,
} from '@zendeskgarden/react-buttons';
import {
  find,
  filter,
  sortBy,
  set,
  cloneDeep,
  startCase,
  keys,
  map,
  debounce,
  unset,
  take,
  pickBy,
  includes,
  replace,
  slice,
  get,
  join,
  isEmpty,
  findIndex,
  isArray,
  isEqual,
  forEach,
  omit,
} from 'lodash';
import Accordion from 'theme/Accordion';
import Icon from 'components/Icon/Icon';

import Flex from 'styled-flex-component';
import { MD, SM } from '@zendeskgarden/react-typography';
import { closeSidebar } from 'state/sidebars/actions';
import { variables } from 'theme/variables';
import { CheckboxList } from 'components/Checkbox/Checkbox';
import { setValues, getValues } from 'utility/urlUtils';
import { setFilters } from 'state/application/actions';
import {
  REQUEST_GLOBALS,
  getValuesForRequest,
  reverseCategorizeFacets,
  generateFiltersObject,
} from 'utility/reverseUrlFilters';
import categorizeFacets from 'utility/categorizeFacets';
import {
  Dropdown,
  Select,
  Item,
  Menu,
  Field,
} from '@zendeskgarden/react-dropdowns';
import { globalInterestsFilterProps } from 'propTypesObjects';
import FilterItemGroupTwoFilters from 'components/FilterItemGroup/FilterItemGroupTwoFilters';
import GlobalAttributeFilter from './GlobalAttributeFilter';
import {
  SidebarFilterHeader,
  SidebarFilterBody,
  ResetHeader,
} from '../../GlobalSideBar.styles';

const {
  spacing,
  color_red_400: colorRed400,
  custom_grey: customGrey,
} = variables;

function AccordianFilters({
  style,
  total,
  resetFilterValues,
  selectedValues,
  facetGroups,
  onChangeCheckboxList,
  onChangeFilters,
  currentRangeFilterData,
}) {
  const [filterView, setFilterView] = useState('filters');
  const attributeValueFacet = find(facetGroups, { key: 'attribute_values' });
  const newFacetGroups =
    facetGroups || filter(facetGroups, ({ key }) => key !== 'attribute_values');

  const renderCustomTitle = useCallback(
    (customTitle) => (
      <div
        style={{ fontSize: '.8rem', color: customGrey }}
      >{`${customTitle}`}</div>
    ),
    []
  );

  return (
    <div style={style}>
      <SidebarFilterHeader
        padding={`${spacing} ${spacing} ${spacing} ${spacing}`}
      >
        <Flex style={{ width: '100%' }} justifyBetween alignCenter>
          <Flex alignCenter>
            <Icon icon="icon-settings" fontSize="20px" color="#fff" />
            <MD paddingHorizontal bold color="#fff">
              Filters
            </MD>
          </Flex>
          <IconButton
            onClick={closeSidebar}
            disableBackground
            alignCenter
            justifyEnd
            size="large"
            aria-label="closeSidebar"
          >
            <Icon button icon="icon-close" fontSize="13px" color="#fff" />
          </IconButton>
        </Flex>
      </SidebarFilterHeader>
      <ResetHeader>
        <SM bold>{numeral(total || 0).format('0,0')} Results</SM>
        <SM>
          <ZendeskButton blue link onClick={() => resetFilterValues()}>
            Reset Filters
          </ZendeskButton>
        </SM>
      </ResetHeader>
      <div>
        <ButtonGroup selectedItem={filterView} onSelect={setFilterView}>
          <ZendeskButton
            onClick={() => setFilterView('filters')}
            groupButton
            style={{ borderLeft: 'none', width: '50%', borderRadius: '0' }}
            key="filters"
            value="filters"
          >
            Filters
          </ZendeskButton>
          <ZendeskButton
            onClick={() => setFilterView('attributes')}
            groupButton
            style={{ borderRight: 'none', width: '50%', borderRadius: '0' }}
            key="attributes"
            value="attributes"
          >
            Attributes
          </ZendeskButton>
        </ButtonGroup>
      </div>
      {filterView === 'attributes' ? (
        <SidebarFilterBody>
          <GlobalAttributeFilter
            attributeValueFacet={attributeValueFacet}
            selectedValues={selectedValues}
            onChangeCheckboxList={onChangeCheckboxList}
          />
        </SidebarFilterBody>
      ) : (
        <SidebarFilterBody>
          {map(newFacetGroups, (facet, i) => {
            const selectedFacetValues = {
              ...selectedValues[facet.key],
              ...selectedValues[`${facet.key}_missing`],
            };
            // This Ternary below removes any filter on Attribute, since Attributes should be
            // filtered within its own `Attribute` tab, not filter tab
            if (!includes(facet.name, 'Attribute')) {
              return (
                <div key={`${i}-${facet.key}`}>
                  {
                    // for all accordion filters that are checkboxes
                    facet.type === 'checkbox' && (
                      <Accordion
                        disabled={!facet.buckets.length}
                        key={`${i}-${facet.key}`}
                        style={{ paddingTop: 0 }}
                        title={startCase(facet.name)}
                      >
                        <CheckboxList
                          selected={selectedFacetValues}
                          onChange={onChangeCheckboxList}
                          items={facet.buckets}
                        />
                      </Accordion>
                    )
                  }
                  {
                    // specific to range filters, built for quantity range on supply tables for now
                    facet.type === 'range' && (
                      <Accordion
                        disabled={!facet.buckets.length}
                        key={`${i}-${facet.key}`}
                        style={{ paddingTop: 0 }}
                        title={startCase(facet.title)}
                      >
                        <FilterItemGroupTwoFilters
                          config={currentRangeFilterData.config}
                          type={facet.type}
                          itemSelection
                          onChangeConfig={onChangeFilters}
                          currentConfigs={currentRangeFilterData.currentConfig}
                          minWidth={80}
                          customTitle={renderCustomTitle(
                            currentRangeFilterData.config.customTitle
                          )}
                        />
                      </Accordion>
                    )
                  }
                </div>
              );
            }
            return null;
          })}
        </SidebarFilterBody>
      )}
    </div>
  );
}

AccordianFilters.defaultValues = {
  style: undefined,
  total: undefined,
  resetFilterValues: undefined,
  selectedValues: undefined,
  facetGroups: undefined,
  onChangeCheckboxList: undefined,
};

AccordianFilters.propTypes = globalInterestsFilterProps.accordian;

function RenderSelectDropdown({
  selectedValues,
  facetGroups,
  onChangeCheckboxList,
  omittedFilters = [],
}) {
  const omittedFiltersLength = omittedFilters?.length;
  const groups = omittedFiltersLength
    ? filter(facetGroups, (g) => !includes(omittedFilters, g.key))
    : facetGroups;
  return (
    <div
      className="filter-select-dropdown-wrapper"
      style={{ minWidth: '300px' }}
    >
      {map(groups, (facet, i) => {
        const selectedFacetValues = {
          ...selectedValues[facet.key],
          ...selectedValues[`${facet.key}_missing`],
        };
        const mappedOptions = map(facet.buckets, (o) => ({ option: o }));
        const options = sortBy(mappedOptions, 'option');
        return (
          <div key={i} className="filter-dropdown-wrapper">
            <DropdownEl
              name={facet.name}
              options={options}
              selectedFacetValues={selectedFacetValues}
              onChangeCheckboxList={onChangeCheckboxList}
            />
          </div>
        );
      })}
    </div>
  );
}

RenderSelectDropdown.defaultProps = {
  selectedValues: undefined,
  facetGroups: undefined,
  onChangeCheckboxList: undefined,
  omittedFilters: undefined,
};

RenderSelectDropdown.propTypes = globalInterestsFilterProps.selectDropdown;

function DropdownEl({
  name,
  options,
  selectedFacetValues,
  onChangeCheckboxList,
}) {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const mappedOptions = map(options, 'option');
  const checked = filter(
    mappedOptions,
    (option) =>
      selectedFacetValues &&
      (selectedFacetValues[`__${option.value}`] ||
        selectedFacetValues[`${option.value}`])
  );

  return (
    <div>
      <Dropdown
        downshiftProps={{ itemToString: (item) => item && item.label }}
        selectedItems={checked}
        isOpen={menuIsOpen}
        onStateChange={(changes) => {
          if (changes.type === '__autocomplete_click_item__') {
            return;
          }
          if (
            changes.isOpen !== menuIsOpen &&
            Object.prototype.hasOwnProperty.call(changes, 'isOpen')
          ) {
            setMenuIsOpen(changes.isOpen);
          }
        }}
      >
        <Field>
          <Select
            filterDropdown
            values={checked.length}
            style={{ minWidth: '100%', maxWidth: '240px' }}
            background="#fff"
            active={false}
            fontSize="13px"
          >
            <Flex>
              <SM
                slate
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  marginRight: '7px',
                }}
              >
                {name}:
              </SM>
              {checked && checked.length ? (
                map(take(checked, 1), (option) => (
                  <Flex style={{ minWidth: 0 }} key={option.value}>
                    <SM ellipsis style={{ maxWidth: '100px' }} bold>
                      {option.label &&
                        option.label.replace &&
                        replace(option.label, 'AA_M', 'M')}
                    </SM>
                    {checked.length > 1 ? (
                      <SM
                        style={{ paddingLeft: '5px' }}
                      >{` +${checked.length}`}</SM>
                    ) : (
                      ''
                    )}
                  </Flex>
                ))
              ) : (
                <SM key="all" bold as="span">
                  {' '}
                  All
                </SM>
              )}
            </Flex>
          </Select>
        </Field>
        {menuIsOpen ? (
          <Menu maxHeight="200px" style={{ minWidth: '320px' }}>
            {options?.length === 0 ? (
              <SM slate paddingHorizontal paddingVertical>
                No Items To Filter
              </SM>
            ) : (
              map(options, ({ option, i }) => {
                const checkedValue =
                  selectedFacetValues &&
                  (selectedFacetValues[`__${option.value}`] ||
                    selectedFacetValues[option.value]);
                return (
                  <Item
                    onClick={(e) => {
                      e.stopPropagation();
                      onChangeCheckboxList(option, !checkedValue);
                    }}
                    key={`${option.value}+${i}`}
                    value={option}
                  >
                    {option.label === 'AA_Missing' ? (
                      <SM uppercase xs color={colorRed400}>
                        {startCase(
                          replace(
                            replace(`${option.label}`, /_/g, ' '),
                            'AA Missing',
                            `Missing ${name}`
                          )
                        )}{' '}
                        ({option.count})
                      </SM>
                    ) : (
                      <SM uppercase xs slate>
                        {startCase(replace(`${option.label}`, /_/g, ' '))} (
                        {option.count})
                      </SM>
                    )}
                  </Item>
                );
              })
            )}
          </Menu>
        ) : null}
      </Dropdown>
    </div>
  );
}

DropdownEl.defaultProps = {
  name: undefined,
  options: undefined,
  selectedFacetValues: undefined,
  onChangeCheckboxList: undefined,
};

DropdownEl.propTypes = globalInterestsFilterProps.dropdown;

function GlobalInterestsFilter(props) {
  const {
    options,
    dropdowns,
    styles,
    keyword,
    querystringOnlyKeys: querystringOnlyKeysProp,
  } = props;
  const { defaultFilters, querystringOnlyKeys: querystringOnlyKeysOption } =
    options;
  const querystringOnlyKeys =
    querystringOnlyKeysProp || querystringOnlyKeysOption;
  const DEBOUNCE_TIME = 100;
  const setUrlValueDebounceRef = useRef(debounce(setValues, DEBOUNCE_TIME));
  const debounceOnFilterRef = useRef(
    debounce((values, sort, currentValue, filterOptions) => {
      options.onFilter(values, sort, currentValue, filterOptions);
    }, DEBOUNCE_TIME)
  );
  const debounceOnChange = useCallback(
    (values, sort, currentValue, filterOptions) => {
      debounceOnFilterRef.current(values, sort, currentValue, filterOptions);
    },
    []
  );

  let omittedFilters = options?.facets?.omit || [];
  omittedFilters = [...omittedFilters, 'attribute_values'];

  const resultPath = options?.facets?.paths?.result;
  const filterPath = options?.facets?.paths?.filters;
  const { filters = {}, baseBranch } = useBranch({
    filters: filterPath,
    baseBranch: [resultPath[0]],
  });

  const remainingPath = slice(resultPath, 1);
  const facetsPath = join([...remainingPath, 'facets'], '.');
  const totalPath = join([...remainingPath, 'total'], '.');
  const sortPath = join([...remainingPath, 'sort'], '.');
  const facets = get(baseBranch, facetsPath);
  const total = get(baseBranch, totalPath);
  const sort = get(baseBranch, sortPath);

  const selectedValues = useMemo(
    () => (filters ? reverseCategorizeFacets(filters, options.type) : {}),
    [filters, options.type]
  ); // todo memo
  const facetGroups = categorizeFacets(facets, options.type);
  const [checkedQueryString, setCheckedQueryString] = useState(false);
  const queryString = getValues();

  // Check the querystring and set filters based on its values (when page is refreshed)
  useEffect(() => {
    if (!checkedQueryString) {
      setFilters({
        path: filterPath,
        selected: omit(queryString, querystringOnlyKeys || []),
      });
      setCheckedQueryString(true);
    }
  }, [checkedQueryString, filterPath, queryString, querystringOnlyKeys]);

  // Set the filters based on any querystring values that are applicable to this table
  useEffect(() => {
    if (checkedQueryString) {
      const defaultValues = getValuesForRequest(options.type);
      const filtered = pickBy(
        defaultValues,
        (v, k) => !includes(REQUEST_GLOBALS, k)
      );
      setFilters({
        path: filterPath,
        selected: omit(filtered, querystringOnlyKeys || []),
      });
    }
  }, [checkedQueryString, filterPath, options.type, querystringOnlyKeys]);

  const resetFilterValues = useCallback(() => {
    setUrlValueDebounceRef.current(defaultFilters || {}, keys(getValues()));
    debounceOnChange(defaultFilters || {}, null, null, { reset: true });
    setFilters({ path: filterPath, selected: defaultFilters || {} });
  }, [debounceOnChange, defaultFilters, filterPath]);

  const onChangeCheckboxList = useCallback(
    (item, checked) => {
      const clonedValues = cloneDeep(selectedValues);
      const querystring = getValues();
      // Persist preexisting querystring values
      const prunedQuerystring = cloneDeep(querystring);
      forEach(prunedQuerystring, (_value, key) => {
        if (clonedValues[key]) {
          unset(prunedQuerystring, key);
        }
      });
      const itemKey = item.key;
      let itemType = item.type;
      if (item.value === 'missing') {
        itemType = `${itemType}_missing`;
      }

      if (item.isBoolean && checked) {
        set(clonedValues, `${itemType}.${itemKey}`, checked);
        unset(clonedValues, `${itemType}.${!itemKey}`);
      } else if (checked) {
        set(clonedValues, `${itemType}.${'__'}${itemKey}`, checked);
      } else if (item.value === 'missing') {
        unset(clonedValues, `${itemType}`);
      } else {
        unset(clonedValues, `${itemType}.${'__'}${itemKey}`);
      }

      const urlReadyObject = {
        ...prunedQuerystring,
        ...generateFiltersObject(clonedValues),
        keyword,
      };
      setFilters({
        path: filterPath,
        selected: omit(urlReadyObject, querystringOnlyKeys || []),
      });
      setTimeout(async () => {
        if (!checked && !options.localFilters) {
          setUrlValueDebounceRef.current(urlReadyObject, [
            ...keys(facets),
            ...map(keys(facets), (f) => `${f}_missing`),
          ]); // look for missing in URL too
          await Promise.delay(20); // race condition
        }
        if (options.onFilter) {
          debounceOnChange(urlReadyObject, sort, { item, checked });
        }

        if (!options.localFilters && checked) {
          // Some views we do not want to set on URL bar.
          await Promise.delay(100); // race condition
          setUrlValueDebounceRef.current(urlReadyObject, [
            ...keys(facets),
            ...map(keys(facets), (f) => `${f}_missing`),
          ]); // look for missing in URL too
        }
      }, 150);
    },

    [
      debounceOnChange,
      facets,
      filterPath,
      keyword,
      options.localFilters,
      options.onFilter,
      querystringOnlyKeys,
      selectedValues,
      sort,
    ]
  );

  // Specific use for range filters
  const currentRangeFilterIdx = findIndex(facetGroups, {
    range: true,
    key: 'quantity_uom',
  });
  const [currentRangeFilter, updateRangeFilter] = useState({ config: {} });
  const DEBOUNCE_RANGE_TIME = 500;
  const debounceOnRangeFilterRef = useRef(
    debounce((values, srt, currentValue, filterOptions) => {
      options.onFilter(values, srt, currentValue, filterOptions);
    }, DEBOUNCE_RANGE_TIME)
  );
  const debounceOnRangeChange = useCallback(
    (values, srt, currentValue, filterOptions) => {
      debounceOnRangeFilterRef.current(
        values,
        srt,
        currentValue,
        filterOptions
      );
    },
    []
  );

  useEffect(() => {
    if (
      !isEmpty(facetGroups) &&
      facetGroups[currentRangeFilterIdx] &&
      isEmpty(currentRangeFilter.config)
    ) {
      const rangeFilter = facetGroups[currentRangeFilterIdx];
      const quantityMin =
        filters.quantity_min &&
        (isArray(filters.quantity_min)
          ? filters.quantity_min[0]
          : filters.quantity_min);
      const quantityMax =
        filters.quantity_max &&
        (isArray(filters.quantity_max)
          ? filters.quantity_max[0]
          : filters.quantity_max);
      const uomKey =
        filters.quantity_uom &&
        (isArray(filters.quantity_uom)
          ? Number(filters.quantity_uom[0])
          : Number(filters.quantity_uom));
      if (quantityMin)
        rangeFilter.config.value.min = {
          value: quantityMin,
          label: quantityMin,
        };
      if (quantityMax)
        rangeFilter.config.value.max = {
          value: quantityMax,
          label: quantityMax,
        };
      if (uomKey)
        rangeFilter.config.uom = find(rangeFilter.config.uomOptions, [
          'key',
          uomKey,
        ]);
      updateRangeFilter(rangeFilter);
    }
  }, [currentRangeFilter, currentRangeFilterIdx, facetGroups, filters]);

  const onChangeRange = useCallback(
    async (type, value) => {
      const uomVal = value?.uom?.value || {};
      const minVal = value?.value?.min?.value;
      const maxVal = value?.value?.max?.value;
      updateRangeFilter((rangeFilterPrevious) => ({
        ...rangeFilterPrevious,
        config: value,
      }));
      set(selectedValues, 'quantity_uom', uomVal);
      set(selectedValues, 'quantity_min', minVal);
      set(selectedValues, 'quantity_max', maxVal);
      if (uomVal && (minVal || maxVal)) {
        const urlReadyObject = { ...generateFiltersObject(selectedValues) };
        await setFilters({
          path: filterPath,
          selected: omit(urlReadyObject, querystringOnlyKeys || []),
        });
        setTimeout(async () => {
          if (!value && !options.localFilters) {
            setUrlValueDebounceRef.current(urlReadyObject, [
              ...keys(facets),
              ...map(keys(facets), (f) => `${f}_missing`),
            ]); // look for missing in URL too
            await Promise.delay(20); // race condition
          }

          if (options.onFilter) {
            await debounceOnRangeChange(urlReadyObject, sort, value);
          }

          if (!options.localFilters && value) {
            // Some views we do not want to set on URL bar.
            await Promise.delay(100); // race condition
            setUrlValueDebounceRef.current(urlReadyObject, [
              ...keys(facets),
              ...map(keys(facets), (f) => `${f}_missing`),
            ]); // look for missing in URL too
          }
        }, 150);
      } else if (uomVal) {
        set(selectedValues, 'quantity_uom', '');
        set(selectedValues, 'quantity_min', '');
        set(selectedValues, 'quantity_max', '');
        const urlReadyObject = { ...generateFiltersObject(selectedValues) };
        await setFilters({
          path: filterPath,
          selected: omit(urlReadyObject, querystringOnlyKeys || []),
        });
        setTimeout(async () => {
          if (!value && !options.localFilters) {
            setUrlValueDebounceRef.current(urlReadyObject, [
              ...keys(facets),
              ...map(keys(facets), (f) => `${f}_missing`),
            ]); // look for missing in URL too
            await Promise.delay(20); // race condition
          }

          if (options.onFilter) {
            await debounceOnRangeChange(urlReadyObject, sort, value);
          }

          if (!options.localFilters && value) {
            // Some views we do not want to set on URL bar.
            await Promise.delay(100); // race condition
            setUrlValueDebounceRef.current(urlReadyObject, [
              ...keys(facets),
              ...map(keys(facets), (f) => `${f}_missing`),
            ]); // look for missing in URL too
          }
        }, 150);
      }
    },
    [
      debounceOnRangeChange,
      facets,
      filterPath,
      options.localFilters,
      options.onFilter,
      querystringOnlyKeys,
      selectedValues,
      sort,
    ]
  );

  const Component = dropdowns ? RenderSelectDropdown : AccordianFilters;

  return (
    <Component
      omittedFilters={omittedFilters}
      style={styles}
      total={total}
      resetFilterValues={resetFilterValues}
      selectedValues={selectedValues}
      facetGroups={facetGroups}
      onChangeCheckboxList={onChangeCheckboxList}
      // Specific params for Range filters
      onChangeFilters={(type, value) =>
        onChangeRange(type, value, currentRangeFilter)
      }
      currentRangeFilterData={currentRangeFilter}
    />
  );
}

GlobalInterestsFilter.defaultValues = {
  options: undefined,
  matching: undefined,
  setFilteredMatches: undefined,
  dropdowns: undefined,
};

GlobalInterestsFilter.propTypes = globalInterestsFilterProps.globalFilter;

export default React.memo(GlobalInterestsFilter, (prevProps, nextProps) => {
  const prevFilterPath = prevProps.options?.facets?.paths?.filters;
  const nextFilterPath = nextProps.options?.facets?.paths?.filters;
  return isEqual(prevFilterPath, nextFilterPath);
});
