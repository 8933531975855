import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/Icon/Icon';
import Flex from 'styled-flex-component';
import { MD, SM, LG } from '@zendeskgarden/react-typography';
import { Formik } from 'formik';
import { Form } from 'theme/Form';
import { Row, Col } from '@zendeskgarden/react-grid';
import { FullFormFieldWrapper } from 'theme/AuthForms.styles';
import { useBranch } from 'baobab-react/hooks';
import { Button } from 'theme/Button';
import { get, map } from 'lodash';
import AutoCompleteFormItem from 'components/MultiTypeForm/components/MultiSelectFormItem/AutoCompleteFormItem.js';
import {
  getObjectPersonnel,
  createObjectPersonnel,
  deleteObjectPersonnel,
} from 'state/crm/actions.js';
import { NewDashboardCard } from 'components/DashboardCard/DashboardCard';
import CompanyLookup from 'components/CompanyLookup/CompanyLookupV2';
import { variables } from 'theme/variables';
import { personnelShape } from 'propTypesObjects';
import { getRole } from 'utility/hasAccount';

const { custom_silver: customSilver, color_red_400: colorRed400 } = variables;

export function PersonnelContainer({
  label,
  type,
  contentType,
  objectId,
  render,
}) {
  const [personnel, setPersonnel] = useState([]);
  const [personnelStatus, setPersonnelStatus] = useState({});
  const { routedModalState } = useBranch({
    routedModalState: ['application', 'routedModalState'],
  });

  const getObjectPersonnelLocally = useCallback(async () => {
    setPersonnelStatus({ loading: true });
    const objPersonnel = await getObjectPersonnel({
      content_type: contentType,
      object_id: objectId,
    });
    setPersonnel(objPersonnel?.results);
    setPersonnelStatus({ loading: false });
  }, [contentType, objectId]);

  useEffect(() => {
    getObjectPersonnelLocally();
  }, [getObjectPersonnelLocally]);

  useEffect(() => {
    if (routedModalState === 'exit') {
      getObjectPersonnelLocally();
    }
  }, [getObjectPersonnelLocally, routedModalState]);

  const onCreateObjectPersonnel = useCallback(
    async (data) => createObjectPersonnel(data),
    []
  );

  const onDeleteObjectPersonnel = useCallback(
    async (id) => {
      setPersonnelStatus({ [`message_${id}`]: { loading: true } });
      const result = await deleteObjectPersonnel(id);
      if (result.error) {
        setPersonnelStatus({ [`message_${id}`]: { error: result.error } });
      } else {
        getObjectPersonnelLocally();
        setPersonnelStatus({ [`message_${id}`]: { loading: false } });
      }
    },
    [getObjectPersonnelLocally]
  );

  return render ? (
    render({ personnel })
  ) : (
    <PersonnelForm
      type={type}
      label={label}
      personnel={personnel}
      contentType={contentType}
      objectId={objectId}
      onSuccess={getObjectPersonnelLocally}
      onSubmit={onCreateObjectPersonnel}
      onDelete={onDeleteObjectPersonnel}
      personnelStatus={personnelStatus}
    />
  );
}

PersonnelContainer.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  contentType: PropTypes.number,
  objectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  render: PropTypes.func,
};

PersonnelContainer.defaultProps = {
  label: undefined,
  type: undefined,
  contentType: undefined,
  objectId: undefined,
  render: undefined,
};

function PersonnelForm({
  label,
  type,
  personnel,
  onSubmit,
  onDelete,
  contentType,
  objectId,
  onSuccess,
  disabled,
  canContinue,
  personnelStatus,
}) {
  const { value: role } = getRole() || {};
  const { rfqMeta, clientId } = useBranch({
    rfqMeta: ['requestForQuote', 'meta', 'result'],
    clientId: [
      'authentication',
      'session',
      'roles',
      role,
      'data',
      'company',
      'id',
    ],
  });
  const priorityOptions = get(rfqMeta, 'priority');
  const roleOptions = get(rfqMeta, `object_personnel_attributes[${type}]`);

  const submitPersonnel = useCallback(
    async (values, { setSubmitting, setStatus, resetForm }) => {
      setSubmitting(true);
      const outData = {
        content_type: contentType,
        object_id: objectId,
        user: values.person.value,
        role: values.role.value,
      };
      const savePersonnelResult = await onSubmit(outData);
      const { error } = savePersonnelResult;
      if (error) {
        setStatus({ error });
      } else {
        resetForm();
        if (onSuccess) {
          onSuccess();
        }
      }
      setSubmitting(false);
    },
    [contentType, objectId, onSubmit, onSuccess]
  );

  return (
    <Formik
      initialValues={{
        person: '',
        role: '',
      }}
      onSubmit={submitPersonnel}
    >
      {(props) => (
        <FormRendered
          // LINT OVERRIDE #3
          // Component wraps another component
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          label={label}
          onDelete={onDelete}
          personnel={personnel}
          priorityOptions={priorityOptions}
          disabled={disabled}
          canContinue={canContinue}
          personnelStatus={personnelStatus}
          {...{ clientId, roleOptions }}
        />
      )}
    </Formik>
  );
}

PersonnelForm.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  personnel: PropTypes.arrayOf(personnelShape),
  onSubmit: PropTypes.func,
  onDelete: PropTypes.func,
  contentType: PropTypes.number,
  objectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onSuccess: PropTypes.func,
  disabled: PropTypes.bool,
  canContinue: PropTypes.bool,
  personnelStatus: PropTypes.shape({
    loading: PropTypes.bool,
  }),
};

PersonnelForm.defaultProps = {
  label: undefined,
  type: undefined,
  personnel: undefined,
  onSubmit: undefined,
  onDelete: undefined,
  contentType: undefined,
  objectId: undefined,
  onSuccess: undefined,
  disabled: undefined,
  canContinue: undefined,
  personnelStatus: undefined,
};

function FormRendered({
  onDelete,
  disabled,
  values,
  handleSubmit,
  isSubmitting,
  status,
  setFieldValue,
  personnel,
  clientId,
  roleOptions,
  personnelStatus,
  label,
}) {
  return (
    <Form size="large" onSubmit={handleSubmit}>
      <Row>
        <Col size="5">
          <FullFormFieldWrapper spacer spacing="0px">
            <SM bold navy scaled tag="span">
              {label || 'Personnel'}
            </SM>
            <CompanyLookup
              searchType="user"
              placeholder={`Search ${label || 'Personnel'}`}
              clientId={clientId}
              style={{ marginTop: 10 }}
              selectedItem={values.person}
              setSelectedValue={(value) => {
                setFieldValue('person', value);
              }}
            />
          </FullFormFieldWrapper>
        </Col>
        <Col size="5">
          <FullFormFieldWrapper spacer spacing="0px">
            <SM bold navy scaled tag="span">
              Role
            </SM>
            <AutoCompleteFormItem
              style={{ marginTop: 10 }}
              defaultValue="Select Role"
              optionValues={map(roleOptions, (item) => ({
                value: item.id,
                label: item.name,
              }))}
              selectedItem={values.role}
              setSelectedValue={(value) => setFieldValue('role', value)}
              active={values.role !== ''}
            />
          </FullFormFieldWrapper>
        </Col>
        <Col size="2">
          <Button
            loading={isSubmitting || undefined}
            type="submit"
            disabled={disabled}
            buttonStatus
            error={status && status.error}
            primary
            style={{ minWidth: 0, marginTop: 40 }}
          >
            Save
          </Button>
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          {status && status.error ? (
            <div>
              <SM error validation="error">
                {status.error}
              </SM>
            </div>
          ) : null}
        </Col>
      </Row>

      <div style={{ marginTop: 40 }}>
        {personnel && personnel.length ? (
          map(personnel, (person, i) => (
            <NewDashboardCard key={i} style={{ padding: 20, marginBottom: 20 }}>
              <Flex justifyBetween full alignCenter>
                <div>
                  <LG bold>{person.user_name}</LG>
                  <MD>{person.role_name}</MD>
                </div>
                <Icon
                  buttonStyle
                  color={customSilver}
                  hoverColor={colorRed400}
                  onClick={() => onDelete(person.id)}
                  icon="icon-delete"
                />
              </Flex>
              {personnelStatus[`message_${person.id}`]?.error ? (
                <SM paddingTopSm error>
                  {personnelStatus[`message_${person.id}`]?.error}
                </SM>
              ) : null}
            </NewDashboardCard>
          ))
        ) : (
          <NewDashboardCard style={{ padding: 20 }}>
            <MD center>No Personnel Yet.</MD>
          </NewDashboardCard>
        )}
      </div>
    </Form>
  );
}

FormRendered.propTypes = {
  onDelete: PropTypes.func,
  disabled: PropTypes.bool,
  values: PropTypes.shape({
    person: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      }),
    ]),
    role: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      }),
    ]),
  }),
  handleSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
  status: PropTypes.string,
  setFieldValue: PropTypes.func,
  personnel: PropTypes.arrayOf(personnelShape),
  clientId: PropTypes.number,
  roleOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  personnelStatus: PropTypes.shape({
    loading: PropTypes.bool,
  }),
  label: PropTypes.string,
};

FormRendered.defaultProps = {
  onDelete: undefined,
  disabled: undefined,
  values: undefined,
  handleSubmit: undefined,
  isSubmitting: undefined,
  status: undefined,
  setFieldValue: undefined,
  personnel: undefined,
  clientId: undefined,
  roleOptions: undefined,
  personnelStatus: undefined,
  label: undefined,
};

export default PersonnelForm;
