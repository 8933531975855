import fetchJson from 'utility/fetchJson';
import { isEmpty, slice, toUpper } from 'lodash';
import { getValuesForRequest } from 'utility/reverseUrlFilters';
import { BASE } from '../../constants';

export async function getCustomerEngagements({
  sorting = {},
  filters = {},
  pagination = {},
}) {
  // TODO: make a entry in getValuesForRequest for engagements
  // filters = isEmpty(filters) ? getValuesForRequest('customers') : filters
  const body = { ...filters, ...pagination, ...sorting };
  const response = await fetchJson(`${BASE}/api/crm/engagement/list/`, {
    method: 'POST',
    body: JSON.stringify(isEmpty(body) ? { page: 1 } : body),
  });
  return response;
}

export async function getCustomers({
  sorting = {},
  filters = {},
  pagination = {},
}) {
  const currentFilters = isEmpty(filters)
    ? getValuesForRequest('customers')
    : filters;
  const body = { ...currentFilters, ...pagination, ...sorting };
  const response = await fetchJson(`${BASE}/api/user/list/`, {
    method: 'POST',
    body: JSON.stringify(isEmpty(body) ? { page: 1 } : body),
  });
  return response;
}

export async function getCustomersWithLastEvent({
  sorting = {},
  filters = {},
  pagination = {},
}) {
  const currentFilters = isEmpty(filters)
    ? getValuesForRequest('customers')
    : filters;
  const body = {
    ...currentFilters,
    ...pagination,
    ...sorting,
    show_user_last_campaign: true,
  };
  const response = await fetchJson(`${BASE}/api/user/list/`, {
    method: 'POST',
    body: JSON.stringify(isEmpty(body) ? { page: 1 } : body),
  });
  return response;
}

export async function getItemUserMatches({
  contentType,
  objectId,
  matchTypes,
}) {
  const body = {
    content_type: contentType,
    object_id: objectId,
    match_types: matchTypes && matchTypes[0] === 'all' ? undefined : matchTypes,
  };
  const response = await fetchJson(`${BASE}/api/facilitator/match-users/`, {
    method: 'POST',
    body: JSON.stringify(body),
  });

  const createPagination = ({ data }) => {
    const page = 1;
    const limit = 250;
    const startIndex = page * limit - limit;
    const endIndex = page * limit;
    const pagination = {
      page,
      num_pages: Math.ceil(data.length / 10),
      total: data.length,
      limit,
      docs: slice(data, startIndex, endIndex),
    };
    return pagination;
  };

  // Call whenever new matching data is returned from backend (only when match type is changed)
  const transformMatches = (matches) => {
    const pagination = createPagination({ data: matches.response?.docs || [] });

    const transformedMatches = {
      page: pagination?.page,
      num_pages: pagination?.num_pages,
      total: pagination?.total,
      limit: pagination?.limit,
      docs: pagination?.docs,
      facets: matches.facets,
      sort_fields: [
        'first_name',
        'last_name',
        'email',
        'job_title',
        'is_email_confirmed',
        'is_pending_moderation',
        'has_user_been_invited',
        'date_joined',
        'update_timestamp',
        'priority_rank',
        'record_status',
        'status',
      ],
      filters: {},
      sort: { direction: undefined, sort: undefined },
    };
    return transformedMatches;
  };

  const transformedResponse = transformMatches(response);
  return transformedResponse;
}

export async function getCustomerDetails(userId) {
  const response = await fetchJson(`${BASE}/api/user/${userId}/`, {
    method: 'GET',
  });
  return response;
}

export async function getPartnerCustomerDetails(userId) {
  const response = await fetchJson(
    `${BASE}/api/user/partner-user/?id=${userId}`,
    {
      method: 'GET',
    }
  );
  return response;
}

export async function getCustomerSettings(userId) {
  const response = await fetchJson(`${BASE}/api/settings/user/${userId}/`, {
    method: 'GET',
  });
  return response;
}

export async function getCustomerProductWishlist({ userId, cipher, token }) {
  const query =
    cipher && token
      ? `?token=${token}&cipher=${encodeURIComponent(cipher)}`
      : '';
  const response = await fetchJson(
    `${BASE}/api/user/product/${userId}/${query}`,
    {
      method: 'GET',
    }
  );
  return response;
}

export async function getWishlistItem({ userId }) {
  const response = await fetchJson(`${BASE}/api/user/product/${userId}/`, {
    method: 'GET',
  });
  return response;
}

export async function submitCustomer(data) {
  const response = await fetchJson(`${BASE}/api/user/`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
  return response;
}

export async function updateCustomer(userId, data) {
  const response = await fetchJson(`${BASE}/api/user/${userId}/`, {
    method: 'PATCH',
    body: JSON.stringify(data),
  });
  return response;
}

export async function denyCustomer({ user }) {
  const response = await fetchJson(`${BASE}/api/user/moderate/deny/`, {
    method: 'POST',
    body: JSON.stringify({ user }),
  });
  return response;
}

export async function getCustomerInternalRelationships(userId) {
  const response = await fetchJson(
    `${BASE}/api/user/internal-relationship/?user_id=${userId}`,
    {
      method: 'GET',
    }
  );
  return response;
}

// ENRICHMENT
export async function enrichCustomer(customerId) {
  const response = await fetchJson(`${BASE}/api/user/enrich/`, {
    method: 'POST',
    body: JSON.stringify({ user_id: customerId }),
  });
  return response;
}

export async function getEnrichedCustomer(customerId) {
  const queryString = new URLSearchParams([['user_id', customerId]]);
  const response = await fetchJson(`${BASE}/api/user/enrich/?${queryString}`, {
    method: 'GET',
  });
  return response;
}

// FORM MANAGEMENT OF INTERNAL RELATIONSHIPS
export async function createInternalRelationship({
  user,
  relatedUser,
  description,
  type,
}) {
  const body = {
    user,
    related_user: relatedUser,
    description,
    type: toUpper(type),
  };
  const response = await fetchJson(`${BASE}/api/user/internal-relationship/`, {
    method: 'POST',
    body: JSON.stringify(body),
  });
  return response;
}

export async function updateInternalRelationship({
  relationshipId,
  user,
  relatedUser,
  description,
  type,
}) {
  const body = {
    id: relationshipId,
    user,
    related_user: relatedUser,
    description,
    type: toUpper(type),
  };
  const response = await fetchJson(`${BASE}/api/user/internal-relationship/`, {
    method: 'PATCH',
    body: JSON.stringify(body),
  });
  return response;
}

export async function deleteInternalRelationship(relationshipId) {
  const response = await fetchJson(
    `${BASE}/api/user/internal-relationship/?id=${relationshipId}`,
    {
      method: 'DELETE',
    }
  );
  return response;
}

// MANAGEMENT
export async function mergeCustomers(data = {}) {
  const response = await fetchJson(`${BASE}/api/user/merge/`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
  return response;
}

export async function deleteCustomers(data = {}) {
  const response = await fetchJson(`${BASE}/api/user/delete/`, {
    method: 'DELETE',
    body: JSON.stringify(data),
  });
  return response;
}
