import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid';
import {
  Autocomplete,
  Dropdown,
  Menu,
  Field,
} from '@zendeskgarden/react-dropdowns';
import { size } from 'lodash';

import { trackClick } from 'utility/analytics';
import { Ellipsis } from 'theme/Global.styles';
import { variables } from 'theme/variables';
import styled from 'styled-components/macro';
import isIe11 from 'utility/isIe11';
import EndFinishFilterItemSelection from './components/EndFinishFilterItemSelection/EndFinishFilterItemSelection';
import AlloyFilterItemSelectionTwo from './components/AlloyFilterItemSelectionTwo/AlloyFilterItemSelectionTwo';
import {
  SelectedAlloyLabel,
  SelectedAlloyLabelMedium,
} from './FilterItemSelection.styles';

const { custom_blue: customBlue } = variables;
const CountItem = styled('span')`
  color: ${customBlue} !important;
`;

function FilterItemSelection({
  warning,
  error,
  title,
  type,
  selectedFilters,
  selectionFilters,
  optionValues,
  setSelectedValue,
  selectedValue,
  size: propSize,
  small,
  medium,
  semibold,
  colorOverride,
  style,
  minDropdownWidth,
  currentFilters,
  allowMultiple,
}) {
  const [filteredFinishValue, setSelectedFinishFromFilter] = useState(null);
  const [filteredAlloyValue, setSelectedAlloyFromFilter] = useState(null);
  const [numberSelected, setSelected] = useState(0);
  const [elId] = useState(uuid.v4());
  const [isOpen, setIsOpen] = useState(false);
  const [isRendered, setIsRendered] = useState(false);
  const [inputValue, setInputValue] = useState();

  const stateAndActionsRef = useRef(undefined);

  const filterable = true;

  function onClose() {
    if (stateAndActionsRef.current) {
      stateAndActionsRef.current.closeMenu();
    }
    setIsOpen(false);

    if (!isIe11()) {
      const itemInput = document.querySelectorAll('.filter-item-input input');
      for (let i = 0; i < itemInput.length; i += 1) {
        itemInput[i].removeEventListener('focus', onClose);
      }
    }
  }

  function onTab(e) {
    if (e.keyCode === 9) {
      setIsOpen(false);
    }
  }

  useEffect(() => {
    if (isOpen && !isRendered) {
      setIsRendered(true);
      if (!isIe11()) {
        document.addEventListener('keydown', onTab);
      }
    }

    if (isOpen && !isIe11()) {
      let itemInput = document.querySelectorAll(
        `.filter-item-input:not(.el-${elId}) input`
      );
      const itemInputInput = document.querySelectorAll(
        `input.filter-item-input`
      );
      itemInput = [...itemInput, ...itemInputInput];
      for (let i = 0; i < itemInput.length; i += 1) {
        itemInput[i].addEventListener('focus', onClose);
      }
    }
  }, [isRendered, isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(
    () => () => {
      document.removeEventListener('keydown', onTab);
      let itemInput = document.querySelectorAll(
        `.filter-item-input:not(.el-${elId}) input`
      );
      const itemInputInput = document.querySelectorAll(
        `input.filter-item-input`
      );
      itemInput = [...itemInput, ...itemInputInput];
      for (let i = 0; i < itemInput.length; i += 1) {
        itemInput[i].removeEventListener('focus', onClose);
      }
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  let count = (selectedValue && size(selectedValue)) || 0;
  if (type === 'alloy') {
    count += size(selectedFilters?.groups) || 0;
  } else if (type === 'end_finish') {
    count += size(selectedFilters) || 0;
  }
  count = isOpen ? numberSelected || 0 : count;

  function renderSelected() {
    const AlloyLabel = medium ? SelectedAlloyLabelMedium : SelectedAlloyLabel;
    if (selectedValue?.[0]?.label && count === 1) {
      return (
        <Ellipsis>
          <AlloyLabel navy>{selectedValue[0].label}</AlloyLabel>
        </Ellipsis>
      );
    }
    if (count) {
      return (
        <Ellipsis>
          {title} <CountItem>({count})</CountItem>{' '}
        </Ellipsis>
      );
    }
    return <Ellipsis>{title} (0) </Ellipsis>;
  }

  return (
    <Dropdown
      isOpen={isOpen}
      style={style || { position: 'relative' }}
      inputValue={
        (inputValue === '' || inputValue) && filterable
          ? inputValue
          : `${title} (${count})`
      }
      onStateChange={(changes, stateAndActions) => {
        stateAndActionsRef.current = stateAndActions;
        if (
          Object.prototype.hasOwnProperty.call(changes, 'inputValue') &&
          filterable
        ) {
          setInputValue(changes.inputValue);
        }
      }}
      downshiftProps={{
        itemToString: (item) => item && (item.value || item.label || item.id),
      }}
      onSelect={(v) => {
        if (isOpen && type === 'end_finish') {
          setSelectedFinishFromFilter(v);
        } else if (isOpen && type === 'alloy') {
          setSelectedAlloyFromFilter(v);
        }
      }}
    >
      <Field style={{ width: '100%' }}>
        <Autocomplete
          className={`filter-item-input el-${elId}`}
          warning={warning}
          error={error}
          onClick={() => {
            trackClick(`${type}Open`, type, 'FilterItemSelection');
            setIsOpen(true);
          }}
          onFocus={() => {
            trackClick(`${type}Open`, type, 'FilterItemSelection');
            if (!isIe11()) {
              setIsOpen(true);
            }
            setInputValue('');
          }}
          ellipsis
          style={{ width: '100%' }}
          cursorDisabled={!filterable}
          colorOverride={colorOverride}
          semibold={semibold}
          size={propSize}
          medium={medium}
          small={small}
          active={!!numberSelected}
          itemSelection
        >
          {renderSelected()}
        </Autocomplete>
      </Field>
      {isOpen ? (
        <Menu
          small
          popperModifiers={{
            flip: { enabled: false },
          }}
          style={{
            overflow: 'hidden',
            padding: 0,
            minWidth:
              inputValue && type === 'end_finish'
                ? '300px'
                : minDropdownWidth || '550px',
          }}
          className="item-selection-menu"
          placement="bottom-end"
          maxHeight="400px"
        >
          {type === 'end_finish' ? (
            <EndFinishFilterItemSelection
              onClose={() => onClose()}
              filteredFinishValue={filteredFinishValue}
              selectedFilters={selectedFilters}
              selectionFilters={selectionFilters}
              selectedValue={selectedValue}
              setSelectedValue={setSelectedValue}
              inputValue={inputValue}
              clearFinishFilterValue={() => setSelectedFinishFromFilter(null)}
              updateTotalSelected={(n) => setSelected(n)}
              optionValues={optionValues}
              currentFilters={currentFilters}
              allowMultiple={allowMultiple}
              medium={medium}
            />
          ) : (
            <AlloyFilterItemSelectionTwo
              selectedFilters={selectedFilters}
              selectionFilters={selectionFilters}
              filteredAlloyValue={filteredAlloyValue}
              clearAlloyFilterValue={() => setSelectedAlloyFromFilter(null)}
              onClose={() => onClose()}
              setInputValue={setInputValue}
              inputValue={inputValue}
              selectedValue={selectedValue}
              setSelectedValue={setSelectedValue}
              updateTotalSelected={(n) => setSelected(n)}
              optionValues={optionValues}
              currentFilters={currentFilters}
              allowMultiple={allowMultiple}
              medium={medium}
            />
          )}
        </Menu>
      ) : null}
    </Dropdown>
  );
}

FilterItemSelection.defaultProps = {
  warning: undefined,
  error: undefined,
  title: undefined,
  type: undefined,
  selectedFilters: undefined,
  selectionFilters: undefined,
  optionValues: undefined,
  setSelectedValue: undefined,
  selectedValue: undefined,
  size: undefined,
  small: undefined,
  medium: undefined,
  semibold: undefined,
  colorOverride: undefined,
  style: undefined,
  minDropdownWidth: undefined,
  currentFilters: undefined,
  allowMultiple: true,
};

FilterItemSelection.propTypes = {
  warning: PropTypes.bool,
  error: PropTypes.bool,
  title: PropTypes.string,
  type: PropTypes.string,
  selectedFilters: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.shape({
      groups: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  ]),
  selectionFilters: PropTypes.shape({}),
  optionValues: PropTypes.arrayOf(PropTypes.shape({})),
  setSelectedValue: PropTypes.func,
  selectedValue: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
    })
  ),
  size: PropTypes.number,
  small: PropTypes.bool,
  medium: PropTypes.bool,
  semibold: PropTypes.bool,
  colorOverride: PropTypes.bool,
  style: PropTypes.shape({}),
  minDropdownWidth: PropTypes.number,
  currentFilters: PropTypes.shape({}),
  allowMultiple: PropTypes.bool,
};

export default FilterItemSelection;
