import React, { useCallback } from 'react';
import { SM } from '@zendeskgarden/react-typography';
import { Field, Input, MediaInput } from '@zendeskgarden/react-forms';
import { Col, Row } from '@zendeskgarden/react-grid';
import { isObject } from 'lodash';
import PropTypes from 'prop-types';

function FilterItemInputs({
  uom,
  error,
  warning,
  range,
  tolerance,
  title,
  value,
  onChangeInput,
  inputType,
  medium,
  semibold,
  placeholder,
  disabled,
  prefix,
  suffix,
}) {
  const onChange = useCallback(
    (rangeValue, e) => {
      let formattedVal = isObject(value) ? { ...value } : {}; // We dont want to mutate
      const inputVal = e.currentTarget.value;
      if (rangeValue) {
        formattedVal[rangeValue] = { value: inputVal, label: inputVal };
      } else {
        formattedVal = { value: inputVal, label: inputVal };
      }
      onChangeInput(formattedVal);
    },
    [value, onChangeInput]
  );

  const checkValid = useCallback(() => {
    if (error) {
      return 'error';
    }
    if (warning) {
      return 'warning';
    }
    return undefined;
  }, [error, warning]);

  const InputType = tolerance ? MediaInput : Input;

  function getContainer() {
    if (prefix) {
      return 'prefix';
    }
    if (suffix) {
      return 'suffix';
    }
    return true;
  }

  return (
    <div>
      {range ? (
        <Row>
          <Col>
            <Field>
              <InputType
                validation={checkValid()}
                className="filter-item-input"
                type={inputType || undefined}
                itemSelection
                placeholder={tolerance ? '0.0000' : 'Min'}
                value={value?.min?.value || ''}
                onChange={(...otherMin) => onChange('min', ...otherMin)}
                medium={medium}
                semibold={semibold}
                disabled={disabled}
                start={tolerance ? '+' : undefined}
                tolerance={tolerance}
                wrapperProps={{
                  bare: true,
                }}
              />
            </Field>
          </Col>
          <Col>
            <Field>
              <InputType
                validation={checkValid()}
                className="filter-item-input"
                type={inputType || undefined}
                itemSelection
                placeholder={tolerance ? '0.0000' : 'Max'}
                value={value?.max?.value || ''}
                onChange={(...otherMax) => onChange('max', ...otherMax)}
                medium={medium}
                semibold={semibold}
                disabled={disabled}
                start={tolerance ? '-' : undefined}
                tolerance={tolerance}
                wrapperProps={{
                  bare: true,
                }}
              />
            </Field>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col>
            <div className="input-container">
              <Field
                paddingLeft={prefix?.paddingLeft || undefined}
                paddingRight={suffix?.paddingRight || undefined}
                container={getContainer()}
              >
                {prefix && <SM fieldLabelPrefix>{prefix.value}</SM>}
                <Input
                  validation={checkValid()}
                  className="filter-item-input"
                  type={inputType || undefined}
                  itemSelection
                  placeholder={placeholder || title}
                  value={value?.value || ''}
                  onChange={(...other) => onChange(null, ...other)}
                  medium={medium}
                  semibold={semibold}
                />
                {suffix && <SM fieldLabelSuffix>{suffix.value}</SM>}
                {uom && uom !== 'Equals' && uom !== 'Range' ? (
                  <SM fieldLabel>{uom}</SM>
                ) : null}
              </Field>
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
}

FilterItemInputs.defaultProps = {
  uom: undefined,
  error: undefined,
  warning: undefined,
  range: undefined,
  title: undefined,
  value: undefined,
  onChangeInput: undefined,
  inputType: undefined,
  medium: undefined,
  semibold: undefined,
  placeholder: undefined,
  disabled: false,
  tolerance: undefined,
  prefix: undefined,
  suffix: undefined,
};

FilterItemInputs.propTypes = {
  uom: PropTypes.string,
  error: PropTypes.bool,
  warning: PropTypes.bool,
  range: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  value: PropTypes.oneOfType([
    PropTypes.shape({
      value: PropTypes.string,
      min: PropTypes.shape({
        value: PropTypes.string,
      }),
      max: PropTypes.shape({
        value: PropTypes.string,
      }),
    }),
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    PropTypes.string,
  ]),
  onChangeInput: PropTypes.func,
  inputType: PropTypes.string,
  medium: PropTypes.bool,
  semibold: PropTypes.bool,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  tolerance: PropTypes.bool,
  prefix: PropTypes.shape({
    paddingLeft: PropTypes.number,
    paddingRight: PropTypes.number,
    value: PropTypes.string,
  }),
  suffix: PropTypes.shape({
    paddingLeft: PropTypes.number,
    paddingRight: PropTypes.number,
    value: PropTypes.string,
  }),
};

export default FilterItemInputs;
