import React from 'react';
import { variables } from 'theme/variables';
import { get, map, includes } from 'lodash';
import { Row, Col } from '@zendeskgarden/react-grid';
import FilterItemGroup from 'components/FilterItemGroup/FilterItemGroup';
import PropTypes from 'prop-types';
import PublicThemeOverrides from 'higherOrder/PublicThemeOverrides';
import { webRefactorSwitch } from 'utility/environment';
import ItemSelectionFilterWrapper from './ItemSelectionFilters.styles';
import { rfqMetaShape } from '../../propTypesObjects';
import Icon from '../Icon/Icon';
import { Button } from '../../theme/Button';

const { spacing_sm: spacingSm, spacing_lg: spacingLg } = variables;

function ItemSelectionFilters({
  style = {},
  filters,
  onChangeFilters,
  itemId,
  rfqMeta,
  inline,
  search,
  toggleTable,
  tableVisible,
  menuZIndex,
  popperModifiers,
  resetFilters,
  page,
  titleStyle,
  hiddenFilters,
  medium,
  isMfg,
}) {
  return (
    <ItemSelectionFilterWrapper
      style={style}
      className="item-selection-filters"
      inline={inline}
      page={page}
    >
      <Row extraPadding style={{ alignItems: 'center' }}>
        {map(filters, (filter, type, ff) => {
          const canBeSmall = !filter.operators && !type === 'end_finish';
          const range = get(filter, 'operator.value') === 'range';
          const hidden = hiddenFilters && includes(hiddenFilters || [], type);
          return !filter.disabled && !filter.hidden && !hidden ? (
            <Col
              size={filter.columnSize}
              className={`filter-wrapper ${type} ${range ? 'range' : ''} ${
                canBeSmall ? 'can_be_small' : 'not_small'
              }`}
              style={{
                marginBottom: inline ? spacingSm : spacingLg,
                ...(filter.columnStyle || {}),
              }}
              key={type}
            >
              <FilterItemGroup
                ignorePlaceholderPrefix
                selectionFilters={filter.selectionFilters}
                currentFilters={{ ...ff }}
                filter={filter}
                type={type}
                onChangeFilters={onChangeFilters}
                itemId={itemId}
                rfqMeta={rfqMeta}
                inline={inline}
                menuZIndex={menuZIndex}
                popperModifiers={popperModifiers}
                titleStyle={titleStyle}
                medium={medium}
                isMfg={isMfg}
              />
            </Col>
          ) : (
            <span key={type} />
          );
        })}
        {search && (
          <Col style={{ paddingRight: '10px', paddingLeft: '10px' }}>
            <Button
              className="iconButton"
              onClick={() =>
                page === 'globalWarehouse' ? resetFilters() : toggleTable()
              }
            >
              <Icon
                className="resourcesIcon"
                fontAwesome
                icon={
                  page === 'globalWarehouse'
                    ? 'rotate-right'
                    : 'magnifying-glass'
                }
                iconPrefix={page === 'globalWarehouse' ? 'far' : 'fal'}
                fontSize="16px"
              />
              <div className="resourcesName">
                {tableVisible
                  ? `${
                      page === 'globalWarehouse'
                        ? 'Reset Filters'
                        : 'Close Table & Reset'
                    }`
                  : `Search`}
              </div>
            </Button>
          </Col>
        )}
      </Row>
    </ItemSelectionFilterWrapper>
  );
}

ItemSelectionFilters.defaultProps = {
  style: undefined,
  filters: undefined,
  onChangeFilters: undefined,
  itemId: undefined,
  rfqMeta: undefined,
  inline: undefined,
  search: false,
  toggleTable: undefined,
  tableVisible: undefined,
  menuZIndex: undefined,
  popperModifiers: undefined,
  resetFilters: undefined,
  page: undefined,
  titleStyle: undefined,
  hiddenFilters: undefined,
  medium: undefined,
  isMfg: undefined,
};

ItemSelectionFilters.propTypes = {
  style: PropTypes.shape({}),
  filters: PropTypes.shape([]),
  onChangeFilters: PropTypes.func,
  itemId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  rfqMeta: rfqMetaShape,
  inline: PropTypes.bool,
  search: PropTypes.bool,
  toggleTable: PropTypes.func,
  tableVisible: PropTypes.bool,
  menuZIndex: PropTypes.number,
  popperModifiers: PropTypes.shape({}),
  resetFilters: PropTypes.func,
  page: PropTypes.string,
  titleStyle: PropTypes.shape({}),
  hiddenFilters: PropTypes.arrayOf(PropTypes.string),
  medium: PropTypes.bool,
  isMfg: PropTypes.bool,
};

export const PublicItemSelectionFilters = webRefactorSwitch
  ? PublicThemeOverrides(ItemSelectionFilters)
  : ItemSelectionFilters;

export default ItemSelectionFilters;
