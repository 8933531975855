import asyncTreeRequester from 'utility/asyncTreeRequester';
import tree from 'state';
import { generateItemFilters } from 'state/requestForQuote/transformer';
import * as effects from './effects';

const marketComparablesCursor = tree.select(['marketComparables']);

export async function getMarketComparables({
  filters,
  pagination,
  sorting,
  grouping,
} = {}) {
  return asyncTreeRequester({
    func: effects.getMarketComparables.bind(this),
    pagination,
    filters,
    sorting,
    grouping,
    cursor: marketComparablesCursor,
    path: ['currentData'],
  });
}

export async function getMarketComparableItem(id) {
  return asyncTreeRequester({
    func: effects.getMarketComparableItem.bind(this, id),
    cursor: marketComparablesCursor,
    path: ['currentMarketComparable'],
  });
}

export function setMarketComparable(data, type, id) {
  return asyncTreeRequester({
    func:
      type === 'Add'
        ? effects.createMarketComparable.bind(this, data)
        : effects.updateMarketComparable.bind(this, id, data),
    cursor: marketComparablesCursor,
    path: ['currentMarketComparable'],
  });
}

export function clearCurrentMarketComparable() {
  marketComparablesCursor.unset(['currentMarketComparable']);
}

export function initItemFilters(seed) {
  const itemFilters = generateItemFilters(undefined, seed);
  marketComparablesCursor.set(['itemFilters'], itemFilters);
}

export function updateItemFilters(type, data) {
  const { value, operator, selectedFilters } = data;
  const itemFilters = marketComparablesCursor.get(['itemFilters']);
  if (selectedFilters) {
    itemFilters[type] = {
      ...itemFilters[type],
      selectedFilters,
    };
  }
  if (value || value === null) {
    itemFilters[type] = {
      ...itemFilters[type],
      value,
    };
  }
  if (operator) {
    itemFilters[type] = {
      ...itemFilters[type],
      operator,
    };
  }
  marketComparablesCursor.set(['itemFilters'], itemFilters);
}

export async function getPurchaseHistory({
  filters,
  pagination,
  sorting,
  grouping,
} = {}) {
  return asyncTreeRequester({
    func: effects.getPurchaseHistory.bind(this),
    pagination,
    filters,
    sorting,
    grouping,
    cursor: marketComparablesCursor,
    path: ['purchaseHistory'],
  });
}

export async function getPurchaseItemHistory({
  filters,
  pagination,
  sorting,
  grouping,
} = {}) {
  return asyncTreeRequester({
    func: effects.getPurchaseItemHistory.bind(this),
    pagination,
    filters,
    sorting,
    grouping,
    cursor: marketComparablesCursor,
    path: ['purchaseItemHistory'],
  });
}

export async function getPurchaseGroupHistory({
  filters,
  pagination,
  sorting,
  grouping,
} = {}) {
  return asyncTreeRequester({
    func: effects.getPurchaseGroupHistory.bind(this),
    pagination,
    filters,
    sorting,
    grouping,
    cursor: marketComparablesCursor,
    path: ['purchaseGroupHistory'],
  });
}

export async function getPurchaseGroup(id) {
  return asyncTreeRequester({
    func: effects.getPurchaseGroup.bind(this, id),
    cursor: marketComparablesCursor,
    path: ['purchaseGroup'],
  });
}

export async function getRequestQuoteHistory({
  filters,
  pagination,
  sorting,
  grouping,
} = {}) {
  return asyncTreeRequester({
    func: effects.getRequestQuoteHistory.bind(this),
    pagination,
    filters,
    sorting,
    grouping,
    cursor: marketComparablesCursor,
    path: ['requestQuoteHistory'],
  });
}

export async function getRequestOrderHistory({
  filters,
  pagination,
  sorting,
  grouping,
} = {}) {
  return asyncTreeRequester({
    func: effects.getRequestOrderHistory.bind(this),
    pagination,
    filters,
    sorting,
    grouping,
    cursor: marketComparablesCursor,
    path: ['requestOrderHistory'],
  });
}

export async function getRequestItemHistory({
  filters,
  pagination,
  sorting,
  grouping,
} = {}) {
  return asyncTreeRequester({
    func: effects.getRequestItemHistory.bind(this),
    pagination,
    filters,
    sorting,
    grouping,
    cursor: marketComparablesCursor,
    path: ['requestItemHistory'],
  });
}

export async function getRequestGroupHistory({
  filters,
  pagination,
  sorting,
  grouping,
} = {}) {
  return asyncTreeRequester({
    func: effects.getRequestGroupHistory.bind(this),
    pagination,
    filters,
    sorting,
    grouping,
    cursor: marketComparablesCursor,
    path: ['requestGroupHistory'],
  });
}

export async function getRequestGroup(id) {
  return asyncTreeRequester({
    func: effects.getRequestGroup.bind(this, id),
    cursor: marketComparablesCursor,
    path: ['requestGroup'],
  });
}

export async function getOrderHistory({
  filters,
  pagination,
  sorting,
  grouping,
} = {}) {
  return asyncTreeRequester({
    func: effects.getOrderHistory.bind(this),
    pagination,
    filters,
    sorting,
    grouping,
    cursor: marketComparablesCursor,
    path: ['orderHistory'],
  });
}

export async function getOrderItemHistory({
  filters,
  pagination,
  sorting,
  grouping,
} = {}) {
  return asyncTreeRequester({
    func: effects.getOrderItemHistory.bind(this),
    filters,
    pagination,
    sorting,
    grouping,
    cursor: marketComparablesCursor,
    path: ['orderItemHistory'],
  });
}

export async function getOrderGroupHistory({
  filters,
  pagination,
  sorting,
  grouping,
} = {}) {
  return asyncTreeRequester({
    func: effects.getOrderGroupHistory.bind(this),
    pagination,
    filters,
    sorting,
    grouping,
    cursor: marketComparablesCursor,
    path: ['orderGroupHistory'],
  });
}

export async function getOrderGroup(id) {
  return asyncTreeRequester({
    func: effects.getOrderGroup.bind(this, id),
    cursor: marketComparablesCursor,
    path: ['orderGroup'],
  });
}

export async function patchMarketCompDetails(data) {
  try {
    marketComparablesCursor.merge(['currentMarketComparable', 'result'], data);
    return data;
  } catch (err) {
    return { error: err.message };
  }
}
