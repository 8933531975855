import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  groupBy,
  cloneDeep,
  findIndex,
  filter,
  map,
  get,
  find,
  forEach,
  reject,
} from 'lodash';
import Flex from 'styled-flex-component';
import { LG, SM } from '@zendeskgarden/react-typography';
import { Col, Row } from '@zendeskgarden/react-grid';
import Checkbox from 'components/Checkbox/Checkbox';

import {
  EndFinishFilterItemSelectionWrapper,
  SelectionCol,
  Item,
  EmptySelectionCol,
} from './AlloyFilterItemSelectionTwo.styles';

import AlloyFilterItemSelectionTwoResults from './components/AlloyFilterItemSelectionTwoResults/AlloyFilterItemSelectionTwoResults';

function AlloyFilterItemSelectionTwoItemSelection({
  updateTotalSelected,
  setSelectedValue,
  filteredAlloyValue,
  clearAlloyFilterValue,
  optionValues,
  selectionFilters,
  selectedValue,
  selectedFilters,
  inputValue,
  onClose,
  allowMultiple,
}) {
  const [initiated, setInitiated] = useState();
  const [activeState, setActiveState] = useState({
    alloys: [],
    activeGroups: [],
  });

  const { activeGroups, alloyGroups, alloys } = activeState;

  const selectedAlloys = filter(alloys, 'selected');

  const totalSelected = filter(alloys, ({ selected }) => selected).length;

  const chooseAlloy = useCallback(
    (_alloys, _activeGroups) => {
      const selected = filter(_alloys, 'selected');
      updateTotalSelected(selected.length + get(_activeGroups, 'length', 0));
      setSelectedValue(selected, {
        selectedFilters: { groups: _activeGroups },
      });
    },
    [setSelectedValue, updateTotalSelected]
  );

  useEffect(() => {
    if (initiated) {
      chooseAlloy(alloys, activeGroups);
    }
  }, [activeGroups, alloys, chooseAlloy, initiated]);

  useEffect(() => {
    updateTotalSelected(totalSelected);
  }, [totalSelected, updateTotalSelected]);

  const onSelectGroup = useCallback(
    (selectedGroup, selected) => {
      let cf;
      if (!selected) {
        cf = [...activeGroups, selectedGroup];
      } else {
        const index = findIndex(activeGroups, selectedGroup);
        cf = [...activeGroups];
        cf.splice(index, 1);
      }

      let alloyItems = alloys;
      if (!selected) {
        alloyItems = map(alloys, (a) => {
          const obj = { ...a };
          if (selectedGroup.value === obj.group) {
            obj.selected = false;
          }
          return obj;
        });
      }

      setActiveState({
        ...activeState,
        alloys: alloyItems,
        activeGroups: cf,
      });
    },
    [activeGroups, activeState, alloys]
  );

  const onSelectItem = useCallback(
    (item, custom) => {
      const items = cloneDeep(alloys);
      if (custom === true) {
        items.push(item);
      }
      // Allow for cases where filters are generated from a different transformer which may not have set 'multiple' at all.
      // We are treating multiple === true as default
      if (allowMultiple === false) {
        forEach(items, (_element, index) => {
          items[index].selected = false;
        });
      }
      const itemInList = find(items, item);
      if (itemInList) {
        itemInList.selected = !itemInList.selected;
      }

      const removeItemsGroup = reject(activeGroups, ['value', item.group]);

      setActiveState({
        ...activeState,
        alloys: items,
        activeGroups: removeItemsGroup,
      });
    },
    [activeGroups, activeState, allowMultiple, alloys]
  );

  useEffect(() => {
    // when searching values, the event bubbles to root dropdown. we then pass that down to this componnet
    // where we set it. not the best performance but works
    // this is not ideal but it works.
    if (filteredAlloyValue?.alloy?.value) {
      const v = filteredAlloyValue;
      if (v.alloy.type) {
        onSelectGroup(v.alloy, v.selected);
      } else {
        onSelectItem(v.alloy, v.customValue);
      }
      clearAlloyFilterValue();
    }
  }, [
    clearAlloyFilterValue,
    filteredAlloyValue,
    filteredAlloyValue?.alloy?.value,
    onSelectGroup,
    onSelectItem,
  ]);

  function initInitialSelectedAlloys(_alloys, _selectedAlloys) {
    const items = map(_alloys, (g) => {
      const currentVg = find(_selectedAlloys, { value: g.value });
      return {
        ...g,
        selected: !!currentVg,
      };
    });

    const customValues = filter(_selectedAlloys, 'customValue');

    return [...items, ...customValues];
  }

  useEffect(() => {
    if (!initiated) {
      const alloyItems = optionValues || [];
      const availableGroups = selectionFilters?.groups || [];

      const _selectedAlloys = selectedValue || [];
      const selectedGroups = selectedFilters?.groups || [];
      const selectedValueItems = initInitialSelectedAlloys(
        alloyItems,
        _selectedAlloys
      );

      const defaultState = {
        ...activeState,
        alloys: selectedValueItems,
        alloyGroups: availableGroups,
        activeGroups: selectedGroups,
      };

      setActiveState(defaultState);
      setInitiated(true);
    }
  }, [
    activeState,
    initiated,
    optionValues,
    selectedFilters?.groups,
    selectedValue,
    selectionFilters?.groups,
  ]);

  if (!alloys.length) {
    return <div />;
  }
  if (inputValue) {
    return (
      <AlloyFilterItemSelectionTwoResults
        inputValue={inputValue}
        activeState={activeState}
      />
    );
  }
  return (
    <EndFinishFilterItemSelectionWrapper>
      <Flex justifyBetween alignCenter>
        <LG bold>Choose Alloy</LG>
        <SM bold justifyEnd onClick={onClose} link>
          Done
        </SM>
      </Flex>

      <Row style={{ marginTop: 0 }}>
        <Col sm={6} size={10}>
          <SelectionCol>
            {alloys.length ? (
              map(alloyGroups, (group) => {
                const { label, value } = group;
                const selected = find(activeGroups, { value });
                return (
                  <div key={`${value}-${label}`}>
                    {allowMultiple !== false ? (
                      <Item
                        style={{ background: 'rgb(243, 243, 243)' }}
                        onClick={() => onSelectGroup(group, selected)}
                        key={`${value}-${label}`}
                      >
                        <Checkbox checked={selected} />
                        <SM bold>{label}</SM>
                      </Item>
                    ) : (
                      <Item
                        style={{
                          background: 'rgb(243, 243, 243)',
                          cursor: 'auto',
                        }}
                        key={`${value}-${label}`}
                      >
                        <SM bold>{label}</SM>
                      </Item>
                    )}
                    <div>
                      {map(
                        filter(alloys, ['group', group.value]),
                        (alloyItem) => {
                          const {
                            label: _label,
                            value: _value,
                            selected: _selected,
                          } = alloyItem;
                          return (
                            <Item
                              onClick={() => onSelectItem(alloyItem)}
                              key={`${_value}-${_label}`}
                            >
                              <Checkbox checked={_selected} />
                              {_label}
                            </Item>
                          );
                        }
                      )}
                    </div>
                  </div>
                );
              })
            ) : (
              <EmptySelectionCol>No Items Match Criteria</EmptySelectionCol>
            )}
          </SelectionCol>
        </Col>

        <Col sm={6} size={6}>
          <SelectionCol>
            {!activeGroups.length && !selectedAlloys.length ? (
              <EmptySelectionCol>No Items Have Been Selected</EmptySelectionCol>
            ) : null}
            {map(activeGroups, (_filter, i) => (
              <React.Fragment key={i}>
                {i === 0 ? (
                  <Item key={-1} headerItem small>
                    Selected Alloy Groups
                  </Item>
                ) : null}
                <Item onClick={() => onSelectGroup(_filter, true)} key={`${i}`}>
                  <div>
                    <Flex alignCenter>
                      <Checkbox checked />
                      {_filter.label}
                    </Flex>
                  </div>
                </Item>
              </React.Fragment>
            ))}
            {map(groupBy(selectedAlloys, 'group'), (gr, groupId) => {
              const type =
                get(find(alloyGroups, { value: Number(groupId) }), 'label') ||
                'Custom Alloys';
              return (
                <div key={`${gr.value}-${type}`}>
                  <Item key={-12} headerItem small>
                    {type}
                  </Item>
                  {map(gr, (alloy) => (
                    <Item
                      onClick={() => onSelectItem(alloy)}
                      key={`${alloy.value}-${alloy.label}`}
                    >
                      <div>
                        <Flex alignCenter>
                          <Checkbox checked />
                          {alloy.label}
                        </Flex>
                      </div>
                    </Item>
                  ))}
                </div>
              );
            })}
          </SelectionCol>
        </Col>
      </Row>
    </EndFinishFilterItemSelectionWrapper>
  );
}

AlloyFilterItemSelectionTwoItemSelection.propTypes = {
  updateTotalSelected: PropTypes.func,
  setSelectedValue: PropTypes.func,
  filteredAlloyValue: PropTypes.shape({
    alloy: PropTypes.shape({
      value: PropTypes.number,
    }),
  }),
  clearAlloyFilterValue: PropTypes.func,
  optionValues: PropTypes.arrayOf(PropTypes.shape({})),
  selectionFilters: PropTypes.shape({
    groups: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  selectedValue: PropTypes.arrayOf(PropTypes.shape({})),
  selectedFilters: PropTypes.shape({
    groups: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  inputValue: PropTypes.string,
  onClose: PropTypes.func,
  allowMultiple: PropTypes.bool,
};

AlloyFilterItemSelectionTwoItemSelection.defaultProps = {
  updateTotalSelected: undefined,
  setSelectedValue: undefined,
  filteredAlloyValue: undefined,
  clearAlloyFilterValue: undefined,
  optionValues: undefined,
  selectionFilters: undefined,
  selectedValue: undefined,
  selectedFilters: undefined,
  inputValue: undefined,
  onClose: undefined,
  allowMultiple: undefined,
};

export default AlloyFilterItemSelectionTwoItemSelection;
