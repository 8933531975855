import fetchJson from 'utility/fetchJson';
import { isEmpty } from 'lodash';
import { BASE } from '../../constants';
import { getValuesForRequest } from 'utility/reverseUrlFilters';

export async function getAttributeCategoryList({
  filters,
  pagination,
  sorting = {},
}) {
  filters = !filters ? getValuesForRequest('conversations') : filters;
  const body = { ...filters, ...pagination, ...sorting };
  const response = await fetchJson(`${BASE}/api/attribute/value/list/`, {
    method: 'POST',
    body: JSON.stringify(isEmpty(body) ? { page: 1 } : body),
  });
  return response;
}

export async function getAttributeCategory() {
  const response = await fetchJson(`${BASE}/api/attribute/category/`, {
    method: 'GET',
  });
  return response;
}

export async function createAttributeCategory(data) {
  const response = await fetchJson(`${BASE}/api/attribute/category/`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
  return response;
}

export async function updateAttributeCategory(id, data) {
  const response = await fetchJson(`${BASE}/api/attribute/category/${id}/`, {
    method: 'PATCH',
    body: JSON.stringify(data),
  });
  return response;
}

export async function deleteAttributeCategory(id) {
  const response = await fetchJson(`${BASE}/api/attribute/category/${id}/`, {
    method: 'DELETE',
  });
  return response;
}

export async function getAttributeSubCategory() {
  const response = await fetchJson(`${BASE}/api/attribute/category/sub/`, {
    method: 'GET',
  });
  return response;
}

export async function createAttributeSubCategory(data) {
  const response = await fetchJson(`${BASE}/api/attribute/category/sub/`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
  return response;
}

export async function updateAttributeSubCategory(id, data) {
  const response = await fetchJson(
    `${BASE}/api/attribute/category/sub/${id}/`,
    {
      method: 'PATCH',
      body: JSON.stringify(data),
    }
  );
  return response;
}

export async function deleteAttributeSubCategory(id) {
  const response = await fetchJson(
    `${BASE}/api/attribute/category/sub/${id}/`,
    {
      method: 'DELETE',
    }
  );
  return response;
}

export async function getAttributeCategoryValue() {
  const response = await fetchJson(`${BASE}/api/attribute/value/`, {
    method: 'GET',
  });
  return response;
}

export async function createAttributeCategoryValue(data) {
  const response = await fetchJson(`${BASE}/api/attribute/value/`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
  return response;
}

export async function getAttributeCategoryDetails(id) {
  const response = await fetchJson(`${BASE}/api/attribute/value/${id}/`, {
    method: 'GET',
  });
  return response;
}

export async function updateAttributeCategoryValue(id, data) {
  const response = await fetchJson(`${BASE}/api/attribute/value/${id}/`, {
    method: 'PATCH',
    body: JSON.stringify(data),
  });
  return response;
}

export async function deleteAttributeCategoryValue(id) {
  const response = await fetchJson(`${BASE}/api/attribute/value/${id}/`, {
    method: 'DELETE',
  });
  return response;
}

export async function getAttributesForContentType(data) {
  const response = await fetchJson(
    `${BASE}/api/attribute/content-type/object/`,
    {
      method: 'POST',
      body: JSON.stringify(data),
    }
  );
  return response;
}

export async function addAttributesToContentType(data) {
  const response = await fetchJson(`${BASE}/api/attribute/value/object/`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
  return response;
}

export async function deleteAttributesToContentType(id) {
  const response = await fetchJson(
    `${BASE}/api/attribute/value/object/${id}/`,
    {
      method: 'DELETE',
    }
  );
  return response;
}

export async function getAttributeItems(data) {
  const response = await fetchJson(`${BASE}/api/attribute/value/item/list/`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
  return response;
}

export async function saveAttributeItem(data) {
  const response = await fetchJson(`${BASE}/api/attribute/value/item/`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
  return response;
}

export async function updateAttributeItem(data) {
  const response = await fetchJson(`${BASE}/api/attribute/value/item/`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
  return response;
}

export async function deleteAttributeItem(id) {
  const response = await fetchJson(`${BASE}/api/attribute/value/item/${id}/`, {
    method: 'DELETE',
  });
  return response;
}

// /api/attribute/category/ - high level category (“application type”)
// /api/attribute/category/sub/ - child level category (“octg”)
// /api/attribute/value/ - value of either a high level caetgory or child (“SWD”)
// /api/attribute/value/object - link between value (above) and object (content type + object_id)
