import fetchJson from 'utility/fetchJson';
import moment from 'moment';
import { BASE } from '../../constants';

export async function getRfqMeta() {
  let storedMeta = localStorage.getItem('rfqMeta');
  storedMeta = storedMeta ? JSON.parse(storedMeta) : null;
  const shouldRefetch = storedMeta
    ? moment(Date.now()).diff(storedMeta.date, 'minutes') > 20
    : true;

  if (shouldRefetch) {
    const response = await fetchJson(`${BASE}/api/meta/item/`, {
      method: 'GET',
    });
    if (response && !response.error) {
      localStorage.setItem(
        'rfqMeta',
        JSON.stringify({ date: new Date(), data: response })
      );
    }
    return response;
  }
  return storedMeta.data;
}

export async function getRfqGroup(id, isMfg) {
  const path = isMfg ? '/api/mrfq/group/' : '/api/rfq/group/';
  const response = await fetchJson(`${BASE}${path}${id}/`, {
    method: 'GET',
  });
  return response;
}

export async function getRfqOrphans(options, isMfg) {
  const { userId } = options || {};
  const path = isMfg ? '/api/mrfq/item/orphans/' : '/api/rfq/item/orphans/';
  const query = `?limit=100${userId ? `&user=${userId}` : ''}`;
  const response = await fetchJson(`${BASE}${path}${query}`, {
    method: 'GET',
  });
  return response;
}

// ONBAORD
export async function getUserInterestItems(interestItemId) {
  const response = await fetchJson(
    `${BASE}/api/on-board/product/${`${interestItemId}/` || ''}`,
    {
      method: 'GET',
    }
  );
  return response;
}

export async function saveUserInterestItems(data, { cipher, token } = {}) {
  const query =
    cipher && token
      ? `?token=${token}&cipher=${encodeURIComponent(cipher)}`
      : '';
  const response = await fetchJson(`${BASE}/api/on-board/product/${query}`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
  return response;
}

export async function updateUserInterestItems(
  id,
  data,
  { cipher, token } = {}
) {
  const query =
    cipher && token
      ? `?token=${token}&cipher=${encodeURIComponent(cipher)}`
      : '';
  const response = await fetchJson(
    `${BASE}/api/on-board/product/${id}/${query}`,
    {
      method: 'PATCH',
      body: JSON.stringify(data),
    }
  );
  return response;
}

export async function deleteUserInterestItems(id, { cipher, token }) {
  const query =
    cipher && token
      ? `?token=${token}&cipher=${encodeURIComponent(cipher)}`
      : '';
  const response = await fetchJson(
    `${BASE}/api/on-board/product/${id}/${query}`,
    {
      method: 'DELETE',
    }
  );
  return response;
}

export async function getRfqGroupItems(id, isMfg) {
  const path = isMfg ? '/api/mrfq/group/item/' : '/api/rfq/group/item/';
  const response = await fetchJson(`${BASE}${path}${id}/`, {
    method: 'GET',
  });
  return response;
}

export async function getRfqItemDetails(id, isMfg) {
  const path = isMfg ? '/api/mrfq/item/' : '/api/rfq/item/';
  const response = await fetchJson(`${BASE}${path}${id}/`, {
    method: 'GET',
  });
  return response;
}

export async function createRfqGroup(data, options, isMfg) {
  const { cipher, token } = options || {};
  const query =
    cipher && token
      ? `?token=${token}&cipher=${encodeURIComponent(cipher)}`
      : '';
  const path = isMfg ? '/api/mrfq/group/' : '/api/rfq/group/';
  const response = await fetchJson(`${BASE}${path}${query}`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
  return response;
}

export async function updateRfqGroup(id, data, isMfg) {
  const path = isMfg ? '/api/mrfq/group/' : '/api/rfq/group/';
  const response = await fetchJson(`${BASE}${path}${id}/`, {
    method: 'PATCH',
    body: JSON.stringify(data),
  });
  return response;
}

export async function getRfqGroupItemOverrides(rfqId, isMfg) {
  const path = isMfg
    ? '/api/mrfq/group/item/attr/summary/'
    : '/api/rfq/group/item/attr/summary/';
  const response = await fetchJson(`${BASE}${path}${rfqId}/`, {
    method: 'GET',
  });
  return response;
}

export async function getRfqGroupItemOverride(id, isMfg) {
  const path = isMfg
    ? '/api/mrfq/group/item/attr/'
    : '/api/rfq/group/item/attr/';
  const response = await fetchJson(`${BASE}${path}${id}/`, {
    method: 'GET',
  });
  return response;
}

export async function createRfqGroupItemOverride(data, isMfg) {
  const path = isMfg
    ? '/api/mrfq/group/item/attr/'
    : '/api/rfq/group/item/attr/';
  const response = await fetchJson(`${BASE}${path}`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
  return response;
}

export async function updateRfqGroupItemOverride(id, data, isMfg) {
  const path = isMfg
    ? '/api/mrfq/group/item/attr/'
    : '/api/rfq/group/item/attr/';
  const response = await fetchJson(`${BASE}${path}${id}/`, {
    method: 'PATCH',
    body: JSON.stringify(data),
  });
  return response;
}

export async function deleteRfqGroupItemOverride(id, isMfg) {
  const path = isMfg
    ? '/api/mrfq/group/item/attr/'
    : '/api/rfq/group/item/attr/';
  const response = await fetchJson(`${BASE}${path}${id}/`, {
    method: 'DELETE',
  });
  return response;
}

export async function getRfqTechnicalData(data, isMfg) {
  const path = isMfg ? '/api/mrfq/item/technical/' : '/api/rfq/item/technical/';
  const response = await fetchJson(`${BASE}${path}`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
  return response;
}

export async function saveItemData(data, options, isMfg) {
  const { cipher, token } = options || {};
  const query =
    cipher && token
      ? `?token=${token}&cipher=${encodeURIComponent(cipher)}`
      : '';
  const path = isMfg ? '/api/mrfq/item/' : '/api/rfq/item/';
  const response = await fetchJson(`${BASE}${path}${query}`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
  return response;
}

export async function updateItemData(id, data, isMfg) {
  const path = isMfg ? '/api/mrfq/item/' : '/api/rfq/item/';
  const response = await fetchJson(`${BASE}${path}${id}/`, {
    method: 'PATCH',
    body: JSON.stringify(data),
  });
  return response;
}

export async function deleteItemData(id, isMfg) {
  const path = isMfg ? '/api/mrfq/item/' : '/api/rfq/item/';
  const response = await fetchJson(`${BASE}${path}${id}/`, {
    method: 'DELETE',
  });
  return response;
}

export async function getRfqWellDetails(rfqId) {
  const response = await fetchJson(`${BASE}/api/rfq/group/well/${rfqId}/`, {
    method: 'GET',
  });
  return response;
}

export async function saveRfqWellDetails(data) {
  const response = await fetchJson(`${BASE}/api/rfq/well/`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
  return response;
}

export async function updateRfqWellDetails(id, data) {
  const response = await fetchJson(`${BASE}/api/rfq/well/${id}/`, {
    method: 'PATCH',
    body: JSON.stringify(data),
  });
  return response;
}

export async function deleteRfqWellDetails(id) {
  const response = await fetchJson(`${BASE}/api/rfq/well/${id}/`, {
    method: 'DELETE',
  });
  return response;
}

export async function getRfqGroupSummary(rfqId, isMfg) {
  const path = isMfg ? '/api/mrfq/group/summary/' : '/api/rfq/group/summary/';
  const response = await fetchJson(`${BASE}${path}${rfqId}/`, {
    method: 'GET',
  });
  return response;
}

export async function transitionRfq(rfqId, status, data, options, isMfg) {
  const { cipher, token } = options || {};
  const path = isMfg ? '/api/mrfq/group/' : '/api/rfq/group/';
  const query =
    cipher && token
      ? `?token=${token}&cipher=${encodeURIComponent(cipher)}`
      : '';
  try {
    const response = await fetchJson(
      `${BASE}${path}${rfqId}/${status}/${query}`,
      {
        method: 'POST',
        body: JSON.stringify({ json: JSON.stringify(data) }),
      }
    );
    return response;
  } catch (err) {
    return { error: err.message };
  }
}

export async function getRfqStatusLog({ rfqId, paginationUrl }, isMfg) {
  const path = isMfg
    ? '/api/mrfq/group/transitions/'
    : '/api/rfq/group/transitions/';
  const response = await fetchJson(paginationUrl || `${BASE}${path}${rfqId}/`, {
    method: 'GET',
  });
  return response;
}

export async function deleteRfqCustomerPersonnel(id, isMfg) {
  const path = isMfg ? '/api/mrfq/customer/' : '/api/rfq/customer/';
  const response = await fetchJson(`${BASE}${path}${id}/`, {
    method: 'DELETE',
  });
  return response;
}

export async function saveRfqCustomerPersonnel(data, isMfg) {
  const path = isMfg ? '/api/mrfq/customer/' : '/api/rfq/customer/';
  const response = await fetchJson(`${BASE}${path}`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
  return response;
}

export async function getPossibleTransitions(id, isMfg) {
  const path = isMfg ? '/api/mrfq/group/' : '/api/rfq/group/';
  const response = await fetchJson(
    `${BASE}${path}${id}/possible-transitions/`,
    {
      method: 'GET',
    }
  );
  return response;
}

export async function createSimpleRfqInquery(data) {
  const response = await fetchJson(`${BASE}/api/p/simple-inquiry/`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
  return response;
}

export async function getSimpleRfqInqueryList() {
  const response = await fetchJson(`${BASE}/api/p/simple-inquiry/`, {
    method: 'GET',
  });
  return response;
}

export async function getSimpleRfqInqueryItem(id) {
  const response = await fetchJson(`${BASE}/api/p/simple-inquiry/${id}/`, {
    method: 'GET',
  });
  return response;
}

export async function duplicateRfq(rfqId, isMfg) {
  const data = {
    rfq_id: isMfg ? undefined : rfqId,
    mrfq_id: isMfg ? rfqId : undefined,
  };
  const path = isMfg ? '/api/mrfq/duplicate/' : '/api/rfq/duplicate/';
  const response = await fetchJson(`${BASE}${path}`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
  return response;
}
