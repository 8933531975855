import React, { useEffect } from 'react';
import { Button } from 'theme/Button';
import { track } from 'utility/analytics';
import Flex from 'styled-flex-component';
import Icon from 'components/Icon/Icon';
import { Alert as AlertItem, Title } from '@zendeskgarden/react-notifications';
import { SM, MD } from '@zendeskgarden/react-typography';
import styled from 'styled-components/macro';
import { fadeIn } from 'theme/Animations.styles.js';
import { css } from 'styled-components/macro';
import { variables } from 'theme/variables';

const { custom_orange } = variables;

const animation = (props) =>
  css`
    ${fadeIn} 300ms ease;
  `;
export const AlertWrapper = styled('div')`
  animation: ${animation};
`;

const Alert = ({
  summary,
  view,
  type,
  icon,
  title,
  onClose,
  small,
  inline,
  style,
  action,
}) => {
  useEffect(() => {
    if (view && summary) {
      track('alertVisible', {
        icon,
        type,
        view,
        summary,
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AlertWrapper style={style || {}}>
      <AlertItem inline={inline} type={type} small={small}>
        <Flex full alignCenter>
          <Flex full alignCenter>
            {icon ? <Icon spacingRight icon={icon} /> : null}
            <Title>{title}</Title>
          </Flex>
          {onClose ? (
            <Icon
              pointer
              onClick={onClose}
              icon={'icon-close'}
              fontSize="13px"
            />
          ) : null}
          {action ? (
            <Button
              color="#000"
              bold
              style={{ minWidth: '150px' }}
              link
              onClick={action.onClick}
              fontSize="13px"
            >
              {action.text}
            </Button>
          ) : null}
        </Flex>
      </AlertItem>
    </AlertWrapper>
  );
};

export const StyledAlert = styled('div')`
  background-color: #fff;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(226, 229, 236);
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 4px 5px rgba(66, 100, 251, 0.061);
  border-radius: 6px;
  position: relative;
  margin: 0px 0 30px;
  overflow: hidden;
  display: flex;
  align-items: stetch;
  justify-content: space-between;
  ${(props) =>
    props.type === 'error'
      ? `
    background-color: rgba(255, 35, 0, 0.07);
    ${AlertMessage} {
      * {
        color: #aa2713 !important;
      }
    }

     button {
      color: #5f0707 !important;
      * {
        color: #5f0707 !important;
      }
    }
    
  `
      : ''}

  ${(props) =>
    props.type === 'warning'
      ? `
    background-color: rgba(255, 35, 0, 0.07);
    ${AlertMessage} {
      * {
        color: yellow !important;
      }
    }

     button {
      color:yellow !important;
      * {
        color: yellow !important;
      }
    }
    
  `
      : ''}

  ${(props) =>
    props.type === 'success'
      ? `
    background-color: rgba(54, 181, 136, 0.13);
    ${AlertMessage} {
      * {
        color: #206b51 !important;
      }
    }

    ${AlertType} {
      &:after {
        background: #34b98a !important;
      }
    }

     button {
      color: #134030 !important;
      * {
        color: #134030 !important;
      }
    }
    
  `
      : ''}


  ${(props) =>
    props.type === 'warning'
      ? `
    background-color: rgba(241, 116, 82, 0.14);
    ${AlertMessage} {
      * {
        color: #b55d44 !important;
      }
    }

    ${AlertType} {
      &:after {
        background: ${custom_orange} !important;
      }
    }

     button {
      color: #783c2c !important;
      * {
        color: #783c2c !important;
      }
    }
    
  `
      : ''}
`;

export const AlertType = styled('div')`
  width: 100px;
  min-height: 100%;
  color: #fff;
  padding: 10px;
  tex-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  * {
    z-index: 2;
  }
  &:after {
    z-index: 1;
    content: '';
    transform: skew(-20deg);
    background-color: red;
    position: absolute;
    left: -10px;
    top: 0;
    bottom: 0;
    right: 0;
  }
`;

export const AlertMessage = styled('div')`
  padding: 10px 30px;
`;

export const NewAlert = ({ label, type, title, style, action }) => {
  return (
    <StyledAlert style={style || {}} type={type}>
      <Flex alignCenter>
        <AlertType>
          <SM xs center uppercase white>
            {label || type}
          </SM>
        </AlertType>
        <AlertMessage>
          <SM navy>{title}</SM>
        </AlertMessage>
      </Flex>
      <Flex alignCenter style={{ maxWidth: '200px' }}>
        {action ? (
          <Button
            color="#000"
            bold
            style={{ minWidth: '150px' }}
            link
            onClick={action.onClick}
            fontSize="13px"
          >
            {action.text}
          </Button>
        ) : (
          <div></div>
        )}
      </Flex>
    </StyledAlert>
  );
};

export default Alert;
