import fetchJson from 'utility/fetchJson';
import moment from 'moment';
import queryString from 'query-string';
import { BASE } from '../../constants';

export async function fetchAddressSuggestion(location) {
  const response = await fetchJson(
    `${BASE}/api/autocomplete/location/?location=${location}`,
    {
      method: 'GET',
    }
  );
  return response;
}

export async function fetchSuggestion(
  type,
  value,
  clientId,
  includeContacts,
  contentType,
  isUser,
  contactStatus,
  company
) {
  let query;
  if (type === 'contact') {
    query = queryString.stringify({
      keyword: value,
      client_id: clientId,
      is_user: isUser,
      contact_status: contactStatus,
      company,
    });
  } else {
    query = queryString.stringify({
      keyword: value,
      facilitator: clientId,
      include_contacts: includeContacts,
      content_type_id: contentType,
    });
  }
  const response = await fetchJson(
    `${BASE}/api/autocomplete/${type}/?${query}`,
    {
      method: 'GET',
    }
  );
  return response;
}

export async function createFile(data, { isImage, customPath } = {}) {
  const path = isImage ? 'image' : 'file';
  const response = await fetchJson(
    customPath ? `${BASE}/${customPath}` : `${BASE}/api/util/${path}/`,
    {
      method: 'POST',
      body: data,
    },
    { formData: true }
  );
  return response;
}

export async function deleteFile(id, { isImage, legacy = false } = {}) {
  const path = isImage ? 'image' : 'file';
  const legacyPath = legacy ? 'legacy/' : '';
  const response = await fetchJson(
    `${BASE}/api/util/${legacyPath}${path}/${id}/`,
    {
      method: 'DELETE',
    }
  );
  return response;
}

export async function fetchFilesForObjectId(
  { objectId, contentType, type },
  { isImage } = {}
) {
  const path = isImage ? 'image' : 'file';
  const response = await fetchJson(
    `${BASE}/api/util/${path}/list/?object_id=${objectId}&content_type=${contentType}${
      type ? `&type=${type}` : ''
    }`,
    {
      method: 'GET',
    }
  );
  return response;
}

export async function fetchFileProxy(endpoint) {
  const response = await fetchJson(`${BASE}${endpoint}`, {
    method: 'GET',
  });
  return response;
}

export async function fetchFile(id, { isImage, legacy = false } = {}) {
  const path = isImage ? 'image' : 'file';
  const legacyPath = legacy ? 'legacy/' : '';
  const response = await fetchJson(
    `${BASE}/api/util/${legacyPath}${path}/${id}/`,
    {
      method: 'GET',
    }
  );
  return response;
}

export async function submitContactUs(data) {
  const response = await fetchJson(`${BASE}/api/user/contact/`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
  return response;
}

export async function submitQuickInquire(data) {
  const response = await fetchJson(`${BASE}/api/user/inquire/`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
  return response;
}

export async function submitSubscribe(data) {
  const response = await fetchJson(`${BASE}/api/user/subscribe/`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
  return response;
}

// FETCH META
export async function fetchPublicMeta() {
  let response;
  try {
    let storedMeta = localStorage.getItem('publicMeta');
    storedMeta = storedMeta ? JSON.parse(storedMeta) : null;
    const shouldRefetch = storedMeta
      ? moment(Date.now()).diff(storedMeta.date, 'minutes') > 20
      : true;
    if (shouldRefetch) {
      response = await fetchJson(`${BASE}/api/meta/public/`, {
        method: 'GET',
      });
      if (response && !response.error) {
        localStorage.setItem(
          'publicMeta',
          JSON.stringify({ date: new Date(), data: response })
        );
      }
      return response;
    }
    return storedMeta.data;
  } catch (err) {
    console.error('Error Loading Public Meta');
  }
  return response;
}
